import {Checkbox, FormControl, InputLabel} from '@material-ui/core';
import React from 'react';
import Card from '../../Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from '../../Card/CardBody';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import Button from '../../CustomButtons/Button';
import {Check} from '@material-ui/icons';
import Datetime from 'react-datetime';
import dayjs from "dayjs";
import moment from "moment";

export const Personal = ({
  classes,
  handleSubmit,
  app_state,
  state,
  handleChange,
  setState,
  handleCustomReactSelectChange,
  handleToggle,
  referral_source_select_options,

  getFullDate
}) => {
  const select_country_select_options = state.country_list_prio.map(item => ({
    value: item.id,
    label: item.full_name
  }));
  const marital_status_select_options = state.marital_status_list
    .sort((a, b) => a.nickname.localeCompare(b.nickname))
    .map(item => ({
      value: item.id,
      label: item.nickname
    }));
  const language_select_options = state.language_id_list
    .sort((a, b) => a.nickname.localeCompare(b.nickname))
    .map(item => ({
      value: item.id,
      label: item.nickname
    }));

    let addressType = 'Residential';
    let addressNotes = ''
    if (state.account_type !== 1) {
        addressType = 'Business';
        addressNotes = `The address for client contact ${state.first_name} ${state.last_name} is in the Contacts tab.`
    }

  return (
    <React.Fragment>
      <div style={{padding: 20}}>
        <form onSubmit={handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card style={{height: '90%'}}>
                <CardHeader>
                  <h4 className={classes.cardIconTitle}>Telephone & Email</h4>
                </CardHeader>
                <CardBody>

                    <CustomInput
                        labelText='Primary Email'
                        id='primary_email'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                            disabled: true,
                            value: state.email || '',
                            onChange: event => {
                                handleChange(event);
                            }
                        }}
                    />
                    {!state.edit_mode || state.email_secondary && 
                    <CustomInput
                    labelText='Secondary Email'
                    id='email_secondary'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.email_secondary || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                    />
                  }
                                <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>

                  <CustomInput
                    labelText='Home Telephone'
                    id='telephone_home'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.telephone_home || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText='Work Telephone'
                    id='telephone_work'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.telephone_work || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />
                              
                              
                  </GridItem>
                  </GridContainer>

                  <CustomInput
                    labelText='Mobile Telephone'
                    id='telephone_mobile'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.telephone_mobile || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <Card style={{height: '90%'}}>
                    <CardHeader color='rose' icon>
                        <h4 className={classes.cardIconTitle}>{addressType} Address</h4>
                    </CardHeader>

                    <CardBody>
                        <h5 style={{marginTop: 0}}>
                            Address details for account holder: {state.client_nickname}
                        </h5>
                        <em>{addressNotes}</em>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                    labelText='Address (line 1)'
                                    id='residential_street_line_1'
                                    formControlProps={{fullWidth: true}}
                                    inputProps={{
                                        disabled: !state.edit_mode,
                                        value: state.residential_street_line_1 || '',
                                        onChange: event => {
                                            handleChange(event);
                                        }
                                    }}
                                />
                                {(state.edit_mode || state.residential_street_line_2) && 
                                <CustomInput
                                    labelText='Address (line 2)'
                                    id='residential_street_line_2'
                                    formControlProps={{fullWidth: true}}
                                    inputProps={{
                                        disabled: !state.edit_mode,
                                        value: state.residential_street_line_2 || '',
                                        onChange: event => {
                                            handleChange(event);
                                        }
                                    }}
                                />
                                  }
                                <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>

                                <CustomInput
                                    labelText='Suburb'
                                    id='residential_street_suburb'
                                    formControlProps={{fullWidth: true}}
                                    inputProps={{
                                        disabled: !state.edit_mode,
                                        value: state.residential_street_suburb || '',
                                        onChange: event => {
                                            handleChange(event);
                                        }
                                    }}
                                />
                                </GridItem>
                              <GridItem xs={12} sm={12} md={3}>

                                <CustomInput
                                    labelText='State'
                                    id='residential_street_state'
                                    formControlProps={{fullWidth: true}}
                                    inputProps={{
                                        disabled: !state.edit_mode,
                                        value: state.residential_street_state || '',
                                        onChange: event => {
                                            handleChange(event);
                                        }
                                    }}
                                />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>

                                <CustomInput
                                    labelText='Postcode'
                                    id='residential_street_postcode'
                                    formControlProps={{fullWidth: true}}
                                    inputProps={{
                                        disabled: !state.edit_mode,
                                        value: state.residential_street_postcode || '',
                                        onChange: event => {
                                            handleChange(event);
                                        }
                                    }}
                                />
                                </GridItem>
                                </GridContainer>

                                <CustomReactSelect
                                    label='Country'
                                    options={select_country_select_options}
                                    value={state.residential_street_country}
                                    onChange={handleCustomReactSelectChange('residential_street_country')}
                                    isClearable={false}
                                    isDisabled={!state.edit_mode}
                                />
                            </GridItem>
                        </GridContainer>

                    </CardBody>
                </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card style={{height: '99%'}}>
                <CardHeader color='rose' icon>
                  <h4 className={classes.cardIconTitle}>Personal Details</h4>
                </CardHeader>
                <CardBody>
                  <h5 style={{marginTop: 0}}>
                    Personal details for account contact: {state.first_name} {state.last_name}
                  </h5>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel className={classes.label}>Date Of Birth</InputLabel>
                      <br />
                      <FormControl>
                        <Datetime
                          timeFormat={false}
                          dateFormat={'Do MMMM YYYY'}
                          inputProps={{
                            placeholder: getFullDate(new Date(state.date_of_birth)) || '',
                            disabled: !state.edit_mode
                          }}
                          onChange={event => {
                            let selDate = '';
                            const isMoment = moment.isMoment(event);
                            if (!isMoment) {
                                event = moment(event, 'DD/MM/YYYY');
                                if (!event.isValid()) {
                                    return;
                                }
                            }
                            if (event.toDate().getMonth() >= 9) {
                              if (event.toDate().getDate() > 9) {
                                selDate =
                                  event.toDate().getFullYear() +
                                  '-' +
                                  (event.toDate().getMonth() + 1) +
                                  '-' +
                                  event.toDate().getDate() +
                                  'T00:00.00.000Z';
                              } else {
                                selDate =
                                  event.toDate().getFullYear() +
                                  '-' +
                                  (event.toDate().getMonth() + 1) +
                                  '-0' +
                                  event.toDate().getDate() +
                                  'T00:00.00.000Z';
                              }
                            } else if (event.toDate().getMonth() < 9) {
                              if (event.toDate().getDate() > 9) {
                                selDate =
                                  event.toDate().getFullYear() +
                                  '-0' +
                                  (event.toDate().getMonth() + 1) +
                                  '-' +
                                  event.toDate().getDate() +
                                  'T00:00.00.000Z';
                              } else {
                                selDate =
                                  event.toDate().getFullYear() +
                                  '-0' +
                                  (event.toDate().getMonth() + 1) +
                                  '-0' +
                                  event.toDate().getDate() +
                                  'T00:00.00.000Z';
                              }
                            }
                            setState({date_of_birth: selDate});
                          }}
                        />
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText='Place Of Birth City'
                        id='place_of_birth_city'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          disabled: !state.edit_mode,
                          value: state.place_of_birth_city || '',
                          onChange: event => {
                            handleChange(event);
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <CustomReactSelect
                        label='Place Of Birth Country'
                        options={select_country_select_options}
                        value={state.place_of_birth_country}
                        onChange={handleCustomReactSelectChange('place_of_birth_country')}
                        isClearable={true}
                        isDisabled={!state.edit_mode}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomReactSelect
                        label='Nationality'
                        options={select_country_select_options}
                        value={state.nationality}
                        onChange={handleCustomReactSelectChange('nationality')}
                        isClearable={true}
                        isDisabled={!state.edit_mode}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <CustomReactSelect
                        label='Marital Status'
                        options={marital_status_select_options}
                        value={state.marital_status}
                        onChange={handleCustomReactSelectChange('marital_status')}
                        isClearable={true}
                        isDisabled={!state.edit_mode}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomReactSelect
                        label='Language'
                        options={language_select_options}
                        value={state.language_id}
                        onChange={handleCustomReactSelectChange('language_id')}
                        isClearable={true}
                        isDisabled={!state.edit_mode}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                    <CustomInput
                    labelText='Occupation'
                    id='occupation'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.occupation || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText='Employer'
                    id='employer'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.employer || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />

</GridItem>
</GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card style={{height: '99%'}}>
                <CardHeader color='rose' icon>
                  <h4 className={classes.cardIconTitle}>Info provided by Sales Staff</h4>
                </CardHeader>
                <CardBody>
                  {/* <h4 className={classes.cardIconTitle}>Client Financial Information (for risk calc)</h4> */}
                    <CustomReactSelect
                        label='Turn Over'
                        options={[
                            {value: 1, label: 'Low Turnover (<5 Transactions per Month)'},
                            {value: 2, label: 'Medium Turnover (5 - 10 Transactions per Month)'},
                            {value: 3, label: 'High Turnover (> 10 Transactions per Month)'},
                        ]}
                        value={state.turnover}
                        onChange={handleCustomReactSelectChange('turnover')}
                        isClearable={true}
                        isDisabled={!state.edit_mode}
                    />
                    <CustomReactSelect
                        label='Estimated Net Worth'
                        options={[
                            {value: 1, label: '< $250,000'},
                            {value: 2, label: '$250,000 - $500,000'},
                            {value: 3, label: '$500,001 - 1,000,000'},
                            {value: 4, label: '$100,001 - 10,000,000'},
                            {value: 5, label: '> $10,000,000'}
                        ]}
                        value={state.netWorth}
                        onChange={handleCustomReactSelectChange('netWorth')}
                        isClearable={true}
                        isDisabled={!state.edit_mode}
                    />
                    <CustomReactSelect
                        label='Source Of Funds'
                        options={[
                            {value: 1, label: 'Salaries and wages'},
                            {value: 2, label: 'Government benefits'},
                            {value: 3, label: 'Rent, Dividends'},
                            {value: 4, label: 'One-off Payments'},
                            {value: 5, label: 'Sale of Assets'},
                            {value: 6, label: 'Borrowed Funds'},
                            {value: 7, label: 'Windfall Gain'},
                        ]}
                        value={state.sourceOfFunds}
                        onChange={handleCustomReactSelectChange('sourceOfFunds')}
                        isClearable={true}
                        isDisabled={!state.edit_mode}
                    />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {/* <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card style={{height: '90%'}}>
                <CardHeader color='rose' icon>
                  <h4 className={classes.cardIconTitle}>Marketing and Referral</h4>
                </CardHeader>
                <CardBody>
                  <CustomReactSelect
                    label='Referral Code'
                    options={referral_source_select_options}
                    value={state.referral_source}
                    onChange={handleCustomReactSelectChange('referral_source')}
                    isClearable={true}
                    isDisabled={!state.edit_mode}
                  />
                  <CustomInput
                    labelText='Referral Code'
                    id='referrer_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.referrer_code || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />
                  <Checkbox
                    checked={state.marketing_authorised === true}
                    value='marketing_authorised'
                    onClick={handleToggle('marketing_authorised')}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                    inputProps={{disabled: !state.edit_mode}}
                  />
                  Marketing Authorised
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer> */}
          <Button color='primary' disabled={!state.edit_mode} type='submit' className={classes.updateProfileButton}>
            Update Personal Details
          </Button>
        </form>
      </div>
    </React.Fragment>
  );
};
