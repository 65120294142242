import React from 'react';

import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';

import Button from '../CustomButtons/Button';
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody.jsx';
import CardHeader from '../Card/CardHeader';
import queryString from 'query-string';

import {FormattedMessage} from 'react-intl';
import {IdentificationFormValidationRules} from './IdentificationConstants';
import {IdentificationAPI} from './IdentificationAPI';
import { Alert, Col, DatePicker, Form, Input, Row, Select } from 'antd'

import {IdentificationConstants} from './IdentificationUtil';

import FormItem from 'antd/lib/form/FormItem';
import {FormGroup} from '@material-ui/core';
import './Identification.css';
import FileUploadNew from '../FileUpload/FileUploadNew';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import {IdentificationTemplates} from './IdentificationTemplates';
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {AppUtil} from '../../AppUtil';
import { AppTemplates } from '../../AppTemplates'
import FileUploader from "../Uploaders/FileUploader";
import {UserOutlined} from "@ant-design/icons";
import {API} from "aws-amplify";

const styles = {
    ...selectStyles,
    ...extendedFormsStyle,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {
        color: '#999999'
    },
    updateProfileButton: {
        float: 'right'
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px'
    },
    registerButton: {
        float: 'right'
    }
};

class IdentificationNew extends React.Component {

    formRef = React.createRef();
    uploadRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = IdentificationConstants.identificationStateKeys;
    }

    componentDidMount() {
        // const { location } = this.props;
        // const queryParams = new URLSearchParams(location.search);
        // const myParam = queryParams.get('clientId');

        IdentificationAPI.fetchCategories().then(response => {
            const data = response.map(item => ({label: item.nickname, value: item.id}));
            this.setState({document_category_list: data});
        }).catch(error => console.log(error));

        IdentificationAPI.fetchCountries().then(response => {
            this.setState({country_list_prio: response});
        }).catch(error => console.log(error));

        const query_strings = queryString.parse(this.props.location.search);
        if (query_strings.id) {
            this.formRef.current.setFieldsValue({ client_id: Number(query_strings.id) })
            this.setState({query_string: true, client_id: query_strings.id, client_id_state: 'success'});
            this.getClientDetails(query_strings.id);
        }
        API.post("commons", "/fetch", { body: { context: "client_contacts", fields: ['*'], condition: {clientId: query_strings.id, deleted: false}}})
            .then(res => {
                this.setState({ clientContacts: res, clientContactsF: []});
            })
        this.fetchClientList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            this.fetchClientList();
        }
    }

    fetchClientList = () => {
        if (!this.props.app_state.current_staff) {
            this.setState({client_list: []});
        } else {
            const staffID = this.props.app_state.current_staff.id
            IdentificationAPI.fetchClientList(staffID)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        client_list: response
                    });
                })
                .catch(err => console.log(err));
        }

    }

    getClientDetails(client_id) {
        IdentificationAPI.fetchClientDetails(client_id).then(response => {
            this.setState({client: response});
            IdentificationAPI.fetchClientTeam(response.team_id)
                .then(response => this.setState({client_team_nickname: response.nickname}))
                .catch(error => console.log(error));
        }).catch(error => console.log(error));
    }

    handleSubmitForm = (data) => {
        const documentCategoryId = data.document_category_id;
        const date = this.formRef.current.getFieldValue('document_expiry');
        data.document_expiry = date != null ?
            AppUtil.handle_transaction_date_change(date, documentCategoryId === 3 ? 'onlyYearMonth' : '')
            : null;
        this.setState({newUser: 'sd'});
        data.nickname = documentCategoryId != null ? this.state.document_category_list.find(i => i.value === documentCategoryId).label : null;
        // data.nickname = "Identification Upload";
        IdentificationAPI.saveIdentificationRecord(data).then(response => {
            this.props.history.push(`/clients/edit/${data.client_id}`);
        }).catch(err => console.log(err));
    }

    handleCancel() {
        this.props.history.push('/identification/list');
    }

    onChangeDocumentCategory(data) {
        const label = this.state.document_category_list.find(item => item.value === data).label || '';
        this.setState({document_category_id: data});
        this.formRef.current.setFieldsValue({nickname: label});
        const format = label.toLowerCase() === 'medicare' ? 'MM/YYYY' : 'DD/MM/YYYY';
        this.setState({document_date_format: format});
        this.formRef.current.setFieldsValue({expiry_date: null});
    }

    onFormSubmit = () => {
        this.formRef.current.setFieldsValue({file_id_back: (typeof this.state.back_image === 'object') ? this.state.back_image.file_id : ''});
        this.formRef.current.setFieldsValue({file_id: (typeof this.state.front_image === 'object') ? this.state.front_image.file_id : ''});
        this.formRef.current.submit();
    }

    storeMultipleImages(image) {
        const images = this.state.multipleImages;
        images.push(image);
        this.setState({multipleImages: images});
    }

    onRemoveImage = (file) => {
        const images = this.state.multipleImages.filter(item => item.file.uid !== file.uid);
        this.setState({multipleImages: images });
    }

    displaySelectedClient = (event) => {
        const client = this.state.client_list.find(item => item.id === event);
        if (client != null) {
            this.setState({activeClient: `${client.first_name} ${client.last_name} [${client.email}]`, client: client});
            const client_contacts = this.state.clientContacts.filter(i => i.clientId === client.id);
            this.setState({ clientContactsF: client_contacts });
        } else {
            this.setState({ clientContactsF: this.state.clientContacts });
        }
    }

    render() {
        const {classes} = this.props;

        const sortedCountry = this.state.country_list_prio.sort((a,b) => a.full_name.localeCompare(b.full_name));
        const country_list_select_options = AppTemplates.countries(sortedCountry);
        const clientList = AppTemplates.clients(this.state.client_list);

        return (
            <GridContainer>
                <GridItem xs={12} sm={12}>
                    <h3>
                        <FormattedMessage id='newIdentification.title'
                                          defaultMessage={'Create a new identification record '}/>
                    </h3>
                    <p>
                        <FormattedMessage
                            id='newIdentification.subtitle'
                            defaultMessage={'Create a new record containing details and an upload of an identification document.'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id='newIdentification.instruction'
                            defaultMessage={'This will be applied to the specified client account and used for verification purposes.'}
                        />
                    </p>
                    <Form ref={this.formRef} name={'newIdentification'} scrollToFirstError layout={'vertical'}
                          onFinish={this.handleSubmitForm}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card>
                                    <CardHeader>
                                        <h5>Document Details</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <FormItem name="client_id"
                                                          label={<FormattedMessage id='newIdentification.form.client'
                                                                                   defaultMessage={'Client'}/>}
                                                          rules={IdentificationFormValidationRules.CLIENT}>
                                                    <Select showSearch
                                                            onChange={(e) => this.displaySelectedClient(e)}
                                                            style={{width: '100%'}}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="alt"
                                                            filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input)}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                            }
                                                            options={clientList}></Select></FormItem>
                                            </Col>
                                            <Col span={12}>
                                                <FormItem name="activeClient"
                                                          label={<FormattedMessage id='newIdentification.form.selectedClient'
                                                                                   defaultMessage={' '}/>}>
                                                    {
                                                        this.state.activeClient != null && this.state.activeClient !== '-' ?
                                                            <Alert message={this.state.activeClient} type="info" /> : ''
                                                    }
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <FormItem name="document_category_id" label={
                                                    <FormattedMessage
                                                        id='newClients.step4.form.documentCategory'
                                                        defaultMessage={'Document Category'}
                                                    />
                                                }
                                                          rules={IdentificationFormValidationRules.DOCUMENT_CATEGORY}>
                                                    <Select showSearch
                                                            style={{width: '100%'}}
                                                            placeholder="Document Category"
                                                            optionFilterProp="children"
                                                            onChange={(e) => this.onChangeDocumentCategory(e)}
                                                            filterOption={(input, option) => (option && option.label ? option.label : '').toLowerCase().includes(input)}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA && optionA.label ? optionA.label : '').toLowerCase().localeCompare((optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase())
                                                            }
                                                            options={this.state.document_category_list}></Select></FormItem>
                                            </Col>
                                            <Col span={12}>
                                                <FormGroup>
                                                    <FormItem name="document_issue_country" label={
                                                        <FormattedMessage id='newClients.step4.form.issueCountry'
                                                                          defaultMessage={'IssueCountry'}/>
                                                    }
                                                              rules={IdentificationFormValidationRules.COUNTRY}>
                                                        <Select showSearch
                                                                style={{width: '100%'}}
                                                                placeholder="Search to select country"
                                                                optionFilterProp="alt"
                                                                filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                                                filterSort={(optionA, optionB) =>
                                                                    (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                                }
                                                                options={country_list_select_options}></Select></FormItem>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <FormItem name="document_number" label={
                                                    <FormattedMessage
                                                        id='newClients.step4.form.documentNumber'
                                                        defaultMessage={'Document Number'}
                                                    />
                                                }
                                                          rules={IdentificationFormValidationRules.DOCUMENT_NUMBER}>
                                                    <Input placeholder={'Document number'} />
                                                </FormItem>
                                            </Col>
                                            <Col span={12}>
                                                <FormItem name="client_contact_id" label={
                                                    <FormattedMessage
                                                        id='newClients.step4.form.clientContactId'
                                                        defaultMessage={'Client Contact'}
                                                    />
                                                }
                                                          rules={IdentificationFormValidationRules.CLIENT_CONTACT}>
                                                    <Select style={{ width: '100%' }} placeholder={'-- ASSIGNED TO CLIENT --'} allowClear={true}  menuItemSelectedIcon={<UserOutlined />} showSearch={true} options={this.state.clientContactsF.map(i => ({
                                                        ...i,
                                                        label: `[${i.id}] ${i.firstName} ${i.lastName}`,
                                                        value: i.id
                                                    }))}></Select>
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <FormItem name="document_issue_state" label={
                                                    <FormattedMessage id='newClients.step4.form.issueState'
                                                                      defaultMessage={'Issue State'}/>
                                                }
                                                          rules={IdentificationFormValidationRules.ISSUE_STATE}>
                                                    <Input placeholder={'Issue state'} />
                                                </FormItem>
                                            </Col>
                                            <Col span={12}>
                                                <FormItem name="document_expiry" label={<FormattedMessage
                                                    id='newClients.step4.form.documentExpiry'
                                                    defaultMessage={'Document Expiry'}/>}
                                                          rules={IdentificationFormValidationRules.EXPIRY_DATE}>
                                                    <DatePicker style={{width: '100%'}} showTime={false} mode="date"
                                                                format={this.state.document_date_format ? this.state.document_date_format : 'DD/MM/YYYY'}/>
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <CardHeader>
                                        <h5>Document Upload</h5>
                                        <em>Please ensure to upload both front and back of Drivers Licences</em>
                                    </CardHeader>
                                    <CardBody>
                                        {/* <Row gutter={16}>
                        <Col md={12}>
                          <FileUploadNew hasFeedback multiUpload={false}
                            onHandleResult={(imageUrl) => this.setState({front_image: imageUrl})}></FileUploadNew>
                          <FormItem hidden name="file_id">
                            <Input type={'hidden'}/>
                          </FormItem>
                          <div style={{textAlign: 'center'}}>
                            <span>Front Image</span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <FileUploadNew hasFeedback multiUpload={false}
                            onHandleResult={(imageUrl) => this.setState({back_image: imageUrl})}></FileUploadNew>
                          <FormItem hidden name="file_id_back">
                            <Input type={'hidden'}/>
                          </FormItem>
                          <div style={{textAlign: 'center'}}>
                            <span>Back Image</span>
                          </div>
                        </Col>
                      </Row>
                      <hr/> */}
                                        <Row gutter={16}>
                                            <Col md={24}>
                                                <Form.Item name={'multipleImages'} getValueFromEvent={(e) => {
                                                    console.log(e);
                                                    return e.map(i => i.filename)
                                                }}>
                                                    <FileUploader></FileUploader>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                        color='primary'
                                        onClick={this.onFormSubmit}
                                        className={classes.registerButton}
                                    >
                                        <FormattedMessage id='newIdentification.submit' defaultMessage={'Submit'}/>
                                    </Button>
                                    <Button color='info' onClick={() => this.handleCancel()}
                                            className={classes.registerButton}>
                                        <FormattedMessage id='newIdentification.cancel' defaultMessage={'Cancel'}/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </GridItem>
            </GridContainer>
        );
    }
}

// export default withRouter(withStyles(styles)(IdentificationNew));
const mapStateToProps = state => {
    return {
        app_state: state.app_state,
    };
};

const IdentificationNewContainer = connect(
    mapStateToProps,
)(withRouter(withStyles(styles)(IdentificationNew)));

export default IdentificationNewContainer;

