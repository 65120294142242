import React from 'react';

import {Descriptions, Divider} from 'antd';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';

import dayjs from 'dayjs';


const ClientSummary1 = ({client}) => {

  return <>
    <Descriptions bordered={true} size={'small'} column={1} items={[
      {
        label: 'Client Name',
        children: <>
          <strong>{client.client_nickname}</strong>
          {
            client.company_name ?
              <span>
                {client.business_abn ? <span><br/>ABN: {client.business_abn}</span> : null}
                {client.business_company_number ? <span><br/>ACN: {client.business_company_number}</span> : null}
              </span>
              : null
          }
        </>
      },
      {
        label: 'Individual Name',
        children: <>
          {client.first_name}{` `}
          {client.middle_name}{` `}
          {client.last_name}
        </>
      },
      {label: 'Email', children: <>{client.email}</>},
      {label: 'Client Notes', children: <>{client.clientNotes}</>},
      {label: 'Webpage', children: <>{client.webpage}</>}
    ]}></Descriptions>
    <Divider />
    <GridContainer>
      <GridItem md={6}>
        <Descriptions bordered={true} size={'small'} column={1} items={[
          {
            label: 'Address',
            children: <>
              {client.residential_street_line_1}<br/>
              {client.residential_street_line_2 ? <span>{client.residential_street_line_2}<br/></span> : null}
              {client.residential_street_suburb}{` `}
              {client.residential_street_state}{` `}
              {client.residential_street_postcode}<br/>
            </>
          },
          {
            label: 'Telephone',
            children: <>
              {
                client.telephone_mobile ? client.telephone_mobile :
                  client.telephone_work ? client.telephone_work :
                    client.telephone_home
              }
            </>
          }
        ]}></Descriptions>
      </GridItem>
      <GridItem md={6}>
        <Descriptions bordered={true} size={'small'} column={1} items={[
          {
            label: 'Date of Birth',
            children: <>
              {dayjs(client.date_of_birth).isBefore(dayjs("01/01/1900")) ?
                "N/A" : dayjs(client.date_of_birth).format("DD/MM/YYYY")}
            </>
          },
          {
            label: 'Place of Birth',
            children: <>
              {client.place_of_birth_city}<br/>
            </>
          }
        ]}></Descriptions>
      </GridItem>
    </GridContainer>
  </>
};

export default ClientSummary1;