import React from 'react';
import {connect} from 'react-redux';

import {API} from 'aws-amplify';
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {Button, Collapse, Divider, Flex, message, Modal, Table} from 'antd';

import {FormattedNumber} from 'react-intl';

import {CSVLink} from "react-csv";
import moment from 'moment';


/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Badge from '../../../components/Badge/Badge.jsx';
import ListTable from "../../../components/ListTable/ListTable";

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {color: '#999999'},
    updateProfileButton: {float: 'right'},
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};


const CurrencyElement = ({classes, currency, value}) => (
    <div className={classes.currency}>
        <div className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`}/>
        <div className={classes.currencyLabel}>
            <p>{currency}</p>
            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={value}/>
        </div>
    </div>
);


/**
 * ----------------------------------------------------------------------------
 * CLASS - Copied from ClientsList - TransfersList - Split into current and past components #1054
 * ----------------------------------------------------------------------------
 */
class PaymentsListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: [],
            totalArray: [],
            receivedArray: [],
            showingOnlyNPR: false,
            payout_email_sending: false,
            payout_email_sent: false,
            transferStatusOptions: [
                { text: 'Draft', value: 'Draft' },
                { text: 'Submitted', value: 'Submitted' },
                { text: 'Acknowledged', value: 'Acknowledged' },
                { text: 'Processing', value: 'Processing' },
                { text: 'Awaiting Funds', value: 'Awaiting Funds' },
                { text: 'Funds Received', value: 'Funds Received' },
                { text: 'Payment Made', value: 'Payment Made' },
                { text: 'Completed', value: 'Completed' },
                { text: 'On Hold', value: 'On Hold' },
                { text: 'Not Proceeding', value: 'Not Proceeding' },
                { text: 'Default', value: 'Default' },
            ],
            isDataListLoading: false
        };
    }

    componentDidMount() {
        this.loadPayments()
    }

    nprReceived = async () => {
        await API.get('payments', `/list`)
            .then(response => {
                console.log(response)
                let transferArray = response.transfers.filter(filter => filter.currency_to_iso_alpha_3 === 'NPR')
                transferArray = transferArray.filter(filter => filter.transfer_status === 'Funds Received')
                this.setState({
                    payments: transferArray,
                    showingOnlyNPR: true
                })
                this.sumPayments(transferArray)
            })
            .catch(error => {
                console.log(error);
            });
    }

    loadPayments = async () => {
        this.setState({isDataListLoading: true});
        await API.get('payments', `/list`)
            .then(response => {
                this.setState({payments: response.transfers, showingOnlyNPR: false})
                this.sumPayments(response.transfers)
            })
            .catch(error => console.log(error)).finally(() => this.setState({isDataListLoading: false}));
    }

    sumPayments = (payments) => {
        let receivedArray = []
        let totalArray = []
        payments.map(payment => {
            // console.log(payment)
            const receivedFound = receivedArray.find(element => element.currency === payment.currency_to_iso_alpha_3)
            const totalFound = totalArray.find(element => element.currency === payment.currency_to_iso_alpha_3)
            if (!totalFound) {
                totalArray.push({currency: payment.currency_to_iso_alpha_3, amount: payment.amount_to})
            } else {
                totalFound.amount += payment.amount_to
            }
            if (!receivedFound) {
                if (payment.status_id === 6) {
                    receivedArray.push({currency: payment.currency_to_iso_alpha_3, amount: payment.amount_to})
                }
            } else {
                if (payment.status_id === 6) {
                    receivedFound.amount += payment.amount_to
                }
            }
        });
        this.setState({
            totalArray: totalArray,
            receivedArray: receivedArray
        })
    }


    backendComplete = async (transferID, paymentID) => {
        let transfer = this.state.payments.filter(filter => filter.id === transferID);
        let transaction_notes = `${this.props.app_state.current_staff.first_name} `
        if (transfer.length > 0 && transfer[0].payments && transfer[0].payments.length > 0) {
            if (transfer[0].payments[0].payoutDate) {
                transaction_notes += `completed payout(s) on ${moment(transfer[0].payments[0].payoutDate).format("DD-MM-YYYY")}`
            }
            if (transfer[0].payments[0].payoutRef) {
                transaction_notes += ` with reference ${transfer[0].payments[0].payoutRef}`
            }
        }
        await API.post('payments', `/complete/${transferID}`, {
            body: {
                staffID: this.props.app_state.current_staff.id,
                transaction_notes: transaction_notes
            }
        }).then(response => console.log(response))
            .catch(error => console.log(error));
    }

    sendPayoutEmail = async (params) => {
        // console.log(this.props);
        this.setState({
            payout_email_sending: true
        });

        let currencyFrom;
        let currencyTo;
        let payouts_list;

        await API.get('currencies', `/get/${params.currency_from_id}`, {})
            .then(response => currencyFrom = response)
            .catch(error => console.log(error));

        await API.get('currencies', `/get/${params.currency_to_id}`, {})
            .then(response => currencyTo = response)
            .catch(error => console.log(error));

        await API.get('transfers', `/get-full/id/${params.transfer_id}`, {})
            .then((response) => {
                console.log(response)
                payouts_list = response.payouts
            });

        await API.post('email', `/client/payout-advice-new`, {
            body: {
                id: params.id,
                currentStaffID: params.currentStaffID,
                transfer_id: params.transfer_id,
                transferID: params.transferID,
                amount_from: params.amount_from,
                amount_to: params.amount_to,
                currency_from: currencyFrom,
                currency_to: currencyTo,
                settlement_date: moment(params.settlement_date).format("DD-MM-YYYY"),
                payouts_list: payouts_list,
                staffOnly: params.staffOnly
            }
        })
            .then(response => this.setState({payout_email_sending: false}))
            .catch(error => this.setState({payout_email_sending: false}));
    }

    buildTableData() {
        const transfer_list_length = this.state.payments.length;
        if (transfer_list_length > 0) {
            // console.log(this.state.payments)
            return this.state.payments.map((prop, key) => {

                let beneficiaryName = prop.payments.length > 0 ? prop.payments[0].beneficiaries_nickname : ''
                // console.log(prop)
                if (prop.payments.length < 1) {
                    alert('Pleae advise Sysadmin: No payments found for transfer ' + prop.id)
                }
                return {
                    id: prop.id,
                    transfer_id: prop.transfer_id,
                    client: prop.client_nickname,
                    client_id: prop.client_id,
                    transaction_date: prop.transaction_datetime != null ? moment(prop.transaction_datetime).format('DD/MM/YYYY') : '-',
                    status_id: prop.status_id,
                    memo: prop.memo,
                    nickname: prop.nickname,
                    splitPay: prop.splitPay,
                    settlement_date: prop.settlement_date,
                    beneficiary: beneficiaryName,
                    beneficiaries_address: prop.beneficiaries_address,
                    amount_from: prop.amount_from,
                    amount_to: prop.amount_to,
                    currency_from: prop.currency_from_iso_alpha_3,
                    currency_to: prop.currency_to_iso_alpha_3,
                    currency_from_id: prop.currency_from_id,
                    currency_to_id: prop.currency_to_id,
                    transfer_status: prop.transfer_status,
                    payments: prop.payments,
                    detail_1: prop.payments[0].detail_1,
                    documents: prop.documents
                };
            });
        }
    }

    buildColumns = () => {
        const {classes} = this.props;
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                defaultSortOrder: 'descend',
                sorter: { compare: (a,b) => a.id - b.id },
                ...this.props.getColumnSearchProps({
                    dataIndex: 'id',
                    label: 'id',
                    render: (text, record) => (
                        <React.Fragment>
                            <a href={`https://crm.forexworldwide.com/transfers/edit/${record.id}?tab=payout`}
                               target="_blank">
                                <Badge
                                    color={
                                        record.transfer_status === 'Draft'
                                            ? 'primary'
                                            : record.transfer_status === 'Submitted'
                                                ? 'primary'
                                                : record.transfer_status === 'Acknowledged'
                                                    ? 'primary'
                                                    : record.transfer_status === 'Processing'
                                                        ? 'primary'
                                                        : record.transfer_status === 'Awaiting Funds'
                                                            ? 'primary'
                                                            : record.transfer_status === 'Funds Received'
                                                                ? 'success'
                                                                : record.transfer_status === 'Payment Made'
                                                                    ? 'primary'
                                                                    : record.transfer_status === 'Completed'
                                                                        ? 'primary'
                                                                        : record.transfer_status === 'On Hold'
                                                                            ? 'primary'
                                                                            : record.transfer_status === 'Not Proceeding'
                                                                                ? 'primary'
                                                                                : 'primary'
                                    }
                                >
                                    {record.id}
                                </Badge>
                            </a>
                        </React.Fragment>
                    ),
                })
            },
            {
                title: 'Date',
                dataIndex: 'transaction_date',
                defaultSortOrder: 'descend',
                sorter: {
                    compare: (a, b) => {
                        const dateA = new Date(a.transaction_date.split('/').reverse().join('/'));
                        const dateB = new Date(b.transaction_date.split('/').reverse().join('/'));

                        return dateA - dateB;
                    },
                },
                ...this.props.getColumnSearchProps({
                    label: 'Date',
                    dataIndex: 'transaction_date',
                    filterInputType: 'DATE',
                    render: (text, record) => {
                        return (
                            <React.Fragment>
                                <strong>{text}</strong> <br/>
                                <small>Settles: {record.settlement_date === null ? '' : moment(record.settlement_date).format('DD/MM/YYYY')} </small>
                            </React.Fragment>
                        )
                    }
                })
            },
            {
                title: 'Client',
                dataIndex: 'client',
                sorter: { compare: (a,b) => a.client - b.client },
                ...this.props.getColumnSearchProps({
                    label: 'Client',
                    dataIndex: 'client',
                    render: (text, record) => (
                        <React.Fragment>
                <span style={{color: "navy"}}>
                  <strong>
                    {text}
                  </strong>
                </span>
                        </React.Fragment>
                    )
                })
            },
            {
                title: 'Beneficiary',
                dataIndex: 'beneficiary',
                sorter: { compare: (a,b) => a.beneficiary - b.beneficiary },
            },
            {
                title: 'From',
                dataIndex: 'currency_from',
                sorter: { compare: (a,b) => a.currency_from - b.currency_from },
                ...this.props.getColumnSearchProps({
                    render: (text, record) => (
                        <React.Fragment>
                            <div
                                className={`currency-flag currency-flag-${record.currency_from ? record.currency_from.toLowerCase() : ''}`}/>
                            &nbsp;&nbsp;
                            {record.currency_from}
                        </React.Fragment>
                    ),
                })
            },
            {
                title: '',
                dataIndex: 'amount_from',
                sorter: { compare: (a,b) => a.amount_from - b.amount_from },
                ...this.props.getColumnSearchProps({
                    dataIndex: 'amount_from',
                    render: (text, record) => (
                        <React.Fragment>
                            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2}
                                             value={record.amount_from}/>
                        </React.Fragment>
                    ),
                })
            },
            {
                title: 'To',
                dataIndex: 'currency_to',
                sorter: { compare: (a,b) => a.currency_to - b.currency_to },
                ...this.props.getColumnSearchProps({
                    dataIndex: 'currency_to',
                    render: (text, record) => (
                        <React.Fragment>
                            <div
                                className={`currency-flag currency-flag-${record.currency_to ? record.currency_to.toLowerCase() : ''}`}/>
                            &nbsp;&nbsp;
                            {record.currency_to}
                        </React.Fragment>
                    ),
                })
            },
            {
                title: '',
                dataIndex: 'amount_to',
                sorter: { compare: (a,b) => a.amount_to - b.amount_to },
                ...this.props.getColumnSearchProps({
                    dataIndex: 'amount_to',
                    render: (text, record) => (
                        <React.Fragment>
                            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2}
                                             value={record.amount_to}/>
                        </React.Fragment>
                    ),
                })
            },
            {
                title: 'Status',
                dataIndex: 'transfer_status',
                onFilter: (value, record) => record.transfer_status && record.transfer_status.startsWith(value),
                filters: this.state.transferStatusOptions,
                render: (text) => {
                    return <React.Fragment>
                            <span style={{color: text === "Funds Received" ? "green" : "red"}}>
                              {text}
                            </span>
                    </React.Fragment>
                }
            },
        ]
    }

    resetNPRPayout = (payoutId) => {
        console.log(payoutId);
        console.log("reset NPR payout");
        Modal.confirm({
            title: 'Are you sure?',
            content: `Are you sure you want to reset this payment request #${payoutId}`,
            onOk: () => {
                const loading = message.loading("Processing...", 0);
                API.post("commons", `/update/${payoutId}`, {
                    body: {
                        context: 'transfer_payouts',
                        data: {
                            payoutBankId: null,
                            payoutDate: null,
                            payoutRef: null
                        }
                    }
                }).then(() => {
                    message.success("NPR payout is reset.");
                }).finally(() => loading())
            }
        })
    }

    render() {
        // console.log(this.props);
        const {classes} = this.props;

        let tableData = this.buildTableData();
        let csvData = []
        let ninjaData = []
        if (!tableData) {
            return null;
        }

        csvData.push([
            "Transfer ID",
            "Transaction Date",
            "Settlement Date",
            "Client",
            "Beneficiary",
            "Payment Detail",
            "From Currency",
            "From Amount",
            "To Currency",
            "To Amount",
            "Status",
        ])

        tableData.map((item, index) => {
            csvData.push([
                item.id,
                moment(item.transaction_date).format("DD-MM-YYYY"),
                moment(item.settlement_date).format("DD-MM-YYYY"),
                item.client,
                item.beneficiary,
                item.detail_1,
                item.currency_from,
                item.amount_from,
                item.currency_to,
                item.amount_to,
                item.transfer_status
            ])
        })

        ninjaData.push([
            "Date",
            "Payment Detail",
            "To Amount",
        ])

        tableData.map((item, index) => {
            if (item.beneficiary && item.beneficiary.toLowerCase().includes('ninja') && item.transfer_status == "Funds Received") {
                ninjaData.push([
                    moment(item.settlement_date).format("DD-MM-YYYY"),
                    item.detail_1,
                    item.amount_to,
                ])
            }
        })

        const layout = {
            labelCol: {span: 24},
            wrapperCol: {span: 24},
        };

        return (
            <div>
                <Card>
                    <CardHeader color='rose' icon>
                        <CardIcon color='rose'>
                            <PermIdentity/>
                        </CardIcon>
                        <div className={classes.flexEnd}>
                            <h4 className={classes.cardIconTitle}>
                                Current Payouts
                            </h4>
                            <div>
                                <CSVLink data={csvData || []}>Download CSV</CSVLink>;
                                <Divider type="vertical"/>
                                <CSVLink data={ninjaData || []}>Ninja CSV</CSVLink>;
                                <Divider type="vertical"/>
                                <Button type='primary'
                                        onClick={() => this.nprReceived()}
                                >
                                    NPR Received
                                </Button>
                                <Divider type="vertical"/>
                                <Button type='primary'
                                        onClick={() => this.loadPayments()}
                                >
                                    Refresh List
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <Table
                                    rowKey={row => row.id}
                                    dataSource={tableData}
                                    loading={this.state.isDataListLoading}
                                    columns={this.buildColumns()}
                                    expandable={{
                                        expandedRowRender: (record) => {
                                            let totalPayouts = 0;
                                            for (var i = 0; i < record.payments.length; i++) {
                                                totalPayouts += Number.parseFloat(record.payments[i].amount_to)
                                            }
                                            const errorFlag = Number.parseFloat(record.amount_to) != (Math.round(totalPayouts * 100) / 100) ? true : false

                                            return (
                                                <Card>
                                                    <CardBody style={{backgroundColor: "#f9f9f9"}}>
                                                        <GridContainer>
                                                            <GridItem xs={12}>
                                                                <h5>Payments:</h5>
                                                                <table style={{width: "100%"}}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <th style={{textAlign: "left"}}>Payment ID</th>
                                                                        <th style={{textAlign: "left"}}>Beneficiary</th>
                                                                        <th style={{textAlign: "left"}}>Account</th>
                                                                        <th style={{textAlign: "left"}}>Currency</th>
                                                                        <th style={{textAlign: "left"}}>Amount</th>
                                                                        <th style={{textAlign: "left"}}>Payment</th>
                                                                    </tr>
                                                                    {record.payments.map((item, index) => {
                                                                        return (
                                                                            <tr key={index} style={{
                                                                                verticalAlign: "top",
                                                                                color: item.payoutDate ? "green" : ""
                                                                            }}>
                                                                                <td>
                                                                                    {item.id}
                                                                                </td>
                                                                                <td>
                                                                                    <strong>{item.beneficiaries_nickname}</strong><br/>
                                                                                    {item.ben_address_line_1}&nbsp;
                                                                                    {item.ben_address_line_2}&nbsp;
                                                                                    {item.ben_address_suburb}&nbsp;
                                                                                    {item.ben_address_state}&nbsp;
                                                                                    {item.ben_address_postcode}
                                                                                </td>
                                                                                <td>
                                                                                    SWIFT: {item.swift_code}<br/>
                                                                                    Account: {item.account_number}
                                                                                </td>
                                                                                <td>
                                                                                    {record.currency_to}
                                                                                </td>
                                                                                <td>
                                <span style={{color: errorFlag ? "red" : ""}}>
                                {Number.parseFloat(item.amount_to).toFixed(2)}
                               </span>
                                                                                    {errorFlag && (<span
                                                                                        style={{color: 'red'}}><br/>Warning: <br/>Payouts do not equal transfer amount</span>)}
                                                                                </td>
                                                                                <td>
                                                                                    {item.payoutDate ? moment(item.payoutDate).format("DD-MM-YYYY") : '[unpaid]'}
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {record.currency_to == 'NPR' && (
                                                                                            <Flex justify={'start'} gap={10}>
                                                                                                <Button
                                                                                                    disabled={(item.payoutDate ? true : false) || errorFlag}
                                                                                                    size="small"
                                                                                                    onClick={() => {
                                                                                                        this.props.history.push(`/backoffice/global-ime-code?tab=send_transaction&paymentID=${item.id}&clientID=${record.client_id}&transferID=${item.transfer_id}&beneficiary=${item.beneficiaries_id}`);
                                                                                                    }}
                                                                                                >
                                                                                                    GIME
                                                                                                </Button>
                                                                                                <Divider
                                                                                                    type="vertical"/>
                                                                                                <Button
                                                                                                    disabled={(item.payoutDate ? true : false) || errorFlag}
                                                                                                    size="small"
                                                                                                    onClick={() => {
                                                                                                        this.props.history.push(`/backoffice/mbl-code?tab=send_transaction&paymentID=${item.id}&clientID=${record.client_id}&transferID=${item.transfer_id}&beneficiaryID=${item.beneficiaries_id}`);
                                                                                                    }}
                                                                                                >
                                                                                                    MBL
                                                                                                </Button>
                                                                                                <Button
                                                                                                    disabled={item.payoutDate == null}
                                                                                                    type={'primary'}
                                                                                                    danger
                                                                                                    size="small"
                                                                                                    onClick={() => this.resetNPRPayout(item.id)}>
                                                                                                    Reset
                                                                                                </Button>
                                                                                            </Flex>
                                                                                        )}
                                                                                        {record.currency_to == 'PHP' && (
                                                                                            <>
                                                                                                <Button
                                                                                                    disabled={(item.payoutDate ? true : false) || errorFlag}
                                                                                                    size="small"
                                                                                                    onClick={() => {
                                                                                                        this.props.history.push(`/backoffice/boc-remit?tab=send_transaction&paymentID=${item.id}&clientID=${record.client_id}&transferID=${item.transfer_id}&beneficiaryID=${item.beneficiaries_id}`);
                                                                                                    }}
                                                                                                >
                                                                                                    BOC
                                                                                                </Button>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    <tr>
                                                                        <td>
                                                                            <Button
                                                                                type={'primary'}
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    this.backendComplete(record.id, record.payments[0].id);
                                                                                }}
                                                                            >
                                                                                Complete Deal
                                                                            </Button>
                                                                            &nbsp;
                                                                            <Button style={{marginBottom: '10px'}}
                                                                                type={'primary'}
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    this.sendPayoutEmail({
                                                                                        id: record.client_id,
                                                                                        currentStaffID: this.props.app_state.current_staff.id,
                                                                                        transfer_id: record.id,
                                                                                        transferID: record.id,
                                                                                        amount_from: record.amount_from,
                                                                                        amount_to: record.amount_to,
                                                                                        currency_from_id: record.currency_from_id,
                                                                                        currency_to_id: record.currency_to_id,
                                                                                        settlement_date: record.settlement_date,
                                                                                        // staffOnly: true
                                                                                    });
                                                                                }}
                                                                            >
                                                                                Payout Email (Client)
                                                                            </Button>
                                                                            &nbsp;
                                                                            <Button
                                                                                type={'primary'}
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    // console.log(record)
                                                                                    this.sendPayoutEmail({
                                                                                        id: record.client_id,
                                                                                        currentStaffID: this.props.app_state.current_staff.id,
                                                                                        transfer_id: record.id,
                                                                                        transferID: record.id,
                                                                                        amount_from: record.amount_from,
                                                                                        amount_to: record.amount_to,
                                                                                        currency_from_id: record.currency_from_id,
                                                                                        currency_to_id: record.currency_to_id,
                                                                                        settlement_date: record.settlement_date,
                                                                                        staffOnly: true
                                                                                    });
                                                                                }}
                                                                            >
                                                                                Payout Email (Staff Test)
                                                                            </Button>
                                                                            &nbsp;
                                                                            <a style={{display: 'block'}} href={`https://crm.forexworldwide.com/transfers/edit/${record.id}?tab=payout`}
                                                                               target="_blank">
                                                                                Link to Payout Email Page
                                                                            </a>
                                                                            <br/>
                                                                            {this.state.payout_email_sending && (
                                                                                <span>Payout email sending</span>)}
                                                                            {this.state.payout_email_sent && (
                                                                                <span>Payout email sent</span>)}
                                                                        </td>
                                                                        <td colSpan={5}>
                                                                            {
                                                                                record.documents != null && record.documents.length > 0
                                                                                    ?  <Collapse
                                                                                        size="small"
                                                                                        items={[{ key: record.id, label: 'Invoices List (Click to Expand)', children: <ol>{record.documents.map(item => <li><a style={{ cursor: 'pointer' }} onClick={ () => window.open(window.location.origin + '/transfers/documents/view/' + item.id, '_blank') }>INVOICE - {item.id}</a> (Click to open in a new tab) </li>)}</ol> }]}
                                                                                    />
                                                                                    : 'There are no any uploaded invoices or documents'
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </CardBody>
                                                </Card>
                                            );
                                        }
                                    }}
                                />

                            </GridItem>
                            <GridItem>
                                <strong>Total Payments: </strong>
                                <table>
                                    {this.state.totalArray.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {item.currency}
                                                </td>
                                                <td>
                                                    {Number.parseFloat(item.amount).toFixed(2)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </table>
                                <br/>
                                <strong>Received Payments: </strong>
                                <table>
                                    {this.state.receivedArray.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {item.currency}
                                                </td>
                                                <td>
                                                    {Number.parseFloat(item.amount).toFixed(2)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </table>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        // transfers: state.transfers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // fetchTransferList: () => {
        //   dispatch(fetchTransferList());
        // },
        // selectTransferUi: data => {
        //   dispatch(selectTransferUi(data));
        // }
    };
};

const PaymentsListPageContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListTable(PaymentsListPage)));

export default PaymentsListPageContainer;
