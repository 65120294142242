import {fetchCurrencyListNotDeleted} from "../../redux/actions/currencies";
import React, {useEffect, useState} from "react";
import {BackOfficeConstants} from "./BackOfficeUtil";
import {
    Alert,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Flex,
    Form,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Switch,
    Table,
    Typography,
    Upload
} from "antd";
import {useForm} from "antd/es/form/Form";
import {
    CalculatorOutlined,
    DeleteOutlined,
    EditOutlined,
    ReloadOutlined,
    SaveOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {API} from "aws-amplify";
import {AppUtil, CurrencyLocales} from "../../AppUtil";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";
import {connect} from "react-redux";
import * as XLSX from "xlsx";
import FileUploader from "../../components/Uploaders/FileUploader";

const ReconciliationReadOnly = ({app_state, currencies, fetchCurrencies}) => {

    const [reconForm] = useForm();
    const [reconCalcForm] = useForm();
    const [audInTransitForm] = useForm();
    const {id} = useParams();
    const [componentState, setComponentState] = useState({
        isModalOpen: false,
        activeIndexId: null,
        readonly: true,
        recordedDate: null,
        isTransitModalOpen: false,
        isAbModalOpen: false,
        entryValues: null,
        abModalDetail: {
            title: 'Amount Breakdown',
            isLoading: false,
            data: [],
            allData: {}
        }
    });
    const [currencyTotals, setCurrencyTotals] = useState({});
    const [apiLayerResponse, setApiLayerResponse] = useState({});
    const [dataSource, setDataSource] = useState([]);
    const [totalReconciliation, setTotalReconciliation] = useState({
        subTotalA: 0,
        subTotalB: 0,
        difference: 0
    });
    const handleSubmit = (values) => {
        if (componentState.activeIndexId !== null) {
            const source = [...dataSource];
            source[componentState.activeIndexId] = values;
            setDataSource(source);
        } else {
            setDataSource(prev => [...prev, values]);
        }
        setComponentState(prev => ({...prev, isModalOpen: false, activeIndexId: null}));
        reconForm.resetFields();
    }

    const onEditBtnClick = (index) => {
        const item = dataSource.find((i, k) => k === index);
        setComponentState(prev => ({...prev, activeIndexId: index, isModalOpen: true, entryValues: item}));
        reconForm.setFieldsValue(item);
    }

    const handleTransitForm = (values) => {
        const valuesWithZeros = Object.fromEntries(
            Object.entries(values).map(([key, value]) => [key, value === undefined ? 0 : value])
        );
        const sum = Object.keys(valuesWithZeros)
            .map((key, index) => {
                const exchangeRate = apiLayerResponse[key.toUpperCase()];
                const amountInAud = (valuesWithZeros[key] / exchangeRate) || 0;
                return key.toUpperCase() === 'AUD'
                    ? valuesWithZeros[key]
                    : amountInAud
            })
            .reduce((a, b) => a + b, 0);

        reconCalcForm.setFieldValue("aud_in_transit", sum);
        setComponentState(prev => ({...prev, isTransitModalOpen: false}));

    }

    const handleExcelUpload = (file) => {
        if (componentState.entryValues != null) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const workbook = XLSX.read(event.target.result, {type: 'binary'});
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const sheetData = XLSX.utils.sheet_to_json(sheet);

                let data = [];
                if (BackOfficeConstants.BANK_LABELS[componentState.entryValues.bank_name] === 'Worldpay') {
                    data = sheetData.map(i => ({key: i.Currency, value: i['Balance on End Date']}));
                } else if (BackOfficeConstants.BANK_LABELS[componentState.entryValues.bank_name] === 'Macquarie Business Bank') {
                    console.log(sheetData);
                    const sumByCurrency = sheetData.reduce((acc, curr) => {
                        const { CCY, 'Current bal.': currentBal } = curr;
                        if (!acc[CCY]) {
                            acc[CCY] = { CCY, 'Current bal.': 0 };
                        }
                        acc[CCY]['Current bal.'] += currentBal;
                        return acc;
                    }, {});
                    const uniques = Object.values(sumByCurrency);
                    data = uniques.map(i => ({key: i.CCY, value: i['Current bal.']}));
                }
                data.forEach(item => {
                    reconForm.setFieldValue(item.key.toLowerCase(), item.value);
                })
            };
            reader.readAsBinaryString(file);
            // Prevent default upload behavior
            return false;
        }
        return false;
    }

    const handleCSVUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, {type: 'binary'});
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet);

            const data = sheetData.map(i => {
                const splits = i.Description.split(' ');
                const lastIndex = splits.length - 1;
                const amountWithCurr = (splits[lastIndex].split(';')[0]).split(':');
                const currency = amountWithCurr[0];
                const amount = Number(amountWithCurr[1])
                return { currency: currency, amount: amount }
            });
            const currencySum = data.reduce((acc, curr) => {
                const { currency, amount: currentBal } = curr;
                if (!acc[currency]) {
                    acc[currency] = { currency, amount: 0 };
                }
                acc[currency].amount += currentBal;
                return acc;
            }, {});
            const uniques = Object.values(currencySum);
            uniques.forEach(item => audInTransitForm.setFieldsValue({ [item.currency.toLowerCase()]: item.amount }));
        };
        reader.readAsBinaryString(file);
        return false;
    }

    const buildColumns = () => {
        return BackOfficeConstants.COLUMNS.map((i, index) => {
            return {
                key: i.key,
                dataIndex: i.dataIndex,
                title: i.title,
                onHeaderCell: () => ({
                    style: { display: i.dataIndex === 'image_token' ? 'none' : '' },
                }),
                onCell: () => ({
                    style: { display: i.dataIndex === 'image_token' ? 'none' : '' },
                }),
                width: 'auto',
                render: (text, record, recordIndex) => {
                    if (i.dataIndex === 'bank_name') {
                        return BackOfficeConstants.BANK_LABELS[text];
                    } else if (i.dataIndex === 'action') {
                        return <Flex gap={5}>
                            <Button disabled={componentState.readonly} type={'primary'} size={'small'}
                                    icon={<EditOutlined/>}
                                    onClick={() => onEditBtnClick(recordIndex)}></Button>
                            <Button disabled={componentState.readonly} type={'primary'} danger={true} size={'small'}
                                    icon={<DeleteOutlined/>}
                                    onClick={() => {
                                        const source = dataSource.filter((i, index) => index !== recordIndex);
                                        setDataSource(source);
                                    }}></Button>
                        </Flex>
                    } else {
                        const rate = apiLayerResponse[i.dataIndex.toUpperCase()];
                        if (text != null && rate != null) {
                            return <Flex vertical={true} gap={5}>
                                <>{(text || 0).toLocaleString('en-Au', {
                                    useGrouping: true,
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}</>
                            </Flex>
                        }
                        return text || (0).toFixed(2);
                    }
                }
            };
        });
    }

    const performReconciliation = (values) => {
        const subTotalA = (calculateTotalAccountBalance() - (values.aud_in_transit || 0) + (values.misc_add_aud || 0)) - (values.misc_sub_aud || 0);
        const subTotalB = (calculateLedgerBalanceTotal() + (values.misc_add_aud_ledger || 0)) - (values.misc_sub_aud_ledger || 0);
        const difference = subTotalA - subTotalB;
        setTotalReconciliation(prev => ({...prev, subTotalA: subTotalA, subTotalB: subTotalB, difference: difference}));

    }

    const calculateLedgerBalanceTotal = () => {
        return Object.keys(currencyTotals).map((i, key) => (
            i !== 'AUD' ? (currencyTotals[i] !== 0 ? (currencyTotals[i] / apiLayerResponse[i]) : 0) : (currencyTotals[i] || 0)
        )).reduce((a, b) => a + b, 0);
    }

    const calculateTotalAccountBalance = () => {
        let bankEachCurrencyTotals = calculateBankEachCurrencyTotals(dataSource);
        bankEachCurrencyTotals = Object.keys(bankEachCurrencyTotals)
            .map(key => key === 'aud'
                ? (bankEachCurrencyTotals[key] || 0)
                : bankEachCurrencyTotals[key] / (apiLayerResponse[key.toUpperCase()]) || 0)
            .reduce((a, b) => a + b, 0);
        return bankEachCurrencyTotals;
    }

    const calculateBankEachCurrencyTotals = (dataSource) => {
        const excludeKeys = ['bank_name', 'action', 'image_token'];
        let totals = dataSource.reduce((acc, obj) => {
            for (const key in obj) {
                if (!excludeKeys.includes(key)) {
                    acc[key] = (acc[key] || 0) + (obj[key] || 0);
                }
            }
            return acc;
        }, {});
        return totals || 0
    }

    const saveReconciliation = () => {
        reconCalcForm.validateFields().then(values => {
            const datum = dataSource.map(obj => Object.fromEntries(
                Object.entries(obj).map(([key, value]) => [key, value === undefined ? 0 : value]))
            )
            const transit = Object.fromEntries(
                Object.entries(audInTransitForm.getFieldsValue()).map(([key, value]) => [key, value === undefined ? 0 : value])
            );
            let historyLedger = componentState.abModalDetail.allData;
            Object.keys(historyLedger).forEach(key => {
                historyLedger[key] = historyLedger[key].filter(i => !(i.balance == null || i.balance === 0));
            });
            API.post("reconciliation", `/update/reconciliation/${id}`, {
                body: {
                    staffId: app_state.current_staff.id,
                    dataSource: JSON.stringify(datum),
                    accountBalanceAUD: calculateTotalAccountBalance(),
                    ledgerBalanceAUD: calculateLedgerBalanceTotal(),
                    accountBalanceInTransit: values.aud_in_transit,
                    accountBalanceAdd: values.misc_add_aud,
                    accountBalanceSubtract: values.misc_sub_aud,
                    ledgerBalanceAdd: values.misc_add_aud_ledger,
                    ledgerBalanceSubtract: values.misc_sub_aud_ledger,
                    currencyTotals: JSON.stringify(currencyTotals),
                    apiLayerResponse: JSON.stringify(apiLayerResponse),
                    transitAmountJSON: JSON.stringify(transit),
                    difference: totalReconciliation.difference,
                    historyLedger: JSON.stringify(historyLedger)
                }
            }).then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
                message.error("Unable to save reconciliation. Please try again.");
            })
        })
    }

    const isEnableImport = () => {
        if (componentState.entryValues != null) {
            return BackOfficeConstants.BANK_LABELS[componentState.entryValues.bank_name] === 'Worldpay'
                || BackOfficeConstants.BANK_LABELS[componentState.entryValues.bank_name] === 'Macquarie Business Bank'
        }
        return false;
    }

    const viewAccountBalanceBreakDown = (record) => {
        setComponentState(prev => ({
            ...prev,
            isAbModalOpen: true,
            abModalDetail: {...prev.abModalDetail, title: `Account Balance of ${record.iso_alpha_3}`, isLoading: true}
        }))
        if (Object.keys(componentState.abModalDetail.allData).length > 0) {
            const accountResponse = componentState.abModalDetail.allData[record.iso_alpha_3].filter(i => i.totalBalance > 0);
            const clientIds = accountResponse.map(i => i.client_id);
            API.post("commons", "/fetch", {
                body: {
                    context: 'clients',
                    condition: {id: clientIds.filter(i => i != null)},
                    fields: ['id', 'first_name', 'last_name', 'email', 'nickname']
                }
            }).then(clientResponse => {
                accountResponse.forEach(i => {
                    i.client = clientResponse.find(k => i.client_id === k.id);
                });
                setComponentState(prev => ({
                    ...prev,
                    abModalDetail: {...prev.abModalDetail, data: accountResponse, isLoading: false}
                }));
            });
        } else {
            setComponentState(prev => ({
                ...prev,
                abModalDetail: {...prev.abModalDetail, data: [], isLoading: false}
            }));
        }
    }


    useEffect(() => {
        fetchCurrencies();
        API.post("commons", "/fetch", {
            body: {
                context: 'reconciliation',
                fields: ['*'],
                condition: {id: id}
            }
        }).then(response => {

            const [res] = response;

            setCurrencyTotals(res.currencyTotals != null ? JSON.parse(res.currencyTotals) : null);
            setApiLayerResponse(res.apiLayerResponse != null ? JSON.parse(res.apiLayerResponse) : null);

            const newSource = res.datasource != null
                ? JSON.parse(res.datasource).map(i => ({...i, image_token: i.image_token === 0 ? null : i.image_token}))
                : [];

            setDataSource(newSource);
            reconCalcForm.setFieldsValue({
                aud_in_transit: res.accountBalanceInTransit,
                misc_add_aud: res.accountBalanceAdd,
                misc_sub_aud: res.accountBalanceSubtract,
                misc_add_aud_ledger: res.ledgerBalanceAdd,
                misc_sub_aud_ledger: res.ledgerBalanceSubtract,
            });

            setComponentState(prev => ({
                ...prev,
                recordedDate: dayjs(res.recordCreated).format('DD/MM/YYYY HH:mm:ss'),
                abModalDetail: {
                    ...prev.abModalDetail,
                    allData: res.historyLedger != null ? JSON.parse(res.historyLedger) : {}
                },
            }));

            reconCalcForm.submit();

            if (res.transitAmountJSON != null) {
                audInTransitForm.setFieldsValue(JSON.parse(res.transitAmountJSON));
            }
        });
    }, [0]);

    return (
        <Card title={<Flex justify={'space-between'}>
            <span>Reconciliation View</span>
            <Form.Item label={'Read Only'} valuePropName={'checked'} style={{marginBottom: 0}}>
                <Switch checked={componentState.readonly} onClick={(e) => {
                    setComponentState(prev => ({...prev, readonly: e}))
                }}></Switch>
            </Form.Item>
        </Flex>}>
            <Alert type={'info'} style={{marginBottom: '10px'}} showIcon={true}
                   message={`Note: This reconciliation is based on the current exchange rate of that particular date and time (${componentState.recordedDate})`}/>
            <Button style={{marginBottom: '10px'}}
                    disabled={componentState.readonly}
                    type={'primary'} onClick={() => {
                setComponentState(prev => ({...prev, isModalOpen: true, activeIndexId: null}));
                reconForm.resetFields();
            }}>Add New Data</Button>
            <Modal open={componentState.isModalOpen}
                   style={{top: 20, zIndex: 1200}}
                   width={'720px'}
                   onOk={() => reconForm.submit()}
                   styles={{body: {maxHeight: '80vh', overflowY: 'scroll', padding: '10px'}}}
                   onCancel={() => setComponentState(prev => ({...prev, isModalOpen: false, activeIndexId: null}))}
                   title={'Add Entry'}>
                <Form form={reconForm}
                      disabled={componentState.readonly}
                      labelCol={{span: 10}}
                      labelAlign={'left'}
                      onValuesChange={(cVales, aValues) => setComponentState(prev => ({...prev, entryValues: aValues}))}
                      onFinish={handleSubmit}>
                    <Flex justify={'space-between'} gap={5}>
                        {/*<Upload beforeUpload={handleExcelUpload}><Button type={'primary'} icon={<UploadOutlined />}>Import Excel</Button></Upload>*/}
                        {isEnableImport() &&
                            <Upload beforeUpload={handleExcelUpload} showUploadList={false}><Button type={'primary'} size={'small'}
                                                                             icon={<UploadOutlined/>}>Import CSV</Button></Upload>}
                        {isEnableImport() &&
                            <Button type={'primary'} size={'small'} danger={true} onClick={() => {
                                reconForm.resetFields();
                            }} icon={<ReloadOutlined/>}>Reset Form</Button>}
                    </Flex>
                    <Divider />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Form.Item name="bank_name" label={'Bank Name'}
                                       rules={[{required: true, message: 'Field is required'}]}>
                                <Select options={BackOfficeConstants.BANK_LABELS.map((i, index) => ({
                                            ...i,
                                            label: i,
                                            value: index
                                        }))}></Select>
                            </Form.Item>
                            {
                                BackOfficeConstants.COLUMNS.filter(i => !(i.dataIndex === 'bank_name' || i.dataIndex === 'action' || i.dataIndex === 'image_token'))
                                    .map(i => {
                                        return <Form.Item name={i.dataIndex} label={i.title}>
                                            <InputNumber precision={2} style={{width: '200px'}}/>
                                        </Form.Item>
                                    })
                            }
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div key={componentState.activeIndexId} style={{padding: '10px', paddingTop: 0}}>
                                <Form.Item layout={'vertical'} name={'image_token'} label={'Upload Image'} getValueFromEvent={(e) => {
                                    return e.map(e => e.name);
                                }}>
                                    <FileUploader uploaderType={'AVATAR'} thumbType={'picture-card'} fetchFileList={() => {
                                        const record = dataSource[componentState.activeIndexId];
                                        const files = [];
                                        if (record != null && record.image_token != null) {
                                            const file = {
                                                filename: record.image_token,
                                                name: record.image_token,
                                                uid: record.image_token,
                                                status: "done"
                                            }
                                            files.push(file);
                                        }
                                        return Promise.resolve(files);
                                    }}></FileUploader>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Table bordered={true} size={'small'}
                           columns={buildColumns()}
                           style={{width: '100%', marginBottom: '10px'}}
                           virtual={true}
                           summary={(dataSource) => {
                               const totals = calculateBankEachCurrencyTotals(dataSource);
                               return (
                                   <>
                                       <Table.Summary.Row>
                                           <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                           {
                                               Object.keys(totals).map((currencyKey, index) => {
                                                   const exchangeRate = apiLayerResponse[currencyKey.toUpperCase()];
                                                   const amountInAud = (totals[currencyKey] / exchangeRate) || 0;
                                                   return <Table.Summary.Cell index={index + 1}>{currencyKey === 'aud'
                                                       ? AppUtil.currencyFormatter('AUD', totals[currencyKey] || 0)
                                                       : <Flex vertical={true}>
                                                           <span>{AppUtil.currencyFormatter(currencyKey.toUpperCase(), totals[currencyKey] || 0)}</span>
                                                           <span>{amountInAud !== 0
                                                               ? AppUtil.currencyFormatter('AUD', amountInAud)
                                                               : AppUtil.currencyFormatter('AUD', 0)}</span>
                                                       </Flex>
                                                   }
                                                   </Table.Summary.Cell>
                                               })
                                           }
                                       </Table.Summary.Row>
                                   </>
                               );
                           }}
                           dataSource={dataSource}></Table>
                </Col>
            </Row>
            <Divider/>
            <h3>Ledger Balances</h3>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Table bordered={true}
                           size={'small'}
                           pagination={{pageSize: 20}}
                           summary={(data) => {
                               return <Table.Summary.Row>
                                   <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                   <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                   <Table.Summary.Cell index={2}>{
                                       new Intl.NumberFormat('en-Au', {
                                           style: 'currency',
                                           minimumFractionDigits: 2,
                                           maximumFractionDigits: 2,
                                           currency: 'AUD',
                                           currencyDisplay: 'code'
                                       }).format(calculateLedgerBalanceTotal())
                                   }</Table.Summary.Cell>
                               </Table.Summary.Row>
                           }}
                           dataSource={Object.keys(currencyTotals).map((i, key) => ({
                               iso_alpha_3: i,
                               currency: <span className={`currency-flag currency-flag-${i.toLowerCase()}`}/>,
                               amount: new Intl.NumberFormat(CurrencyLocales[i], {
                                   style: 'currency',
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                   currency: i,
                                   currencyDisplay: 'code'
                               }).format(currencyTotals[i]),
                               audAmount: new Intl.NumberFormat('AUD', {
                                   style: 'currency',
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                   currency: 'AUD',
                                   currencyDisplay: 'code'
                               }).format(i !== 'AUD' ? (currencyTotals[i] !== 0 ? (currencyTotals[i] / apiLayerResponse[i]) : 0) : (currencyTotals[i] || 0))
                           }))} columns={[
                        {key: 1, dataIndex: 'currency', title: 'Currency'},
                        {
                            key: 2,
                            dataIndex: 'amount',
                            title: 'Amount',
                            render: (text, record) => <Typography.Link
                                onClick={() => viewAccountBalanceBreakDown(record)}>{text}</Typography.Link>
                        },
                        {key: 3, dataIndex: 'audAmount', title: 'Amount in (AUD)'},
                    ]}></Table>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <h3>Reconciliation</h3>
                    <Form form={reconCalcForm} disabled={componentState.readonly} name={'recon-calc-form'}
                          onFinish={performReconciliation}>
                        <Flex justify={'end'} gap={5}>
                            <Button htmlType={'submit'}
                                    type={'primary'}
                                    danger={true}
                                    onClick={() => {
                                        reconCalcForm.resetFields();
                                        setTotalReconciliation(prev => ({subTotalA: 0, subTotalB: 0, difference: 0}));
                                    }}
                                    icon={<ReloadOutlined/>}>Reset Fields</Button>
                            <Button htmlType={'submit'}
                                    type={'primary'}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Save current reconciliation ?',
                                            content: 'Are you sure you want to save this reconciliation ?',
                                            onOk: () => saveReconciliation()
                                        })
                                    }}
                                    icon={<SaveOutlined/>}>Save Reconciliation</Button>
                            <Form.Item>
                                <Button htmlType={'submit'} type={'primary'}
                                        icon={<CalculatorOutlined/>}>Calculate</Button>
                            </Form.Item>
                        </Flex>
                        <Descriptions column={1}
                                      bordered={true}
                                      style={{marginBottom: '10px'}}
                                      size={'small'}
                                      items={[
                                          {
                                              key: 'account-balance',
                                              label: 'Account Balance',
                                              children: new Intl.NumberFormat('en-Au', {
                                                  style: 'currency',
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                  useGrouping: true,
                                                  currency: 'AUD',
                                                  currencyDisplay: 'narrowSymbol'
                                              }).format(calculateTotalAccountBalance())
                                          },
                                          {
                                              key: 'aud-in-transit',
                                              label: 'Aud in Transit',
                                              children: <Flex justify={'start'} gap={5}>
                                                  <Form.Item shouldUpdate style={{marginBottom: 0}}
                                                             name="aud_in_transit">
                                                      <InputNumber precision={2} readOnly={true}/>
                                                  </Form.Item>
                                                  <Typography.Link onClick={() => setComponentState(prev => ({
                                                      ...prev,
                                                      isTransitModalOpen: true
                                                  }))}>(Add Amount)</Typography.Link>
                                              </Flex>
                                          },
                                          {
                                              key: 'misc-add-aud',
                                              label: 'Misc Add AUD',
                                              children: <Form.Item shouldUpdate style={{marginBottom: 0}}
                                                                   name="misc_add_aud">
                                                  <InputNumber precision={2}/>
                                              </Form.Item>
                                          },
                                          {
                                              key: 'misc-subtract-aud',
                                              label: 'Misc Subtract AUD',
                                              children: <Form.Item shouldUpdate style={{marginBottom: 0}}
                                                                   name="misc_sub_aud">
                                                  <InputNumber precision={2}/>
                                              </Form.Item>
                                          },
                                          {
                                              key: 'subtotal-aud',
                                              label: 'Sub Total A',
                                              children: totalReconciliation.subTotalA === 0
                                                  ? <>0 <Typography.Link onClick={() => reconCalcForm.submit()}>(Click
                                                      Calculate)</Typography.Link></>
                                                  : totalReconciliation.subTotalB === 0
                                                      ? <>0 <Typography.Link onClick={() => reconCalcForm.submit()}>(Click
                                                          Calculate)</Typography.Link></>
                                                      : new Intl.NumberFormat('en-Au', {
                                                          style: 'currency',
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                          useGrouping: true,
                                                          currency: 'AUD',
                                                          currencyDisplay: 'narrowSymbol'
                                                      }).format(totalReconciliation.subTotalA)
                                          }
                                      ]}>
                        </Descriptions>
                        <Descriptions column={1}
                                      bordered={true}
                                      style={{marginBottom: '10px'}}
                                      items={[
                                          {
                                              key: 'ledger-balance-total-aud',
                                              label: 'Ledger Balance Total',
                                              children: new Intl.NumberFormat('en-Au', {
                                                  style: 'currency',
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                  useGrouping: true,
                                                  currency: 'AUD',
                                                  currencyDisplay: 'narrowSymbol'
                                              }).format(calculateLedgerBalanceTotal())
                                          },
                                          {
                                              key: 'misc_add_aud_ledger',
                                              label: 'Misc Add AUD Ledger',
                                              children: <Form.Item shouldUpdate style={{marginBottom: 0}}
                                                                   name="misc_add_aud_ledger">
                                                  <InputNumber precision={2}/>
                                              </Form.Item>
                                          },
                                          {
                                              key: 'misc_sub_aud_ledger',
                                              label: 'Misc Sub AUD Ledger',
                                              children: <Form.Item shouldUpdate style={{marginBottom: 0}}
                                                                   name="misc_sub_aud_ledger">
                                                  <InputNumber precision={2}/>
                                              </Form.Item>
                                          },
                                          {
                                              key: 'sub_total_b_aud',
                                              label: 'Sub Total B',
                                              children: totalReconciliation.subTotalB === 0
                                                  ? <>0 <Typography.Link onClick={() => reconCalcForm.submit()}>(Click
                                                      Calculate)</Typography.Link></>
                                                  : new Intl.NumberFormat('en-Au', {
                                                      style: 'currency',
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                      useGrouping: true,
                                                      currency: 'AUD',
                                                      currencyDisplay: 'narrowSymbol'
                                                  }).format(totalReconciliation.subTotalB)
                                          },
                                      ]}
                                      size={'small'}>
                        </Descriptions>
                        <Modal open={componentState.isTransitModalOpen}
                               title={'Add Currency Values to AUD in transit'}
                               form={audInTransitForm}
                               style={{top: 20}}
                               styles={{body: {maxHeight: '80vh', overflowY: 'scroll', padding: '10px'}}}
                               onOk={() => audInTransitForm.submit()}
                               onCancel={() => {
                                   audInTransitForm.resetFields();
                                   setComponentState(prev => ({...prev, isTransitModalOpen: false}));
                               }}>
                            <Divider style={{marginTop: 0}}/>
                            <Form name={'aud-in-transit-form'} form={audInTransitForm} onFinish={handleTransitForm}>
                                <Upload beforeUpload={handleCSVUpload} showUploadList={false}><Button type={'primary'} size={'small'}
                                                                                                      icon={<UploadOutlined/>}>Import
                                    CSV</Button></Upload>
                                <Divider />
                                {
                                    BackOfficeConstants.COLUMNS.filter(i => !(i.dataIndex === 'bank_name' || i.dataIndex === 'action' || i.dataIndex === 'image_token'))
                                        .map(i => {
                                            return <Flex justify={'start'} gap={5} align={'center'}>
                                                <Form.Item name={i.dataIndex} label={i.title}>
                                                    <InputNumber precision={2} style={{width: '200px'}}/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Typography.Text>(Rate:
                                                        ${i.dataIndex.toUpperCase() === 'AUD' ? '1.00' : apiLayerResponse[i.dataIndex.toUpperCase()] || 0})</Typography.Text>
                                                </Form.Item>

                                            </Flex>
                                        })
                                }
                            </Form>

                        </Modal>
                        <Descriptions column={1}
                                      bordered={true}
                                      size={'small'}
                                      style={{marginBottom: '10px'}}
                                      items={[
                                          {
                                              key: 'difference',
                                              label: 'Difference',
                                              children: new Intl.NumberFormat('en-Au', {
                                                  style: 'currency',
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                  useGrouping: true,
                                                  currency: 'AUD',
                                                  currencyDisplay: 'narrowSymbol'
                                              }).format(totalReconciliation.difference)
                                          }
                                      ]}></Descriptions>
                    </Form>
                </Col>
            </Row>
            <Modal open={componentState.isAbModalOpen}
                   width={'900px'}
                   title={componentState.abModalDetail.title}
                   style={{top: 10, zIndex: 1200}}
                   styles={{body: {maxHeight: '80vh', overflowY: 'scroll', padding: '10px'}}}
                   okButtonProps={{hidden: true}}
                   cancelText={'Close'}
                   onCancel={() => setComponentState(prev => ({...prev, isAbModalOpen: false}))}>
                <Table bordered={true} columns={[
                    {
                        key: 'id',
                        dataIndex: 'id',
                        title: 'ID',
                        sorter: (a, b) => a.id - b.id,
                        defaultSortOrder: 'descend'
                    },
                    {
                        key: 'client_id',
                        dataIndex: 'client_id',
                        title: 'Client',
                        render: (text, record) => <Flex justify={'start'}>
                            <Typography.Text>{record.client != null ? record.client.nickname : '-'}</Typography.Text>
                        </Flex>
                    },
                    {
                        key: 'totalBalance',
                        dataIndex: 'balance',
                        title: 'Account Balance',
                        render: (text, record) => {
                            const currency = currencies.currencies.find(i => i.id === record.currency_id);
                            if (currency) {
                                return new Intl.NumberFormat(CurrencyLocales[currency.iso_alpha_3], {
                                    useGrouping: true,
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: currency.iso_alpha_3
                                }).format(text || 0);
                            }
                        }
                    }
                ]}
                       dataSource={componentState.abModalDetail.data}
                       loading={componentState.abModalDetail.isLoading}></Table>
            </Modal>
        </Card>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
        currencies: state.currencies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCurrencies: () => dispatch(fetchCurrencyListNotDeleted())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReconciliationReadOnly);