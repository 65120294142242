import {RISK_SCORE_OPTIONS as riskScoreOptions} from '../duck/constants';

export const RiskComplianceRating = {
    LOW_RISK: 1,
    MEDIUM: 2,
    HIGH: 3,
    VERY_HIGH: 4
};
export const RiskConstants = {
    GetComplianceRiskRating: riskLevel => {
        if (riskLevel == null) {
            return 'NOT_CALCULATED';
        } else if (riskLevel === RiskComplianceRating.LOW_RISK) {
            return 'LOW';
        } else if (riskLevel === RiskComplianceRating.MEDIUM) {
            return 'MEDIUM';
        } else if (riskLevel === RiskComplianceRating.HIGH) {
            return 'HIGH';
        } else if (riskLevel === RiskComplianceRating.VERY_HIGH) {
            return 'DECLINE';
        } else {
            return 'NOT_CALCULATED';
        }
    },
    GetRiskRating: score => {
        if (score == null) {
            return 'NOT_CALCULATED';
        } else if (score >= 0 && score <= riskScoreOptions.LOW_RISK) {
            return 'LOW';
        } else if (score > 0 && score <= riskScoreOptions.MEDIUM) {
            return 'MEDIUM';
        } else if (score > 0 && score <= riskScoreOptions.HIGH) {
            return 'HIGH';
        } else if (score > riskScoreOptions.HIGH) {
            return 'DECLINE';
        } else {
            return 'NOT_CALCULATED';
        }
    },
    GetComplianceRiskColor: riskLevel => {
        if (riskLevel == null) {
            return 'default';
        } else if (riskLevel === RiskComplianceRating.LOW_RISK) {
            return 'green';
        } else if (riskLevel === RiskComplianceRating.MEDIUM) {
            return 'blue';
        } else if (riskLevel === RiskComplianceRating.HIGH) {
            return 'warning';
        } else if (riskLevel === RiskComplianceRating.VERY_HIGH) {
            return 'volcano';
        } else {
            return 'default';
        }
    },
    GetRiskColor: score => {
        if (score == null) {
            return 'default';
        } else if (score >= 0 && score <= riskScoreOptions.LOW_RISK) {
            return 'green';
        } else if (score > 0 && score <= riskScoreOptions.MEDIUM) {
            return 'blue';
        } else if (score > 0 && score <= riskScoreOptions.HIGH) {
            return 'warning';
        } else if (score > riskScoreOptions.HIGH) {
            return 'volcano';
        } else {
            return 'default';
        }
    }
};

export const RISK_CUSTOMER_DETAIL = [
    {
        key: 'i_legal_structure',
        label: 'Legal Structure',
        options: [
            {
                label: 'Individual and Sole Trader (incl. Beneficial Owners and Signatories)',
                value: 1,
                score: 0,
                riskLevel: 1
            }
        ]
    },
    {
        key: 'i_complexity',
        label: 'Complexity',
        options: [
            {
                label: '1 Layers to Ultimate Beneficial Owners',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: '2-3 Layers to Ultimate Beneficial Owners',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: '4-5 Layers to Ultimate Beneficial Owners',
                value: 3,
                score: 2,
                riskLevel: 1
            },
            {
                label: '> 5 Layers to Ultimate Beneficial Owners',
                value: 4,
                score: 3,
                riskLevel: 2
            }
        ]
    },
    {
        key: 'i_jurisdiction',
        label: 'jurisdictions',
        options: []
    },
    {
        key: 'i_residency_status',
        label: 'Residency Status',
        options: [
            {
                label: 'AU Citizen',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'AU Permanent Resident',
                value: 2,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Foreign Citizen and/or Permanent Resident',
                value: 3,
                score: 2,
                riskLevel: 2
            }
        ]
    },
    {
        key: 'i_product_service',
        label: 'Product / Service',
        options: [
            {
                label: 'Remittance Services (FX Spots)',
                value: 1,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Remittance Services (FX Forward Contracts)',
                value: 2,
                score: 2,
                riskLevel: 1
            }
        ]
    },
    {
        key: 'i_channel_sales_referral',
        label: 'Channel Sales Referral',
        options: [
            {
                label: 'Personal Referral and/or Introduction',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Other Sales Referral Channel',
                value: 2,
                score: 2,
                riskLevel: 2
            }
        ]
    },
    {
        key: 'i_channel_service_access',
        label: 'Channel Service Access',
        options: [
            {
                label: 'Face-to-Face Channel',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Non-Face-to-Face Channel (e.g., Internet)',
                value: 2,
                score: 2,
                riskLevel: 2
            }
        ]
    },
    {
        key: 'i_source_of_funds',
        label: 'Source of Funds',
        options: [
            {
                label: 'Salaries and Wages (incl. Bonuses)',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Government Benefits (e.g., Childcare Rebate, Tax Benefit)',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Business Income',
                value: 3,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Investment Income (e.g., Rent, Dividends, Pension)',
                value: 4,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'One-Off Payment (e.g., Matured Investment, Court Settlement)',
                value: 5,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Sale of Assets  (e.g., Shares, Property)',
                value: 6,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Borrowed Funds',
                value: 7,
                score: 4,
                riskLevel: 2
            },
            {
                label: 'Windfall Gain (e.g., Gift, Lottery, Winnings, Gambling)',
                value: 8,
                score: 9,
                riskLevel: 3
            },
            {
                label: 'Unsupported Value (incl. Failure to Provide Information)',
                value: 9,
                score: 9,
                riskLevel: 3
            }
        ]
    },
    {
        key: 'i_source_of_wealth',
        label: 'Source of Wealth',
        options: [
            {
                label: 'Salaries and Wages (incl. Bonuses)',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Government Benefits (e.g., Childcare Rebate, Tax Benefit)',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Business Income',
                value: 3,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Investment Income (e.g., Rent, Dividends, Pension)',
                value: 4,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'One-Off Payment (Matured Investment, Court Settlement, Pension Tfr, Inheritance)',
                value: 5,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Sale of Assets  (e.g., Shares, Property)',
                value: 6,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Borrowed Funds',
                value: 7,
                score: 4,
                riskLevel: 2
            },
            {
                label: 'Windfall Gain (e.g., Gift, Lottery, Winnings, Gambling)',
                value: 8,
                score: 9,
                riskLevel: 3
            },
            {
                label: 'Unsupported Value (incl. Failure to Provide Information)',
                value: 9,
                score: 9,
                riskLevel: 1
            }
        ]
    },
    {
        key: 'i_business_relationship',
        label: 'Nature of Business Relationship',
        options: [
            {
                label: 'Personal - General FX Services',
                value: 16,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Personal - Property (purchase, sale, management)',
                value: 4,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Personal - Education',
                value: 1,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Personal - Entertainment',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Personal - Migration',
                value: 3,
                score: 2,
                riskLevel: 1
            },
            {
                label: 'Personal - Savings and/or Pension',
                value: 5,
                score: 3,
                riskLevel: 2
            },
            {
                label: 'Business - General FX Services',
                value: 17,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Business - Asset Purchases and/or Sales',
                value: 6,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Business - Import/Export Goods',
                value: 18,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Business - International Payments (both Ingoing and Outgoing)',
                value: 8,
                score: 1,
                riskLevel: 2
            },
            {
                label: 'Business - FX Risk Management',
                value: 7,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Business - Salaries and Wages (incl. Staff Payments)',
                value: 9,
                score: 3,
                riskLevel: 2
            },
            {
                label: 'Business - Taxation',
                value: 10,
                score: 3,
                riskLevel: 2
            },
            {
                label: 'Sport - Sporting Tours',
                value: 13,
                score: 0,
                riskLevel: 2
            },
            {
                label: 'Sport - Individual Athletes',
                value: 11,
                score: 1,
                riskLevel: 2
            },
            {
                label: 'Sport - Sports Teams',
                value: 12,
                score: 1,
                riskLevel: 2
            },
            {
                label: 'Sport - Imports and Exports of Goods',
                value: 15,
                score: 1,
                riskLevel: 2
            },
            {
                label: 'Sport - Sponsorship and Events',
                value: 14,
                score: 2,
                riskLevel: 2
            },
        ]
    },
    {
        key: 'i_expected_account_activity',
        label: 'Expected Account Activity',
        options: [
            {
                label: 'Low Turnover (<5 Transactions per Month)',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Medium Turnover (5 - 10 Transactions per Month)',
                value: 2,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'High Turnover (>10 Transactions per Month)',
                value: 3,
                score: 3,
                riskLevel: 2
            }
        ]
    },
    {
        key: 'i_customer_operations',
        label: 'Customer Operations',
        options: [
            {
                label: 'Other Customer Operations',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Third Party Import/Export Business',
                value: 7,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Cash-Intensive Businesses',
                value: 2,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'Casinos and/or Gamblng Establishments',
                value: 3,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'Charity and/or Not-For-Profit Organisations',
                value: 4,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'Digital Currency Providers',
                value: 5,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'High Value and/or Precious Goods Dealers (e.g., Jewellery Dealers)',
                value: 6,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'Vaping or Vaping-Related Suppliers',
                value: 9,
                score: 12,
                riskLevel: 3
            }
        ]
    },
    {
        key: 'i_estimated_net_worth',
        label: 'Estimated Net Worth',
        options: [
            {
                label: '< $250,000',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: '$250,000 - $500,000',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: '$500,001 - 1,000,000',
                value: 3,
                score: 2,
                riskLevel: 2
            },
            {
                label: '$1,000,001 - $10,000,000',
                value: 4,
                score: 3,
                riskLevel: 2
            },
            {
                label: '>$10,000,000',
                value: 5,
                score: 4,
                riskLevel: 2
            }
        ]
    }
];

export const CUSTOMER_SCREEN_OUTCOMES = [
    {
        key: 'i_political_person',
        label: 'Politically Exposed Person',
        options: [
            {
                label: 'Pass',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Fail - Former PEP',
                value: 2,
                score: 10,
                riskLevel: 3
            },
            {
                label: 'Fail - Domestic PEP',
                value: 3,
                score: 26,
                riskLevel: 4
            },
            {
                label: 'Fail - Foreign PEP',
                value: 4,
                score: 26,
                riskLevel: 4
            },
            {
                label: 'Fail - International Organisation PEP',
                value: 5,
                score: 26,
                riskLevel: 4
            }
        ]
    },
    {
        key: 'i_sanctions',
        label: 'sanctions',
        options: [
            {
                label: 'Pass',
                value: 6,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Fail',
                value: 7,
                score: 26,
                riskLevel: 4
            }
        ]
    },
    {
        key: 'i_criminal_record',
        label: 'Criminal Record',
        options: [
            {
                label: 'Pass',
                value: 6,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Fail',
                value: 7,
                score: 26,
                riskLevel: 4
            }
        ]
    },
    {
        key: 'i_terrorism',
        label: 'Terrorism / Terrorism Financing',
        options: [
            {
                label: 'Pass',
                value: 6,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Fail',
                value: 7,
                score: 26,
                riskLevel: 4
            }
        ]
    }
];

export const RISK_CUSTOMER = {
    DETAILS: {label: 'Customer Details', key: 'customer_details', options: RISK_CUSTOMER_DETAIL},
    SCREEN_OUTCOMES: {
        label: 'Customer Screening Outcomes',
        key: 'customer_screen_outcomes',
        options: CUSTOMER_SCREEN_OUTCOMES
    }
};

export const RISK_COMPANY_DETAIL = [
    {
        key: 'c_legal_structure',
        label: 'Legal Structure',
        options: [
            {
                label: 'Partnership',
                value: 5,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Incorporated Association',
                value: 6,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Unincorporated Association',
                value: 7,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Registered Co-Operative',
                value: 8,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Government Body',
                value: 9,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Domestic Company',
                value: 1,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Registered Foreign Company',
                value: 2,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Unregistered Foreign Company',
                value: 3,
                score: 3,
                riskLevel: 3
            },
            {
                label: 'Trust',
                value: 4,
                score: 3,
                riskLevel: 3
            },
        ]
    },
    {
        key: 'c_complexity',
        label: 'Complexity',
        options: [
            {
                label: '1 Layers to Ultimate Beneficial Owners',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: '2-3 Layers to Ultimate Beneficial Owners',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: '4-5 Layers to Ultimate Beneficial Owners',
                value: 3,
                score: 2,
                riskLevel: 1
            },
            {
                label: '> 5 Layers to Ultimate Beneficial Owners',
                value: 4,
                score: 3,
                riskLevel: 2
            }
        ]
    },
    {
        key: 'c_jurisdiction',
        label: 'jurisdictions',
        options: []
    },
    {
        key: 'c_product_service',
        label: 'Product / Service',
        options: [
            {
                label: 'Remittance Services (FX Spots)',
                value: 1,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Remittance Services (FX Forward Contracts)',
                value: 2,
                score: 2,
                riskLevel: 1
            }
        ]
    },
    {
        key: 'c_channel_sales_referral',
        label: 'Channel Sales Referral',
        options: [
            {
                label: 'Personal Referral and/or Introduction',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Other Sales Referral Channel',
                value: 2,
                score: 2,
                riskLevel: 2
            }
        ]
    },
    {
        key: 'c_channel_service_access',
        label: 'Channel Service Access',
        options: [
            {
                label: 'Face-to-Face Channel',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Non-Face-to-Face Channel (e.g., Internet)',
                value: 2,
                score: 2,
                riskLevel: 2
            }
        ]
    },
    {
        key: 'c_channel_funding_method',
        label: 'Funding Method',
        options: [
            {
                label: 'Electronic Funds Transfer',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Cash, Cheque and/or Agent Transfer',
                value: 2,
                score: 26,
                riskLevel: 4
            }
        ]
    },
    {
        key: 'c_channel_product_delivery_channel',
        label: 'Product Delivery Channel',
        options: [
            {
                label: 'Beneficiary Maintained Account',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Other (cash, e-Wallet)',
                value: 2,
                score: 26,
                riskLevel: 4
            }
        ]
    },
    {
        key: 'c_source_of_funds',
        label: 'Source of Funds',
        options: [
            {
                label: 'Salaries and Wages (incl. Bonuses)',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Government Benefits (e.g., Childcare Rebate, Tax Benefit)',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Business Income',
                value: 3,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Investment Income (e.g., Rent, Dividends, Pension)',
                value: 4,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'One-Off Payment (e.g., Matured Investment, Court Settlement)',
                value: 5,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Sale of Assets  (e.g., Shares, Property)',
                value: 6,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Borrowed Funds',
                value: 7,
                score: 4,
                riskLevel: 2
            },
            {
                label: 'Windfall Gain (e.g., Gift, Lottery, Winnings, Gambling)',
                value: 8,
                score: 9,
                riskLevel: 3
            },
            {
                label: 'Unsupported Value (incl. Failure to Provide Information)',
                value: 9,
                score: 9,
                riskLevel: 3
            }
        ]
    },
    {
        key: 'c_source_of_wealth',
        label: 'Source of Wealth',
        options: [
            {
                label: 'Salaries and Wages (incl. Bonuses)',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Government Benefits (e.g., Childcare Rebate, Tax Benefit)',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Business Income',
                value: 3,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Investment Income (e.g., Rent, Dividends, Pension)',
                value: 4,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'One-Off Payment (e.g., Matured Investment, Court Settlement)',
                value: 5,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Sale of Assets  (e.g., Shares, Property)',
                value: 6,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Borrowed Funds',
                value: 7,
                score: 4,
                riskLevel: 2
            },
            {
                label: 'Windfall Gain (e.g., Gift, Lottery, Winnings, Gambling)',
                value: 8,
                score: 9,
                riskLevel: 3
            },
            {
                label: 'Unsupported Value (incl. Failure to Provide Information)',
                value: 9,
                score: 9,
                riskLevel: 3
            }
        ]
    },
    {
        key: 'c_business_relationship',
        label: 'Nature of Business Relationship',
        options: [
            {
                label: 'Personal - General FX Services',
                value: 16,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Personal - Property (purchase, sale, management)',
                value: 4,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Personal - Education',
                value: 1,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Personal - Entertainment',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Personal - Migration',
                value: 3,
                score: 2,
                riskLevel: 1
            },
            {
                label: 'Personal - Savings and/or Pension',
                value: 5,
                score: 3,
                riskLevel: 2
            },
            {
                label: 'Business - General FX Services',
                value: 17,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Business - Asset Purchases and/or Sales',
                value: 6,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Business - Import/Export Goods',
                value: 18,
                score: 1,
                riskLevel: 1
            },
            {
                label: 'Business - International Payments (both Ingoing and Outgoing)',
                value: 8,
                score: 1,
                riskLevel: 2
            },
            {
                label: 'Business - FX Risk Management',
                value: 7,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Business - Salaries and Wages (incl. Staff Payments)',
                value: 9,
                score: 3,
                riskLevel: 2
            },
            {
                label: 'Business - Taxation',
                value: 10,
                score: 3,
                riskLevel: 2
            },
            {
                label: 'Sport - Sporting Tours',
                value: 13,
                score: 0,
                riskLevel: 2
            },
            {
                label: 'Sport - Individual Athletes',
                value: 11,
                score: 1,
                riskLevel: 2
            },
            {
                label: 'Sport - Sports Teams',
                value: 12,
                score: 1,
                riskLevel: 2
            },
            {
                label: 'Sport - Imports and Exports of Goods',
                value: 15,
                score: 1,
                riskLevel: 2
            },
            {
                label: 'Sport - Sponsorship and Events',
                value: 14,
                score: 2,
                riskLevel: 2
            },
        ]
    },
    {
        key: 'c_expected_account_activity',
        label: 'Expected Account Activity',
        options: [
            {
                label: 'Low Turnover (<5 Transactions per Month)',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Medium Turnover (5 - 10 Transactions per Month)',
                value: 2,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'High Turnover (>10 Transactions per Month)',
                value: 3,
                score: 4,
                riskLevel: 2
            }
        ]
    },
    {
        key: 'c_customer_operations',
        label: 'Customer Operations',
        options: [
            {
                label: 'Other Customer Operations',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Third Party Import/Export Business',
                value: 7,
                score: 2,
                riskLevel: 2
            },
            {
                label: 'Cash-Intensive Businesses',
                value: 2,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'Casinos and/or Gamblng Establishments',
                value: 3,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'Charity and/or Not-For-Profit Organisations',
                value: 4,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'Digital Currency Providers',
                value: 5,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'High Value and/or Precious Goods Dealers (e.g., Jewellery Dealers)',
                value: 6,
                score: 12,
                riskLevel: 3
            },
            {
                label: 'Vaping or Vaping-Related Suppliers',
                value: 9,
                score: 12,
                riskLevel: 3
            }
        ]
    },
    {
        key: 'c_estimated_net_worth',
        label: 'Estimated Net Worth',
        options: [
            {
                label: '< $250,000',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: '$250,000 - $500,000',
                value: 2,
                score: 1,
                riskLevel: 1
            },
            {
                label: '$500,001 - 1,000,000',
                value: 3,
                score: 2,
                riskLevel: 2
            },
            {
                label: '$1,000,001 - $10,000,000',
                value: 4,
                score: 3,
                riskLevel: 2
            },
            {
                label: '>$10,000,000',
                value: 5,
                score: 4,
                riskLevel: 2
            }
        ]
    }
];

export const COMPANY_OUTCOMES = {
    pep: {id: 'c_political_person', value: 'pep'},
    sanctions: {id: 'c_sanctions', value: 'sanctions'},
    criminalRecord: {id: 'c_criminal_record', value: 'criminalRecord'},
    terrorism: {id: 'c_terrorism', value: 'terrorism'}
};
export const COMPANY_SCREEN_OUTCOMES = [
    {
        key: COMPANY_OUTCOMES.pep.id,
        formKey: COMPANY_OUTCOMES.pep.value,
        label: 'Politically Exposed Person',
        options: [
            {
                label: 'Pass',
                value: 1,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Fail - Former PEP',
                value: 2,
                score: 10,
                riskLevel: 3
            },
            {
                label: 'Fail - Domestic PEP',
                value: 3,
                score: 26,
                riskLevel: 4
            },
            {
                label: 'Fail - Foreign PEP',
                value: 4,
                score: 26,
                riskLevel: 4
            },
            {
                label: 'Fail - International Organisation PEP',
                value: 5,
                score: 26,
                riskLevel: 4
            }
        ]
    },
    {
        key: COMPANY_OUTCOMES.sanctions.id,
        formKey: COMPANY_OUTCOMES.sanctions.value,
        label: 'Sanctions',
        options: [
            {
                label: 'Pass',
                value: 6,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Fail',
                value: 7,
                score: 26,
                riskLevel: 4
            }
        ]
    },
    {
        key: COMPANY_OUTCOMES.criminalRecord.id,
        formKey: COMPANY_OUTCOMES.criminalRecord.value,
        label: 'Criminal Record',
        options: [
            {
                label: 'Pass',
                value: 6,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Fail',
                value: 7,
                score: 26,
                riskLevel: 4
            }
        ]
    },
    {
        key: COMPANY_OUTCOMES.terrorism.id,
        formKey: COMPANY_OUTCOMES.terrorism.value,
        label: 'Terrorism / Terrorism Financing',
        options: [
            {
                label: 'Pass',
                value: 6,
                score: 0,
                riskLevel: 1
            },
            {
                label: 'Fail',
                value: 7,
                score: 26,
                riskLevel: 4
            }
        ]
    }
];
