import React from 'react';
import {API} from 'aws-amplify';

import {connect} from 'react-redux';
import {fetchClientList} from '../../redux/actions/clients';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import {CSVLink} from 'react-csv';
import {Input, Select, Table, Tag, Typography} from 'antd';

// const moment = require('moment');
import ListTable from "../ListTable/ListTable";
import dayjs from "dayjs";


var moment = require('moment');

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};


const columns2 = [
    {
        title: 'Created',
        dataIndex: 'record_created_datetime',
        key: 'record_created_datetime',
    },
]

class ReportsClientRisk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client_list: [],
            entity_list: [],
            dialog_delete_confirm: false,
            dialog_delete_item: 0,
            dialog_delete_item_name: '',
            team_list: [],
            team: '',
            table_data: [],
            finished: false,
            dataToDownload: []
        };
    }

    componentDidMount() {
        this.getTeamList()
    }

    buildColumns = () => {
        return [
            {
                title: 'Created',
                dataIndex: 'record_created_datetime',
                key: 'record_created_datetime',
                render: (text, record) => {
                    return moment(record.record_created_datetime).format('DD-MM-YYYY');
                }
            },
            {
                title: 'Client ID',
                dataIndex: 'id',
                key: 'id',
                sorter: (a,b) => a.id - b.id,
                ...this.props.getColumnSearchProps({
                    dataIndex: 'id',
                    render: (text) => {
                        return <Typography.Link onClick={() => window.open(`/clients/edit/${text}`, '_blank')}>{text}</Typography.Link>
                    }
                })

            },
            {
                title: 'Client Name',
                dataIndex: 'nickname',
                key: 'nickname',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'nickname'
                })
            },
            {
              title: 'Client Type',
              dataIndex: 'account_type',
              key: 'account_type',
              filters: [
                  {text: 'Personal', value: 1},
                  {text: 'Business', value: 2}
              ],
              onFilter: (value, record) => record.account_type && record.account_type === value,
              render: (text, record) => {
                return text === 1 ? 'Personal' : (text === 2 ? 'Business' : '-')
              }
            },
            {
                title: 'Account Business Type',
                dataIndex: 'account_business_type',
                key: 'account_business_type',
                filters: [
                    {text: '-', value: null},
                    {text: 'Sole Trader', value: 1},
                    {text: 'Company', value: 2},
                    {text: 'Trust', value: 3}
                ],
                onFilter: (value, record) => record.account_business_type === value,
                render: (text, record) => {
                    return text === 1 ? 'Sole Trader' : (text === 2 ? 'Company' : (text === 3 ? 'Trust' : '-'))
                }
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'email',
                    render: (text) => text
                })
            },
            {
                title: 'Team',
                dataIndex: 'team_nickname',
                key: 'team_nickname',
            },
            {
                title: 'Risk Rating',
                dataIndex: 'riskRating',
                key: 'riskRating',
                filters: [
                    {text: '-', value: null},
                    {text: 'Low', value: 14},
                    {text: 'Medium', value: 20},
                    {text: 'High', value: 25},
                    {text: 'Declined', value: 26},
                ],
                ...this.props.getColumnSearchProps({
                    dataIndex: 'riskRating',
                    filterInputType: 'SELECT',
                    onFilter: (filterValue, record) => {
                        const { risk_total_score } = record;

                        if (filterValue == null) {
                            return risk_total_score == null;
                        }

                        if (risk_total_score == null) {
                            return false;
                        }

                        if (filterValue === 14) {
                            return risk_total_score < 14;
                        } else if (filterValue === 20) {
                            return risk_total_score >= 14 && risk_total_score <= 20;
                        } else if (filterValue === 25) {
                            return risk_total_score >= 21 && risk_total_score <= 25;
                        } else if (filterValue === 26) {
                            return risk_total_score > 25;
                        }
                        return false;
                    },
                    render: (text, record) => {
                        if (record.risk_total_score != null) {
                            if (record.risk_total_score < 14) {
                                return <Tag color='success' style={{width: 100, textAlign: "center"}}>Low</Tag>
                            } else if (record.risk_total_score >= 14 && record.risk_total_score <= 20) {
                                return <Tag color='blue-inverse' style={{width: 100, textAlign: "center"}}>Medium</Tag>
                            } else if (record.risk_total_score >= 21 && record.risk_total_score <= 25) {
                                return <Tag color='warning' style={{width: 100, textAlign: "center"}}>High</Tag>
                            } else if (record.risk_total_score > 25){
                                return <Tag color='red-inverse' style={{width: 100, textAlign: "center"}}>Declined</Tag>
                            } else {
                                return '-';
                            }
                        } else {
                            return '-';
                        }
                    }
                })
            },
            {
                title: 'Risk Score',
                dataIndex: 'risk_total_score',
                key: 'risk_total_score',
                sorter: (a,b) => {
                    const riskA = a.risk_total_score === null ? null : parseFloat(a.risk_total_score);
                    const riskB = b.risk_total_score === null ? null : parseFloat(b.risk_total_score);

                    if (riskA === null && riskB === null) {
                        return 0;
                    }
                    if (riskA === null) {
                        return 1;
                    }
                    if (riskB === null) {
                        return -1;
                    }

                    return riskA - riskB;
                },
                render: (text, record) => text
            },
            {
              title: 'Risk Review Date',
              dataIndex: 'risk_review_date',
              key: 'risk_review_date',
              ...this.props.getColumnSearchProps({
                  dataIndex: 'risk_review_date',
                  filterInputType: 'DATE',
                  render: (text) => text == null ? '-' : dayjs(text).format('DD/MM/YYYY')
              })
            }
        ];
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            this.getTeamList()
        }
    }

    getTeamList = () => {
        if (this.props.app_state.current_staff) {
            API.get('teams', `/list/staff/${this.props.app_state.current_staff.id}`)
                .then(response => {
                    console.log(response)

                    const teamList = response.sort((a, b) => a.nickname.localeCompare(b.nickname))

                    const teamListSelect = teamList.map((item) => ({
                        text: item.nickname,
                        label: item.nickname,
                        value: item.id
                    }))

                    this.setState({
                        team_list: teamList,
                        teamListSelect: teamListSelect,
                        team_id: teamList[0].id
                    });

                })
                .catch(error => {
                    console.log(error);
                });
        }
    }


    download = event => {
        const currentRecords = this.state.client_list;
        const data_to_download = [];
        for (let index = 0; index < currentRecords.length; index++) {
            let record_to_download = {};
            for (let colIndex = 0; colIndex < this.buildColumns().length; colIndex++) {
                record_to_download[this.buildColumns()[colIndex].title] = currentRecords[index][this.buildColumns()[colIndex].dataIndex];
            }
            data_to_download.push(record_to_download);
        }
        this.setState({dataToDownload: data_to_download}, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLink.link.click();
        });
    };

    finished() {
        this.setState({finished: true});
    }

    onSearch = async (value) => {
        console.log(value)
        if (!value) {
            this.getFullClientDetails()
            return
        }
        await API.get('clients', `/list/staff/${this.props.app_state.current_staff.id}`)
            .then(response => {
                console.log(response)
                const filteredList = response.filter((item) => {
                    if (item.nickname && item.nickname.toLowerCase().includes(value.toLowerCase())) {
                        return true
                    }
                })
                this.setState({client_list: filteredList})
            })
            .catch(error => {
                console.log(error);
            });
    }

    onChange = (value) => {
        console.log(`selected ${value}`);
        if (!value) {
            this.getFullClientDetails()
        }
        this.getClientDetails(value)
    };

    onSelect = (value) => {
        console.log('search:', value);
    };

    getClientDetails = async (team_id) => {
        await API.get('clients', `/getByTeam/id/${team_id}`)
            .then(response => {
                console.log(response)
                const clientList = response.sort((a, b) => moment(a.record_created_datetime).isBefore(b.record_created_datetime) ? -1 : 1)
                this.setState({client_list: clientList});
            })
            .catch(error => {
                console.log(error);
            });
        // this.buildTableData()
    }

    getFullClientDetails = async () => {
        await API.get('clients', `/list/staff/${this.props.app_state.current_staff.id}`)
            .then(response => {
                console.log(response)
                this.setState({client_list: response});
            })
            .catch(error => {
                console.log(error);
            });
    }

    buildTableData() {
        var client_list_length = this.state.client_list.length;
        let clientList = []
        if (client_list_length > 0) {
            clientList = this.state.client_list

            clientList = clientList.filter((prop, key) => {
                return (prop.email.charAt(0) !== '_')
            })

            // filter by startDate and endDate
            clientList = clientList.filter((prop, key) => {
                const startDate = this.state.startDate
                const endDate = this.state.endDate
                return (moment(prop.record_created_datetime).isBetween(startDate, endDate, null, '[]'))
            })

            clientList = clientList.map((prop, key) => {
                return {
                    id: prop.id,
                    aws_cognito_id: prop.aws_cognito_id,
                    nickname: prop.nickname,
                    business_company_name: prop.business_company_name,
                    first_name: prop.first_name,
                    last_name: prop.last_name,
                    email: prop.email,
                    telephone_mobile: prop.telephone_mobile,
                    residential_street_suburb: prop.residential_street_suburb,
                    residential_street_country: prop.residential_street_country,
                    entity: prop.entity_nickname,
                    team: prop.team_nickname,
                    riskScore: prop.riskScore,
                    riskTotalScore: prop.risk_total_score,
                    riskComments: prop.riskComments,
                    account_type: prop.account_type === null ? 1 : prop.account_type,
                    account_business_type: prop.account_business_type,
                    overallRisk: (prop.overallRisk === 1 ? 'LOW' : (prop.overallRisk === 2 ? 'MEDIUM' : (prop.overallRisk === 3 ? 'HIGH' : ''))),
                    record_created_datetime: moment(prop.record_created_datetime).format("DD/MM/YYYY"),
                    actions: (
                        <div className='actions-right'>
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    this.props.history.push(`/clients/edit/${prop.id}`);
                                }}
                                color='warning'
                                className='edit'
                            >
                                <Dvr/>
                            </Button>{' '}

                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    var data = this.state.client_list;
                                    data.find((o, i) => {
                                        if (o.id === prop.id) {
                                            this.setState({
                                                dialog_delete_confirm: true,
                                                dialog_delete_item: prop.id,
                                                dialog_delete_item_name: `${prop.first_name} ${prop.last_name}`
                                            });

                                            return true;
                                        }
                                        return false;
                                    });
                                    this.setState({data: data});
                                }}
                                color='danger'
                                className='remove'
                            >
                                <Close/>
                            </Button>{' '}
                        </div>
                    )
                };
            });
        }
        this.setState({table_data: clientList});
    }

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Select
                    showSearch
                    placeholder="Select a team"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    onSearch={this.onSelect}
                    allowClear
                    filterOption={(input, option) => {
                        if (option && option.label) {
                            return option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    }}
                    options={this.state.teamListSelect}
                    style={{width: 300, marginBottom: 16, marginRight: 36}}
                />

                <Input.Search
                    placeholder="Search Client Name" enterButton style={{width: 300, marginBottom: 16, marginRight: 36}}
                    allowClear
                    onSearch={this.onSearch}
                />

                <div style={{float: 'right'}}>
                    <Button round color={'primary'} onClick={this.download}>
                        <i className='material-icons'>cloud_download</i> CSV
                    </Button>
                </div>


                <Table
                    // data={this.state.entity_list}
                    dataSource={this.state.client_list}
                    filterable
                    columns={this.buildColumns()}
                    defaultSorted={[
                        {
                            id: 'record_created_datetime',
                            desc: true
                        },
                    ]}
                    // defaultPageSize={5}
                    pagination={{
                        pageSizeOptions: ['5', '10', '20'],
                        defaultPageSize: 5,
                        showSizeChanger: true
                    }}
                    // showPaginationTop
                    showPaginationBottom
                    // className='-highlight'
                />

                <CSVLink data={this.state.dataToDownload} filename="data.csv" className={'hidden'} ref={r => (this.csvLink = r)} target={'_blank'}/>


                {/* <ReactTable
          ref={r => (this.reactTable = r)}
          // data={this.buildTableData()}
          data={this.state.table_data}
          filterable
          columns={columns}
          defaultSorted={[
            {
              id: 'record_created_datetime',
              desc: false
            },
            {
              id: 'nickname',
              desc: false
            }
          ]}
          defaultPageSize={20}
          // showPaginationTop
          showPaginationBottom
          className='-highlight'
        /> */}
                <CSVLink
                    data={this.state.client_list}
                    filename='data.csv'
                    className='hidden'
                    // ref={r => (this.csvLink = r)}
                    target='_blank'
                />
                {/* <Dialog
          open={this.state.dialog_delete_confirm}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure that you wish to <strong>delete</strong> staff member record{' '}
              {this.state.dialog_delete_item_name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handle_delete_dialog_cancel} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => this.handle_delete_dialog_click(this.state.dialog_delete_item)}
              color='danger'
              autoFocus
            >
              Delete Record
            </Button>
          </DialogActions>
        </Dialog> */}
            </React.Fragment>
        );
    }
}

ReportsClientRisk = withRouter(withStyles(styles)(ReportsClientRisk));

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        clients: state.clients
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchClientList: () => {
            dispatch(fetchClientList());
        }
        // setClientSelectId: (id) => {
        // dispatch(setClientSelectId(id))
        // }
    };
};

const ReportsClientRiskContainer = connect(mapStateToProps, mapDispatchToProps)(ListTable(ReportsClientRisk));

export default ReportsClientRiskContainer;
