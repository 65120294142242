import React, {useEffect, useState} from 'react';
import {AiOutlineSearch} from 'react-icons/ai';
import {API, Storage} from 'aws-amplify';
import {Box} from '@material-ui/core';
import Button from '../../CustomButtons/Button';
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import Email from '@material-ui/icons/Email';
import NewClientContactsDialog from '../../../features/Clients/NewClientContactsDialog';
import ListTable from "../../ListTable/ListTable";
import {Card, Col, Descriptions, Image, Modal, Row, Table, Typography} from "antd";
import {AppUtil} from "../../../AppUtil";
import {CloseOutlined} from "@ant-design/icons";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export const Contacts = ListTable(({client_detail_id, getColumnSearchProps, history, isBeneficialOwner }) => {
    const [loading, setLoading] = useState(false);
    const [clientContactsList, setClientContactsList] = useState([]);
    const [newClientContactsDialogOpen, setNewClientContactsDialogOpen] = useState(false);
    const [expandedRow, setExpandedRow] = useState({});
    const [previewImage, setPreviewImage] = useState(null);
    const breakPoint = useBreakpoint();
    const openNewClientContactsDialog = () => {
        setNewClientContactsDialogOpen(true);
    };

    const fetchClientContactsListByClientId = clientId => {
        if (!clientId) {
            return;
        }
        if (!loading) {
            setLoading(true);
        }
        const request = !isBeneficialOwner
            ? API.get('clientcontacts', `/list-by-clientid/${clientId}`)
            : API.post('clientcontacts', `/list-by-clientid/${clientId}`, {body: {isBeneficialOwner}});
            request.then(response => setClientContactsList(response))
            .catch(error => {console.log(error)})
            .finally(() => setLoading(false));
    };

    const getFilter = (filter, onChange) => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <AiOutlineSearch/>
                    <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''}/>
                </div>
            </React.Fragment>
        );
    };

    const handleClientContactsDelete = clientContactsId => {
        if (!loading) {
            setLoading(true);
        }
        API.put('clientcontacts', `/id/${clientContactsId}`, {body: {deleted: true}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
                // API.put('clientcontacts', `/id/${clientContactsId}`, {body: {deleted: true}})
                //   .then(response => {
                //     console.log(response);
                //   })
                //   .catch(error => {
                //     console.log(error);
                //   });
            })
            .finally(() => {
                fetchClientContactsListByClientId(client_detail_id);
            });
    };

    const handleClientContactsSendEmailToggle = (clientContactsId, currentState) => {
        if (!loading) {
            setLoading(true);
        }
        API.put('clientcontacts', `/id/${clientContactsId}`, {body: {sendEmail: !currentState}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
                // API.put('clientcontacts', `/id/${clientContactsId}`, {body: {deleted: true}})
                //   .then(response => {
                //     console.log(response);
                //   })
                //   .catch(error => {
                //     console.log(error);
                //   });
            })
            .finally(() => {
                fetchClientContactsListByClientId(client_detail_id);
            });
    };

    const buildTableData = data => {
        if (!data) {
            return [];
        }

        return data.map(item => ({
            ...item,
            sendEmail: (
                <>
                    {
                        item.main_contact_email !== item.email ?
                            <div>
                                {item.sendEmail ? (<span>YES</span>) : (<span>NO</span>)}
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => handleClientContactsSendEmailToggle(item.id, item.sendEmail)}
                                    color='info'
                                >
                                    <Email/>
                                </Button>
                            </div> : <Typography.Text strong>Main Contact</Typography.Text>
                    }
                </>
            ),
            actions: (
                // <div className='actions-right'>
                <div>
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => history.push(`/clients/contacts/view/${item.id}?tab=5`)}
                        color='warning'
                    >
                        <Dvr/>
                    </Button>
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => handleClientContactsDelete(item.id)}
                        color='danger'
                        className='remove'
                    >
                        <Close/>
                    </Button>
                </div>
            )
        }));
    };

    const onRowExpand = (event, record) => {
        if (event === true) {
            if (
                expandedRow[`row_${record.id}`] == null ||
                expandedRow[`row_${record.id}`].status === 'ERROR'
            ) {
                fetchIdentificationDetails(record).catch(err => {
                    console.log(err);
                    setExpandedRow(prev => ({
                        ...prev,
                        [`row_${record.id}`]: {status: 'ERROR'}
                    }));
                });
            }
        }
    }

    const fetchIdentificationDetails = async record => {
        const identifier = `row_${record.id}`;
        setExpandedRow(prev => ({...prev, [identifier]: {status: 'LOADING'}}));
        const [response] = await API.post("commons", "/fetch", {
            body: {
                context: 'identification_records',
                fields: ['*'],
                condition: {client_contact_id: record.id}
            }
        });
        if (response != null && response.attachments != null) {
            const json = JSON.parse(response.attachments);
            if (json != null && json.ids && json.ids.length > 0) {
                const response = await API.post("commons", "/fetch", {
                    body: {
                        context: 'documents',
                        fields: ['*'],
                        condition: {id: json.ids}
                    }
                });
                setExpandedRow(prev => ({
                    ...prev,
                    [identifier]: {status: 'LOADED', documents: response}
                }))
            }
        } else {
            setExpandedRow(prev => ({
                ...prev,
                [identifier]: {status: 'LOADED', documents: []}
            }))
        }

    };

    const onViewImage = async (item) => {
        const url = await Storage.get(item.file_ref, {expiry: 30});
        const data = await AppUtil.getFileInfo(url);
        if (data.type === 'application/pdf') {
            if (breakPoint.lg || breakPoint.xl) {
                Modal.confirm({
                    width: '90%',
                    wrapClassName: 'pdf-preview-modal',
                    closeIcon: <CloseOutlined/>,
                    icon: null,
                    style: {height: '90%', padding: 0, top: 10},
                    styles: {body: {padding: 0}, content: {padding: 0, margin: 0}},
                    maskClosable: true,
                    footer: null,
                    content: <iframe width={'100%'}
                                     style={{height: '90vh'}}
                                     src={url}/>
                });
            } else {
                window.open(url, '_blank');
            }
        } else {
            setPreviewImage(url);
        }
    }

    useEffect(() => {
        fetchClientContactsListByClientId(client_detail_id);
        // eslint-disable-next-line
    }, [client_detail_id]);

    return (
        <React.Fragment>
            <Box style={{padding: '0 16px'}}>
                <Box style={{textAlign: 'right', marginBottom: '8px'}}>
                    <Button
                        round color='info'
                        onClick={() => fetchClientContactsListByClientId(client_detail_id)}
                    >Refresh
                    </Button>

                    <Button round color='info' onClick={openNewClientContactsDialog}>
                        Create New Contact
                    </Button>
                </Box>
                <p>
                    {isBeneficialOwner ? "The table below lists beneficial owners of this client" : "The table below lists all contacts (including owners) of this client"}
                </p>
                <Table
                    loading={loading}
                    dataSource={buildTableData(clientContactsList)}
                    rowKey={row => row.id}
                    columns={[
                        {
                            title: 'Contact ID',
                            dataIndex: 'id',
                            ...getColumnSearchProps({
                                label: 'Contact ID',
                                dataIndex: 'id',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'First Name',
                            dataIndex: 'firstName',
                            ...getColumnSearchProps({
                                label: 'First Name',
                                dataIndex: 'firstName',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Last Name',
                            dataIndex: 'lastName',
                            ...getColumnSearchProps({
                                label: 'Last Name',
                                dataIndex: 'lastName',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Position',
                            dataIndex: 'position',
                            ...getColumnSearchProps({
                                label: 'Position',
                                dataIndex: 'position',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Beneficial Owner',
                            dataIndex: 'is_ben_owner',
                            ...getColumnSearchProps({
                                label: 'Beneficial Owner',
                                dataIndex: 'is_ben_owner',
                                render: (text, record) => Number(text) === 1 ? 'Yes' : 'No'
                            })
                        },
                        {
                            title: 'Email',
                            dataIndex: 'email',
                            ...getColumnSearchProps({
                                label: 'Email',
                                dataIndex: 'email',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Send Emails',
                            dataIndex: 'sendEmail',
                            ...getColumnSearchProps({
                                label: 'Send Emails',
                                dataIndex: 'sendEmail',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Login',
                            dataIndex: 'aws_cognito_id',
                            ...getColumnSearchProps({
                                label: 'Login',
                                dataIndex: 'aws_cognito_id',
                                render: (text, record) => text != null ? 'Y' : 'N'
                            })
                        },
                        {
                            title: 'Actions',
                            dataIndex: 'actions',
                            sortable: false,
                            filterable: false,
                            render: (text, record) => text
                        }
                    ]}
                    expandable={{
                        expandedRowRender: (row) => {
                            const data = expandedRow[`row_${row.id}`];
                            return <Row gutter={[16,16]}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    {
                                        <Card size={'small'}>
                                            <Descriptions size={'small'} column={1} bordered={true} items={[
                                                {label: 'Name', children: <>{row.firstName} {row.lastName}</>},
                                                {label: 'Email', children: <>{row.email}</>},
                                                {label: 'Position', children: <>{row.position}</>},
                                                {label: 'Telephone', children: <>{row.telephone}</>},
                                                {label: 'Ownership', children: <>{row.ownership}</>},
                                            ]}></Descriptions>
                                        </Card>
                                    }
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    <Card size={'small'}>
                                        <h5>Documents</h5>
                                        <ul>
                                            {data.documents && data.documents.length > 0 ? data.documents.map(item => {
                                                return <li><Typography.Link onClick={() => onViewImage(item)}>
                                                    Image ID - {item.id}
                                                </Typography.Link></li>
                                            }) : <>No Documents</>}
                                        </ul>
                                    </Card>
                                </Col>
                            </Row>
                        },
                        onExpand: (event, record) => {
                            onRowExpand(event, record);
                        },
                    }}
                />
            </Box>
            <NewClientContactsDialog
                defaultClientId={client_detail_id}
                open={newClientContactsDialogOpen}
                handleClose={() => {
                    setNewClientContactsDialogOpen(false)
                    fetchClientContactsListByClientId(client_detail_id)
                }}
            />
            {previewImage && <Image wrapperStyle={{display: 'none'}} preview={{
                visible: previewImage,
                onVisibleChange: (visible) => setPreviewImage(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(null),
            }} src={previewImage}/>}
        </React.Fragment>
    );
});
