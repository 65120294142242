import React, {Fragment, useEffect, useRef, useState} from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import {AiOutlineSearch} from 'react-icons/ai';
import moment from 'moment';
import Dvr from '@material-ui/icons/Dvr';
import {Box} from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import {API} from 'aws-amplify';
import AddDocumentDialog from './AddDocumentDialog';
import {Col, DatePicker, Divider, Form, Input, message, Modal, Row, Select, Select as AntSelect, Table} from "antd";
import ListTable from "../ListTable/ListTable";
import FileUploadNew from "../FileUpload/FileUploadNew";
import {useForm} from "antd/es/form/Form";
import dayjs from "dayjs";
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";
import {Button as AntButton} from 'antd';
import FileUploader from "../Uploaders/FileUploader";

const ClientDocumentList = ({client_id, history, getColumnSearchProps, documentCategoryId }) => {

  const [documentForm] = useForm();
  const [loading, setLoading] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [documentCategories, setDocumentCategories] = useState([]);
  const [uploads, setUploads] = useState([]);
  const uploadRef = useRef();

  const openAddDocumentPopup = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  const onDeleteBtnClick = (documentRowId) => {
      Modal.confirm({
          title: `Delete document #${documentRowId}`,
          content: `Are you sure you want to delete your document ?`,
          onOk: () => {
              const notify = message.loading("Deleting document. Please wait..", 0)
              API.get("client-documents", `/delete/client_document/${documentRowId}`, {}).then(res => {
                  message.success("Document has been deleted");
                  fetchDocumentList(client_id);
              }).catch(err => {
                  console.log(err);
                  message.error(`Unable to delete the document #${documentRowId}. Please try again..`);
              }).finally(() => notify());
          }
      })
  }

  const viewDocument = async (item) => {
      const loading = message.loading("Loading transfer documents. Please wait..", 0);

      let documentIds = item.attachments != null ? (JSON.parse(item.attachments).ids || []) : [];

      if (documentIds.length > 0) {
          const documents = await API.post("commons", "/fetch", {
              body: {
                  context: 'documents',
                  fields: ['*'],
                  condition: {id: documentIds}
              }
          });

          const attachments = documents.map(item => item.file_ref);

          Modal.confirm({
              title: 'Transfer Documents',
              width: 600,
              content: <>
                  <Divider />
                  <Row gutter={[16, 16]}>
                      {attachments.map(i => <>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                              <FileUploader previewOnly={true}
                                            thumbType={'picture-card'}
                                            uploaderType={'PDF'}
                                            fetchFileList={() => {
                                                const file = {
                                                    name: i,
                                                    uid: i,
                                                    status: 'done'
                                                }
                                                return Promise.resolve([file]);
                                            }}></FileUploader>
                          </Col>
                      </>)}
                  </Row>
              </>,
              cancelButtonProps: {hidden: true},
              maskClosable: true
          })
      }

      loading();
  }

  const buildTableData = data => {
    if (!data) {
      return [];
    }

    return data.map(item => ({
      ...item,
      recordCreated: item.recordCreated != null ? moment(item.recordCreated).format('DD/MM/YYYY') : '-',
      actions: (
        <>
            <AntButton icon={<SearchOutlined />} type={'link'} onClick={() => viewDocument(item)}></AntButton>
            <Button
                justIcon
                round
                simple
                onClick={() => {
                    history.push(`/clients/documents/view/${item.id}`);
                }}
                color='warning'
                className='edit'
            >
                <Dvr />
            </Button>
            <AntButton type={'link'} danger={true} icon={<DeleteOutlined />} onClick={() => onDeleteBtnClick(item.id)}></AntButton>
        </>
      )
    }));
  };

  const fetchDocumentList = client_id => {
    setLoading(true);

    const apiUrl = documentCategoryId == null ? `/list-by-clientid/${client_id}` : `/list-by-clientid/${client_id}/${documentCategoryId}`;

    API.get('client-documents', apiUrl)
      .then(response => {
        setDocumentList(response);
      })
      .catch(error => {
        console.log(error);
        API.get('client-documents', apiUrl)
          .then(response => {
            setDocumentList(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

    const onUploadImage = doc => {
        const data = [...uploads];
        data.push({
            uid: doc.file.uid,
            name: doc.file.name,
            status: 'done',
            url: doc.url,
            file_id: doc.file_id
        });
        setUploads(data);
    };

    const onRemoveImage = doc => {
        const items = uploads.filter(item => item.uid !== doc.uid);
        setUploads(items);
        uploadRef.current.restore(items.length > 0 ? items : []);
    };

    const handleSubmit = (values) => {
        if (!client_id) {
            return;
        }
        const s3FileIds = uploads.map(item => item.file_id);
        setLoading(true);
        const requestBody = {
            body: {
                clientId: client_id,
                description: values.description,
                document_category_id:
                values.document_category_id,
                images: s3FileIds,
                expiry: values.expiry,
            }
        };
        API.post('client-documents', '/create/client_document', requestBody)
            .then(response => {
                console.log(response);
                uploadRef.current.restore([]);
                setUploads([])
                setDialogOpen(false);
                fetchDocumentList(client_id);
            })
            .catch(error => {
                API.post('client-documents', '/create/client_document', requestBody)
                    .then(response => {
                        console.log(response);
                        uploadRef.current.restore([]);
                        setUploads([]);
                        setDialogOpen(false);
                        fetchDocumentList(client_id);
                    })
                    .catch(error => console.log(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

  useEffect(() => {
    if (client_id) {
      fetchDocumentList(client_id);
        API.post('commons', '/fetch', {
            body: {
                context: 'clientDocumentsCategories',
                fields: ['*']
            }
        }).then(res => setDocumentCategories(res));
    }
  }, [client_id]);

  return (
    <Fragment>
      <Box style={{textAlign: 'right', marginBottom: '8px'}}>
        <Button round color='info' onClick={openAddDocumentPopup}>
          Add Document
        </Button>
      </Box>
      <Table
        loading={loading}
        dataSource={buildTableData(documentList)}
        filterable
        columns={[
          {
            title: 'Id',
            dataIndex: 'id',
              ...getColumnSearchProps({
                  dataIndex: 'id'
              })
          },
          {
            title: 'Document Id',
            dataIndex: 'documentId',
              ...getColumnSearchProps({
                  dataIndex: 'id',
                  render: (text, record) => {
                      return <React.Fragment>
                          {text == null ? ((record.attachments != null && JSON.parse(record.attachments).ids.length) > 0 ? JSON.parse(record.attachments).ids.join(', ') : ''): text}
                      </React.Fragment>
                  }
              })

          },
          {
            title: 'Description',
            dataIndex: 'description',
          },
           {
                title: 'Category Name',
                dataIndex: 'categoryName'
           },
            {
                title: 'Document Expiry',
                dataIndex: 'expiry',
                render: (text) => text != null ? moment(text).format('DD/MM/YYYY') : '-'
            },
          {
            title: 'Created',
            dataIndex: 'recordCreated',
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
          }
        ]}
      />
        <Modal open={dialogOpen} title={'Document Upload'} onOk={() => documentForm.submit()} onCancel={() => {
            documentForm.resetFields();
            uploadRef.current.restore([]);
            setDialogOpen(false);
        }}>
            <Form form={documentForm} name="documnet-upload-form" layout={'vertical'} onFinish={handleSubmit}>
                <Form.Item wrapperCol={{ span: 15 }} label={'Select Category'} name={'document_category_id'}>
                    <Select placeholder={'-- select category --'} options={documentCategories.map(item => ({...item, label: item.categoryName, value: item.id}))}></Select>
                </Form.Item>
                <Form.Item label={'Description'} name={'description'}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label={'Document Expiry'} name={'expiry'} normalize={(dayjsValue) =>
                    dayjsValue && dayjsValue.startOf("day").format('YYYY-MM-DD')
                }
                           getValueProps={(value) =>
                               value && { value: dayjs(value, 'YYYY-MM-DD').startOf("day") }
                           }>
                    <DatePicker format={'DD/MM/YYYY'} />
                </Form.Item>
                <FileUploadNew listType={'picture'} ref={uploadRef} multiUpload={true} onRemoveImage={onRemoveImage} onHandleResult={onUploadImage}></FileUploadNew>
            </Form>
        </Modal>
      {/*<AddDocumentDialog open={dialogOpen} handleClose={handleDialogClose} clientId={client_id} />*/}
    </Fragment>
  );
};

export default ListTable(ClientDocumentList);
