import React, {useEffect, useState} from 'react';
import {useForm} from "antd/es/form/Form";
import {Card, Col, DatePicker, Flex, Form, Input, message, Row, Select, Switch} from "antd";
import {
  loadIdentificationRecordDetailsRequest,
  loadIdentificationRecordDetailsSuccess
} from "../../redux/actions/identification";
import {connect} from "react-redux";
import {AppUrlConstants} from "../../AppAPI";
import TypographyCountry from "../CurrencySelect/TypographyCountry";
import dayjs from "dayjs";
import {IdentificationAPI} from "./IdentificationAPI";
import {API} from "aws-amplify";
import MButton from '../CustomButtons/Button';
import FileUploader from "../Uploaders/FileUploader";
import {UserOutlined} from "@ant-design/icons";
export const IdentificationEdit = ({identification_record_id}) => {

    const [identificationForm] = useForm();

    const [componentState, setComponentState] = useState({
        editMode: false,
        countryList: [],
        idStatus: [],
        categories: [],
        attachments: [],
        clientContacts: []
    });


    const handleSubmit = (values) => {
      console.log(values);
      const loading = message.loading("Saving identification. Please wait..", 0);
      API.put('identification', `/update/id/${identification_record_id}`, {
        body: values
      }).then(res => {
          message.success("Identification Saved");
      }).catch(err => {
        console.log(err);
      }).finally(() => loading());
    }

    const init = () => {
        const loading = message.loading("Loading identification details.. Please wait..", 0);
        Promise.all([
            AppUrlConstants.fetchCountries(),
            IdentificationAPI.fetchIdentificationStatus(),
            IdentificationAPI.fetchIdentificationRecordById(identification_record_id),
            API.post("commons", "/fetch", { body: { context: 'admin_identification_categories', fields: ['*'] } }),

        ])
            .then(([countries, idStatus, identification, categories]) => {

                API.post("commons", "/fetch", {
                    body: {
                        context: 'client_contacts',
                        condition: {
                            clientId: identification.client_id,
                            deleted: false
                        },
                        fields: ['*']
                    }
                })
                    .then(client_contacts => {
                        setComponentState(prev => ({
                            ...prev,
                            countryList: countries,
                            idStatus: idStatus,
                            attachments: identification.attachments,
                            categories,
                            clientContacts: client_contacts
                        }));
                    }).catch(err => {
                    console.log(err);
                    message.error("Unable to fetch client contacts. Please try again..");
                })

                identificationForm.setFieldsValue({
                  ...identification,
                  record_created_by: `${identification.record_created_staff_last_name}, ${identification.record_created_staff_first_name}`,
                });
            })
            .catch(err => {
                console.log(err);
            }).finally(() => loading());
    }

    useEffect(() => {
        init();
    }, []);

    return <>
        <Flex justify={'end'} style={{ marginBottom: 10 }}>
            <Form.Item label={'Edit Mode'} labelAlign={'left'}>
              <Switch defaultChecked={componentState.editMode} onClick={(e) => setComponentState(prev => ({...prev, editMode: e }))}></Switch>
            </Form.Item>
        </Flex>
        <Form disabled={!componentState.editMode} layout={'vertical'} form={identificationForm} onFinish={handleSubmit}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card title={<h5 style={{ fontWeight: 'bold' }}>Identification Document Details</h5>}>
                        <Form.Item hidden
                                   name={'id'}
                                   rules={[{required: true, message: 'Field is required'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={'Document Nickname'}
                                   name={'nickname'}
                                   rules={[{required: true, message: 'Field is required'}]}>
                            <Input/>
                        </Form.Item>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Form.Item label={'Client'} name={'client'}
                                           rules={[{required: true, message: 'Field is required'}]}>
                                    <Input disabled />
                                </Form.Item>
                              <Form.Item name={'client_id'} hidden
                                         rules={[{required: true, message: 'Field is required'}]}>
                                <Input disabled />
                              </Form.Item>
                                <Form.Item label={'Document Number'}
                                           name={'document_number'}
                                           rules={[{required: true, message: 'Field is required'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'Document Issue State'}
                                           name={'document_issue_state'}
                                           rules={[{required: true, message: 'Field is required'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'Document Issue Country'} name={'document_issue_country'}>
                                    <Select showSearch={true} allowClear={true} optionFilterProp={'alt'}
                                            options={componentState.countryList.map(i => ({
                                                ...i,
                                                value: i.id,
                                                alt: i.full_name + i.iso_alpha_3,
                                                label: <TypographyCountry
                                                    iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>
                                            }))}></Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Form.Item label={'Client Contact'} name={'client_contact_id'} rules={[{required: false, message: 'Field is required'}]}>
                                    <Select placeholder={'-- ASSIGNED TO CLIENT --'} allowClear={true}  menuItemSelectedIcon={<UserOutlined />} showSearch={true} options={componentState.clientContacts.map(i => ({
                                        ...i,
                                        label: `[${i.id}] ${i.firstName} ${i.lastName}`,
                                        value: i.id
                                    }))}></Select>
                                </Form.Item>
                                <Form.Item label={'Document Category'} name={'document_category_id'} rules={[{required: true, message: 'Field is required'}]}>
                                    <Select allowClear={true} showSearch={true} options={componentState.categories.map(i => ({
                                      ...i,
                                      label: i.nickname,
                                      value: i.id
                                    }))}></Select>
                                </Form.Item>
                                <Form.Item label={'Document Expiry'}
                                           name={'document_expiry'}
                                           normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                                           rules={[{required: true, message: 'Document expiry is required'}]}
                                           getValueProps={(value) => value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}}>
                                    <DatePicker format={'DD/MM/YYYY'} style={{width: '100%'}} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label={'Document Detail'} name={'document_detail'}>
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Form.Item label={'Record Created'}
                                           name={'record_created_datetime'}
                                           normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                                           rules={[{required: true, message: 'Record Created is required'}]}
                                           getValueProps={(value) => value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}}>
                                    <DatePicker format={'DD/MM/YYYY'} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Form.Item hidden name={'record_created_staff_id'}>
                                  <Input style={{width: '100%'}} />
                                </Form.Item>
                                <Form.Item label={'Record Created By'} name={'record_created_by'}>
                                    <Input style={{width: '100%'}} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card title={<h5 style={{ fontWeight: 'bold' }}>Status and Notes</h5>} style={{ marginBottom: 10 }}>
                        <Form.Item label={'Status'}
                                   name={'id_status'}
                                   rules={[{required: true, message: 'Field is required'}]}>
                            <Select allowClear={true}
                                    showSearch={true}
                                    options={componentState.idStatus.map(i => ({
                                        ...i, label: i.nickname, value: i.id
                                    }))}></Select>
                        </Form.Item>
                        <Form.Item label={'Staff Notes'} name={'id_notes_staff'}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={'Client Notes'} name={'id_notes_client'}>
                            <Input/>
                        </Form.Item>
                    </Card>
                  <Card title={'Attached Documents'} size={'small'}>
                    <Form.Item hidden name={'file_id'}>
                      <Input/>
                    </Form.Item>
                    <Form.Item hidden name={'file_id_back'}>
                      <Input/>
                    </Form.Item>
                    {componentState.attachments && componentState.attachments.length > 0 ? (
                        <Row gutter={[16, 16]}>
                          {
                            componentState.attachments.map(item => {
                              return <Col span={8}>
                                <FileUploader uploaderType={'PDF'}
                                              thumbType={'picture-card'}
                                              onRemove={(file) => Promise.resolve(file)}
                                              fetchFileList={ async () => {
                                                const file = {
                                                  name: item,
                                                  status: 'done',
                                                  uid: item,
                                                  type: 'application/pdf'
                                                };
                                                return Promise.resolve([file])
                                              }}></FileUploader>
                              </Col>
                            })
                          }
                        </Row>
                    ) : <span><strong style={{textAlign: 'center'}}>No Other Images Found</strong></span>}
                  </Card>
                </Col>
            </Row>
            <MButton
                onClick={() => identificationForm.submit() }
                style={{ float: 'right' }}
                color='primary'
                disabled={!componentState.editMode}>Update Identification</MButton>
        </Form>
    </>
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state, identification: state.identification
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadIdentificationRecordDetailsRequest: () => {
            dispatch(loadIdentificationRecordDetailsRequest());
        }, loadIdentificationRecordDetailsSuccess: () => {
            dispatch(loadIdentificationRecordDetailsSuccess());
        }
    };
};

const IdentificationEditContainer = connect(mapStateToProps, mapDispatchToProps)(IdentificationEdit);

export default IdentificationEditContainer;