import React, {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import {Button, Flex, message, Table} from "antd";
import ListTable from "../../ListTable/ListTable";
import {connect} from "react-redux";
import dayjs from "dayjs";
import {FormattedNumber} from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";
import {ReloadOutlined} from "@ant-design/icons";


const styles = {
    currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem',
            width: 30,
            height: 16
        }
    },
    currencyLabel: {
        textAlign: 'right',
        '& > p': {
            marginBottom: -5,
            fontSize: '0.6rem'
        }
    },
}
const CurrencyElement = ({classes, currency, value}) => (
    <div className={classes.currency}>
        <div className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`} />
        <div className={classes.currencyLabel}>
            <p>{currency}</p>
            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={value} />
        </div>
    </div>
);

const Revenue = (props) => {
    const [componentState, setComponentState] = useState({
        transfersList: []
    });

    const buildColumns = () => {
            return [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    sorter: (a, b) => a.id - b.id,
                    defaultSortOrder: 'descend',
                    ...props.getColumnSearchProps({
                        dataIndex: 'id',
                        render: (text, record) => {
                            return text;
                        }
                    })
                },
                {
                    title: 'Transaction Date',
                    dataIndex: 'transaction_date',
                    ...props.getColumnSearchProps({
                        dataIndex: 'transaction_date',
                        filterInputType: 'DATE',
                        render: (text, record) => {
                            return text == null ? '-' : dayjs(text).format('DD/MM/YYYY');
                        }
                    })
                },
                {
                    title: 'Settlement Date',
                    dataIndex: 'settlement_date',
                    ...props.getColumnSearchProps({
                        dataIndex: 'settlement_date',
                        filterInputType: 'DATE',
                        render: (text, record) => {
                            return text == null ? '-' : dayjs(text).format('DD/MM/YYYY');
                        }
                    })
                },
                {
                    title: 'Beneficiary',
                    dataIndex: 'ben_legal_name',
                    ...props.getColumnSearchProps({
                        dataIndex: 'ben_legal_name',
                        render: (text, record) => {
                            return text;
                        }
                    })
                },
                {
                    title: 'From Amount',
                    dataIndex: 'from_iso_alpha_3',
                    ...props.getColumnSearchProps({
                        dataIndex: 'from_iso_alpha_3',
                        render: (text, record) => {
                            return <CurrencyElement
                                currency={record.from_iso_alpha_3}
                                value={record.amount_from}
                                classes={props.classes}
                            />
                        }
                    })
                },
                {
                    title: 'To Amount',
                    dataIndex: 'to_iso_alpha_3',
                    ...props.getColumnSearchProps({
                        dataIndex: 'to_iso_alpha_3',
                        render: (text, record) => {
                            return <CurrencyElement
                                currency={record.to_iso_alpha_3}
                                value={record.amount_to}
                                classes={props.classes}
                            />;
                        }
                    })
                },
                {
                    title: 'Client Rate',
                    dataIndex: 'client_rate',
                    ...props.getColumnSearchProps({
                        dataIndex: 'client_rate',
                        render: (text, record) => {
                            return text == null ? '-' : text.toFixed(4);
                        }
                    })
                },
                {
                    title: 'Bank Rate',
                    dataIndex: 'bank_rate',
                    ...props.getColumnSearchProps({
                        dataIndex: 'bank_rate',
                        render: (text, record) => {
                            return text == null ? '-' : text.toFixed(4);
                        }
                    })
                },
                {
                    title: 'Profit',
                    dataIndex: 'bank_rate',
                    ...props.getColumnSearchProps({
                        dataIndex: 'bank_rate',
                        render: (text, record) => {

                            if (!record.bank_rate) return 0
                            if (record.client_rate === 1) return 0

                            let transferProfit = 0;
                            const amount_from = Number.parseFloat(record.amount_from);
                            const amount_to = Number.parseFloat(record.amount_to);
                            const client_rate = Number.parseFloat(record.client_rate);
                            const bank_rate = Number.parseFloat(record.bank_rate)

                            if (record.from_iso_alpha_3 === 'AUD') {
                                transferProfit = ((amount_from * client_rate) - (amount_from * bank_rate)) / bank_rate;
                            } else {
                                const inverseClientRate = 1 / client_rate;
                                const inverseBankRate = 1 / bank_rate;
                                transferProfit = ((amount_to * inverseClientRate) - (amount_to * inverseBankRate)) / inverseBankRate;
                            }
            
                            transferProfit = transferProfit.toFixed(2);
                            transferProfit = Math.abs(transferProfit);
                            if (record.fee_admin_amount != null) {
                                transferProfit = transferProfit - Number.parseFloat(record.fee_admin_amount);
                            }
            


                            return transferProfit;
                        }
                    })
                },
            ];
    }

    const init = (response) => {
        const loading = message.loading("Please wait...", 0);
        API.get("revenue", `/client/${props.client_id}`, {}).then(response => {
            setComponentState(prev => ({...prev, transfersList: response }));
            message.success("Transfers fetched");
        }).catch(err => {
            console.log(err);
            message.success("Unable to fetch. Please try again..");
        }).finally(() => loading());
    }

    useEffect(() => {
        init();
    }, []);



    return <>
        <Flex justify={'end'} style={{ marginBottom: 10 }}>
            <Button icon={<ReloadOutlined />} type={'primary'} onClick={() => init() }>Refresh List</Button>
        </Flex>
        <Table size="small" dataSource={componentState.transfersList} columns={buildColumns()}></Table>
    </>
}

const mapStateToProps = () => {};
const mapDispatchToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListTable(Revenue)));