import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';

import Person from '@material-ui/icons/Person';
import Dashboard from '@material-ui/icons/Dashboard';
import ExitToApp from '@material-ui/icons/ExitToApp';

import Button from 'components/CustomButtons/Button.jsx';
import {API} from 'aws-amplify';
import headerLinksStyles from './HeaderLinksStyles.jsx';

import LanguageSelectionContainer from '../CustomDropdown/LanguageSelectionContainer';
import {ClientDropdownList} from './ClientDropdownList.jsx';

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // open: false,
      // news_list: []
    };
  }

  // is_empty(x) {
  //   return (
  //     typeof x == 'undefined' ||
  //     x == null ||
  //     x == false || //same as: !x
  //     x.length == 0 ||
  //     x == '' ||
  //     x.replace(/\s/g, '') == '' ||
  //     !/[^\s]/.test(x) ||
  //     /^\s*$/.test(x)
  //   );
  // }

  componentDidMount() {
    // API.get("news-staff", `/news/notification`)
    //   .then(response => {
    //     // console.log("FROM HEADER");
    //     // console.log(response);
    //     this.setState({
    //       news_list: response
    //     });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  // handleClick = () => {
  //   this.setState({open: !this.state.open});
  // };

  // handleClose = (e, a) => {
  //   const news_id = e.target.id;
  //   // this.setState({ open: false });

  //   if (!this.is_empty(news_id)) {
  //     window.location = '/staffnews/show/' + news_id;
  //   }
  // };

  render() {
    const {classes, rtlActive} = this.props;
    // const {open} = this.state;

    // const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {[classes.dropdownItemRTL]: rtlActive});
    const wrapper = classNames({[classes.wrapperRTL]: rtlActive});
    // const managerClasses = classNames({[classes.managerClasses]: true});

    // const listItems = this.state.news_list.map((prop, key) => (
    //   <MenuItem key={key} id={prop.id} onClick={this.handleClose} className={dropdownItem}>
    //     {prop.headline.substring(0, 30)}
    //   </MenuItem>
    // ));

    return (
      <React.Fragment>
        <div style={{textAlign: 'right'}}>
          <strong>
            Admin Portal Version 2.9.0
          </strong>
          <div className={wrapper} id='customDrop' style={{display: 'flex'}}>
            <ClientDropdownList />
            <LanguageSelectionContainer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withRouter(withStyles(headerLinksStyles)(HeaderLinks));
