import {Button, Card, Flex, Form, message, Select, Table, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {ReloadOutlined} from "@ant-design/icons";
import {API} from "aws-amplify";
import dayjs from "dayjs";
import ListTable from "../ListTable/ListTable";

const IdentificationReport = (props) => {

    const [componentState, setComponentState] = useState({
        reportsList: [],
        FETCH_STATUS: 'LOADED',
        teamList: [],
        activeTeamId: 1,
        categoryList: []
    });

    const buildColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: (a,b) => a.id - b.id,
                defaultSortOrder: 'descend',
                ...props.getColumnSearchProps({
                    dataIndex: 'id',
                    render: (text, record) => text
                })
            },
            {
                title: 'Name',
                dataIndex: 'name',
                ...props.getColumnSearchProps({
                    dataIndex: 'id',
                    render: (text, record) => {
                        return <a href={`/clients/edit/${record.client_id}`} target={"_blank"}>{record.name}</a>

                    }
                })
            },
            {
                title: 'Category Name',
                dataIndex: 'categoryName',
                filters: componentState.categoryList.map(item => ({
                    text: item.nickname,
                    value: item.id
                })),
                onFilter: (value, record) => value === record.document_category_id,
                render: (text, record) => text
            },
            {
                title: 'Document Expiry',
                dataIndex: 'document_expiry',
                ...props.getColumnSearchProps({
                    dataIndex: 'document_expiry',
                    filterInputType: 'DATE',
                    render: (text) => text != null ? dayjs(text).format('DD/MM/YYYY') : '-'
                })

            },
            {
                title: 'Transfers (to today)',
                dataIndex: 'transfer_count_last_3_months',
                render: (text, record) => {
                    const zeroToThree = record.transfer_count_last_3_months;
                    const threeToSix = record.transfer_count_3_to_6_months;
                    const SixToNine = record.transfer_count_6_to_9_months;
                    const NineToTwelve = record.transfer_count_9_to_12_months;
                    const twelveToTwentyFour = record.transfer_count_12_to_24_months;
                    return <table>
                        <tr>
                            <td width={"20%"}>
                                3m: {zeroToThree || '--'}
                            </td>
                            <td width={'20%'}>
                                6m: {threeToSix || '--'}
                            </td>
                            <td width={'20%'}>
                                9m: {SixToNine || '--'}
                            </td>
                            <td width={'20%'}>
                                12m: {NineToTwelve || '--'}
                            </td>
                            <td width={'20%'}>
                                24m: {twelveToTwentyFour || '--'}
                            </td>
                        </tr>
                    </table>
                }
            },
            {
                title: 'Total Transfers',
                dataIndex: 'transfer_count',
                sorter: (a,b) => a.transfer_count - b.transfer_count,
                render: (text, record) => text
            },
            {
                title: 'Last Transfer',
                dataIndex: 'latest_settlement_date',
                ...props.getColumnSearchProps({
                    dataIndex: 'latest_settlement_date',
                    filterInputType: 'DATE',
                    render: (text, record) => text != null ? dayjs(text).format('DD/MM/YYYY') : '-'
                })
            },

        ]
    }

    const init = () => {
        const loading = message.loading("Fetching identification reports which are going to be expired", 0);
        setComponentState(prev => ({...prev, FETCH_STATUS: 'LOADING'}));
        API.get("reports", `/identification/expiry/check/${componentState.activeTeamId}`, {}).then(response => {
            setComponentState(prev => ({...prev, reportsList: response, FETCH_STATUS: 'LOADED'}))
            message.success("Loaded");
        }).catch(err => {
            console.log(err);
            message.error("Unable to fetch. Please click on reload to refresh the list");
            setComponentState(prev => ({...prev, FETCH_STATUS: 'ERROR'}));
        }).finally(() => loading());
    }

    const fetchTeams = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'teams',
                fields: ['*'],
                condition: {deleted: false},
            }
        }).then(res => {
            console.log(res);
            setComponentState(prev => ({...prev, teamList: res}));
        }).catch(err => {
            console.log(err);
        })
    }

    const fetchDocumentCategories = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'admin_identification_categories',
                fields: ['*']
            }
        }).then(res => {
            setComponentState(prev => ({...prev, categoryList: res}));
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        init();
        fetchTeams();
        fetchDocumentCategories();
    }, []);
    useEffect(() => {
        init();
    }, [componentState.activeTeamId]);
    return (
        <>
            <Card title={<Flex justify={'space-between'} align={'center'}>
                <h5>Expiring Identification Records</h5>
                <div>
                    <Button type={'primary'} danger={true} onClick={() => init()} icon={<ReloadOutlined />}>Reload Page</Button>
                </div>
            </Flex>}>
                <Form.Item label={'Team'}>
                    <Select defaultValue={componentState.activeTeamId}
                            style={{ width: '200px' }}
                            allowClear={true}
                            placeholder={'-- SELECT TEAM --'}
                            onChange={(d) => setComponentState(prev => ({...prev, activeTeamId: d}))}
                            options={componentState.teamList.map(i => ({
                                ...i, label: i.nickname,
                                value: i.id
                            }))}></Select>
                </Form.Item>
                <Table size={'small'} columns={buildColumns()} dataSource={componentState.reportsList} loading={componentState.FETCH_STATUS === 'LOADING'}></Table>
            </Card>
        </>
    )
}

export default ListTable(IdentificationReport);