import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';
// import matchSorter from 'match-sorter'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Datetime from 'react-datetime';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import TextField from '@material-ui/core/TextField';

import { 
  Card as AntCard,
  Input,
  Table as AntTable
 } from 'antd';

// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";

import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import Button from '../../CustomButtons/Button';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import {API, Storage} from 'aws-amplify';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
// import Badge from "../../Badge/Badge";
// import matchSorter from "match-sorter";
import {FormattedNumber} from 'react-intl';

//core components for #Issue 963
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';

// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from '../../CustomInput/CustomInput';
// import Datetime from "react-datetime";
import CustomReactSelect from '../../Forms/CustomReactSelect/CustomReactSelect';

//component to send email
import TransferDetailPayoutCommunication from './TransferDetailPayoutCommunication';

import {injectIntl, intlShape} from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import {FormControl} from '@material-ui/core';
import {getKeyThenIncreaseKey} from 'antd/lib/message';

import HorizontalStepperNew from '../../Timeline/HorizontalStepperNew';

var moment = require("moment");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const styles = {
  selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  progressBar: {width: 50},
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'},
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TRANSFER_STATUS_COMPLETE_VALUE = {
  1: {value: 10, text: "Draft"},
  2: {value: 20, text: "In Process"},
  3: {value: 30, text: "Acknowledged"},
  4: {value: 40, text: "Processing"},
  5: {value: 50, text: "Awaiting Funds"},
  6: {value: 60, text: "Funds Received"},
  7: {value: 100, text: "Payment Made"},
  8: {value: 100, text: "Completed"},
  9: {value: 0, text: "On Hold"},
  10: {value: 0, text: "Not Proceeding"}
}



class TransferDetailPayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      loaded: false,
      dialog_delete_confirm: false,
      is_loading: true,
      beneficiary_list: [],
      user_beneficiaries_list: [],
      currencies_list_priority: [],
      payouts_list: [],
      country_list: [],
      currencies_list: [],
      purpose_list: [],
      super_admin: this.props.current_staff_super_admin,
      ACTIONaddBeneficiaryModalOpen: false,
      addBeneficiaryModalOpen: false,
      entity_bank: [],
      entityBankAccounts: [],
      currentRecord: {},
      entity_bank_id: null,
      payoutTotal: 0,
      transfer: {},
      formInput: {
        date: '',
        decription: '',
        debit: '',
        credit: '',
        dateStatus: null,
        decriptionStatus: null,
        debitStatus: null,
        creditStatus: null
      }
    };
  }

  componentDidMount() {
    // console.log(this.props);
    this.setState({
      beneficiary_id: this.props.beneficiary_id,
      payout_datetime: this.props.payout_datetime,
      payout_bank_reference: this.props.payout_bank_reference,
      transfer_status: this.props.transfer_status
    });

    API.get('beneficiaries', '/get-all').then(response => {
      this.setState({beneficiary_list: response});
      return response;
    });

    API.get('system', '/entitybanks/get-all')
      .then(response => {
        this.setState({entity_bank: response});
      })
      .catch(error => {
        console.log(error);
      });
    API.get('system', '/entitybanksaccount/get-all')
      .then(response => {
        this.setState({entityBankAccounts: response});
      })
      .catch(error => {
        console.log(error);
      });

    API.get('transfers', '/get_purpose/id/9')
      .then(response => {
        this.setState({purpose_list: response});
      })
      .catch(error => {
        API.get('transfers', '/get_purpose/id/9')
          .then(response => {
            this.setState({purpose_list: response});
          })
          .catch(error => {
            console.log(error);
          });
        console.log(error);
      });
    /*
        API.get("beneficiaries", `/get/by_client_id/${this.props.transfers.new_transfer_data.client_id}`)
          .then(response => {
            // console.log(response);
            this.setState({
              beneficiaries_list: response
            });
          })
          .catch(error => {
            console.log(error);
          });
        */

    //Code block of #799 needs of API
    API.get('system', '/currencies/get-not-deleted')
      .then(response => {
        // console.log(response);
        this.setState({currencies_list_priority: response});
      })
      .then(() => {
        API.get('beneficiaries', `/get/by_client_id/${this.props.client_id}`) //test value 610
          .then(response => {
            // console.log(response);
            this.setState({user_beneficiaries_list: response});
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('countries', '/list_not_deleted')
      .then(response => {
        this.setState({country_list: response});
      })
      .catch(error => {
        console.log(error);
      });

    API.get('currencies', '/list_not_deleted')
      .then(response => {
        // console.log(response);
        this.setState({currencies_list: response});
      })
      .catch(error => {
        console.log(error);
      });
    //End of Code block

    if (this.props.beneficiary_id) {
      API.get('beneficiaries', `/get/id/${this.props.beneficiary_id}`)
        .then(response => {
          console.log(response);
          this.setState({
            loaded: true,
            is_loading: false,
            beneficiary: response
          });
          // this.props.loadTransferDetailsSuccess();
          return response;
        })
        .then(response => {
          API.get('system', `/countries/get/${response.ben_address_country}`)
            .then(response => {
              // console.log(response);
              this.setState({ben_address_country: response[0].full_name});
              // this.props.loadTransferDetailsSuccess();
              return response;
            })
            .catch(error => {
              console.log(error);
            });

          API.get('system', `/countries/get/${response.bank_address_country}`)
            .then(response => {
              // console.log(response);
              this.setState({bank_address_country: response[0].full_name});
              // this.props.loadTransferDetailsSuccess();
              return response;
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (this.props.currency_to_id) {
      API.get('system', `/currencies/get/${this.props.currency_to_id}`)
        .then(response => {
          // console.log(response);
          this.setState({currency_to: response});
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (this.props.currency_from_id) {
      API.get('system', `/currencies/get/${this.props.currency_from_id}`)
        .then(response => {
          // console.log(response);
          this.setState({currency_from: response});
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (this.props.transfer_id) {
      API.get('transfers', `/get-full/id/${this.props.transfer_id}`)
        .then(response => {
          // console.log(response)
          let payoutTotal = 0;
          for (let i = 0; i < response.payouts.length; i++) {
            payoutTotal += parseFloat(response.payouts[i].amount_to);
          }

          let payoutDifference = 0;
          payoutTotal = Number.parseFloat(payoutTotal || 0).toFixed(2)
          // console.log(payoutTotal)
          let transferAmountTo = Number.parseFloat(response.fullList.amount_to || 0)
          // console.log(transferAmountTo)
          payoutDifference = payoutTotal - transferAmountTo
          // console.log(payoutDifference)

          this.setState({
            transfer: response.fullList,
            payouts_list: response.payouts,
            payoutTotal: payoutTotal,
            payoutDifference: payoutDifference
          });

          API.get('system', `/currencies/get/${response.fullList.currency_to_id}`)
            .then(response1 => {
              this.setState({currency_to: response1});
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
          API.get('transfers', `/get-full/id/${this.props.transfer_id}`).then(response => {
            //console.log(response)
            this.setState({
              transfer: response.fullList,
              payouts_list: response.payouts
            });
          });
        });


    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.remitting_bank_id !== this.props.remitting_bank_id) {
      API.get('system', `/entitybanksaccount/get/id/${this.props.remitting_bank_id}`)
        .then(response => {
          // console.log(response);
          this.setState({
            loaded: true,
            is_loading: false,
            entity_bank_account: response[0]
          });
          // this.props.loadTransferDetailsSuccess();
          return response[0];
        })
        .then(response => {
          // console.log(response);
          API.get('system', `/entitybanks/get/id/${response.entity_bank_id}`)
            .then(response => {
              // console.log(response);
              this.setState({
                loaded: true,
                is_loading: false,
                entity_bank: response[0]
              });
              // this.props.loadTransferDetailsSuccess();
              return response;
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (prevProps.currency_from_id !== this.props.currency_from_id) {
      API.get('system', `/currencies/get/${this.props.currency_from_id}`)
        .then(response => {
          // console.log(response);
          this.setState({currency_from: response});
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (prevProps.language_id !== this.props.language_id) {
      API.get('admin', '/languages/get')
        .then(response => {
          // console.log(response);
          // console.log(response[this.props.language_id].nickname);
          // let matched_id = 1;
          let matched_nickname = 'English';
          for (const item in response) {
            if (response[item].id === this.props.language_id) {
              matched_nickname = response[item].nickname;
            }
          }
          this.setState({language_nickname: matched_nickname});
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  addBeneficiaryModelOpen = () => {
    this.setState({addBeneficiaryModalOpen: true});
  };

  addBeneficiaryModelClose = () => {
    this.setState({
      addBeneficiaryModalOpen: false,
      ACTIONaddBeneficiaryModalOpen: false
    });
  };

  actionAddBeneficiaryModelOpen = () => {
    this.setState({
      ACTIONaddBeneficiaryModalOpen: true,
      addBeneficiaryModalOpen: false
    });
  };

  actionAddBeneficiaryModelClose = () => {
    this.setState({ACTIONaddBeneficiaryModalOpen: false});
  };

  getFullDate = s => {
    //console.log(s);
    let showDate = '';
    if (s.getMonth() >= 9) {
      if (s.getDate() > 9) {
        showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-' + s.getDate();
      } else {
        showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-0' + s.getDate();
      }
    } else if (s.getMonth() < 9) {
      if (s.getDate() > 9) {
        showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-' + s.getDate();
      } else {
        showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-0' + s.getDate();
      }
    }
    return showDate;
  };

  async saveTransferDetail(transfer_detail) {
    return API.put('transfers', `/update/id/${this.props.transfer_id}`, {body: transfer_detail});
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  };

  handleCustomReactSelectChange =
    (
      name
      // index,
      // previous_from_currency,
      // previous_to_currency
    ) =>
    value => {
      let option_value;
      let option_label;

      if (value === null) {
        option_value = null;
        option_label = null;
      } else {
        option_value = value.value;
        option_label = value.label;
      }

      if (name === 'account_currency' || name == 'to_currency') {
        const currency_dataset = this.state.currencies_list_priority;
        console.log(currency_dataset)
        for (let i = 0; i < currency_dataset.length; i++) {
          if (currency_dataset[i].id === option_value) {
            //iban check
            if (currency_dataset[i].iban === 1) {
              this.setState({show_iban: true});
            } else {
              this.setState({show_iban: false});
            }
            //bsb check
            if (currency_dataset[i].bsb === 1) {
              this.setState({show_bsb: true});
            } else {
              this.setState({show_bsb: false});
            }
            //sort_code check
            if (currency_dataset[i].sort_code === 1) {
              this.setState({show_sort_code: true});
            } else {
              this.setState({show_sort_code: false});
            }
            //swift_code check
            if (currency_dataset[i].swift_code === 1) {
              this.setState({show_swift_code: true});
            } else {
              this.setState({show_swift_code: false});
            }
            //aba_routing check
            if (currency_dataset[i].aba_routing === 1) {
              this.setState({show_aba: true});
            } else {
              this.setState({show_aba: false});
            }
            //account number check
            if (currency_dataset[i].account_number === 1) {
              this.setState({show_account_number: true});
            } else {
              this.setState({show_account_number: false});
            }
          }
        }
      }

      if (name == 'payoutBankId') {
        this.setState(
          {
            currentRecord: {
              ...this.state.currentRecord,
              [name]: option_value
            }
          },
          () => {
            if (name === 'from_currency' || name === 'to_currency') {
              switch (this.props.transfers_dashboard_multiple.new_transfer_data.amount_lock) {
                case 'from_amount':
                  this.handleAmount('to_amount');
                  break;
                case 'to_amount':
                  this.handleAmount('from_amount');
                  break;
                default:
                  this.handleAmount();
              }
            }
          }
        );
      } else {
        this.setState({[name]: option_value}, () => {
          if (name === 'from_currency' || name === 'to_currency') {
            switch (this.props.transfers_dashboard_multiple.new_transfer_data.amount_lock) {
              case 'from_amount':
                this.handleAmount('to_amount');
                break;
              case 'to_amount':
                this.handleAmount('from_amount');
                break;
              default:
                this.handleAmount();
            }
          }
        });
      }
    };
  // End of test block

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});

    try {
      await this.saveTransferDetail({
        payout_datetime: this.state.payout_datetime,
        beneficiary_id: this.state.beneficiary_id,
        payout_bank_reference: this.state.payout_bank_reference
      });
    } catch (e) {
      alert(e);
      // this.setState({ isLoading: false });
    }
  };

  //A function to save and create Valid Beneficiaries from added
  finishedCreateBene = async () => {
    if (this.isValidateBeneficiary()) {
      const {
        ben_legal_name,
        ben_email_main,
        ben_address_line_1,
        ben_address_line_2,
        ben_address_suburb,
        ben_address_state,
        ben_address_postcode,
        ben_address_country,
        bank_legal_name,
        iban,
        account_currency,
        bsb_code,
        swift_code,
        account_number,
        aba_routing_number,
        sort_code
      } = this.state;

      try {
        await this.createBeneficiaryIntoDatabase({
          client_id: this.props.client_id,
          nickname: ben_legal_name,
          ben_legal_name: ben_legal_name,
          ben_email_main: ben_email_main,
          ben_address_line_1: ben_address_line_1,
          ben_address_line_2: ben_address_line_2,
          ben_address_suburb: ben_address_suburb,
          ben_address_state: ben_address_state,
          ben_address_postcode: ben_address_postcode,
          ben_address_country: ben_address_country,
          bank_legal_name: bank_legal_name,
          iban: iban,
          account_currency: account_currency,
          bsb_code: bsb_code,
          swift_code: swift_code,
          account_number: account_number,
          aba_routing_number: aba_routing_number,
          sort_code: sort_code
        });
        this.setState(
          {
            addBeneficiaryModalOpen: false,
            actionAddBeneficiaryModalOpen: false
          },
          () => {
            console.log(this.state.payouts_list[0]);
            API.get('beneficiaries', `/get/by_client_id/${this.props.client_id}`) //test account 610 #1092
              .then(response => {
                // let resume_beneficiaries_list = response;
                console.log(response);
                if (response.length > 0) {
                  for (let i = response.length - 1; i > -1; i--) {
                    if (response[i].account_currency !== account_currency) {
                      response.splice(i, 1); // Removes the (i)th element of response
                    }
                  }
                }
                this.setState({user_beneficiaries_list: response});
              })
              .catch(error => {
                console.log(error);
              });
          }
        );
      } catch (e) {
        this.setState({isLoading: false});
      }
      // finally {
      //   window.location.reload(); //#867 Safety reload
      // }
    }
  };

  isValidateBeneficiary() {
    if (this.state.ben_legal_name == '' || typeof this.state.ben_legal_name === 'undefined') {
      this.setState({ben_legal_name_state: 'error'});
    } else {
      this.setState({ben_legal_name_state: 'success'});
    }

    if (this.state.account_currency == '' || typeof this.state.account_currency === 'undefined') {
      this.setState({account_currency_state: 'error'});
    } else {
      this.setState({account_currency_state: 'success'});
    }

    if (
      this.state.ben_legal_name == '' ||
      typeof this.state.ben_legal_name === 'undefined' ||
      this.state.account_currency == '' ||
      typeof this.state.account_currency === 'undefined'
    ) {
      return false;
    }
    return true;
  }

  createActionUi = beneficiary_list_select_options => {
    // console.log(this.state.beneficiary_list);
    // console.log(beneficiary_list_select_options);
    //by Tom, will check beneficiary_list_select_options #1092 Continued
    const {classes} = this.props;

    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.ACTIONaddBeneficiaryModalOpen}
          transition={Transition}
          keepMounted
          onClose={() => this.actionAddBeneficiaryModelClose()}
          aria-labelledby='modal-slide-title'
          aria-describedby='modal-slide-description'
        >
          <DialogTitle id='classic-modal-slide-title' disableTypography className={classes.modalHeader}>
            <h4 className={classes.modalTitle}>Add New Beneficiary</h4>
          </DialogTitle>
          <DialogContent id='modal-slide-description' className={classes.modalBody}>
            <h5>Payout details for “No beneficiary. from below and click “SAVE” to add”</h5>
            <GridItem xs={12} sm={12}>
              {/*<h4>Now provide beneficiary details</h4>*/}
            </GridItem>
            <GridItem xs={12} sm={6}>
              {
                //(this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].checked_beneficiary_details !== true) && (
              }
              <CustomReactSelect
                //error={this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id_state === "error"}
                label='Select beneficiary'
                options={this.state.beneficiary_list}
                value={this.state.beneficiary_id}
                //{
                //this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id
                //}
                onChange={this.handleCustomReactSelectChange('beneficiary_id')}
                isClearable={false}
                //isDisabled={this.state.prefill_beneficiary}
              />
            </GridItem>
            <p> </p>
            <Button
              size='sm'
              color='info'
              key='saveBeneficiaryKey'
              onClick={
                //test
                () => {
                  this.setState({
                    addBeneficiaryModalOpen: false,
                    ACTIONaddBeneficiaryModalOpen: false
                  });

                  API.put('payments', `/update/update_from_editor/${this.state.payouts_list[0].id}`, {
                    body: {
                      beneficiary_id: this.state.beneficiary_id //...data
                    }
                  })
                    .then(database_result => {
                      console.log(database_result);
                      //reload the page after getting result
                      window.location.reload();
                    })
                    .catch(error => {
                      console.log(error);
                    });
                }
              }
              disabled={!this.state.beneficiary_id}
            >
              Save
            </Button>{' '}
            <Button
              size='sm'
              color='info'
              key='addBeneficiaryKey'
              onClick={() => this.addBeneficiaryModelOpen()}
              disabled={
                true
                // !!this.state.beneficiary_id ||
                // !this.state.user_beneficiaries_list
              } //disable it before this modal function - create new beneficiaries works
            >
              Create new beneficiary
            </Button>
          </DialogContent>
          <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
            <Button onClick={() => this.actionAddBeneficiaryModelClose()}>Cancel</Button>
            {/* <Button color="primary" onClick={() => this.finishedCreateBene()}>
                    Add Beneficiary
                  </Button>*/}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  createNewBeneficiaryUi = () => {
    //console.log(this.state.country_list)
    const {classes} = this.props;
    const country_list_select_options = this.state.country_list.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span>
            <img
              src={
                item.iso_alpha_2 !== ''
                  ? '' //require(`../../../assets/img/flags/${item.iso_alpha_2}.png`)
                  : ''
              }
            />
          </span>{' '}
          &nbsp; {item.full_name}{' '}
        </React.Fragment>
      )
    }));
    //console.log(this.state.currencies_list_priority)
    //console.log(this.state.currencies_list)
    const select_currency_select_options = this.state.currencies_list_priority.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
          &nbsp;
          {item.iso_alpha_3 + ': ' + item.full_name}
        </React.Fragment>
      )
    }));

    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.addBeneficiaryModalOpen}
          transition={Transition}
          keepMounted
          onClose={() => this.addBeneficiaryModelClose()}
          aria-labelledby='modal-slide-title'
          aria-describedby='modal-slide-description'
        >
          <DialogTitle id='classic-modal-slide-title' disableTypography className={classes.modalHeader}>
            <h4 className={classes.modalTitle}>Add New Beneficiary</h4>
          </DialogTitle>
          <DialogContent id='modal-slide-description' className={classes.modalBody}>
            <GridContainer justify='flex-start'>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label='Currency'
                  options={select_currency_select_options}
                  value={this.state.account_currency}
                  onChange={this.handleCustomReactSelectChange('account_currency')}
                  isClearable={false}
                />
                <CustomReactSelect
                  label='Bank Country'
                  options={country_list_select_options}
                  value={this.state.bank_country}
                  onChange={this.handleCustomReactSelectChange('bank_country')}
                  isClearable={false}
                  error={this.state.bank_country_state === 'error'}
                />
              </GridItem>
            </GridContainer>
            <hr />
            <h6>Beneficiary Details</h6>

            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomInput
                  success={this.state.ben_legal_name_state === 'success'}
                  error={this.state.ben_legal_name_state === 'error'}
                  labelText={<span>Beneficiary (Account) Name</span>}
                  id='ben_legal_name'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: this.state.ben_legal_name,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_legal_name', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.ben_address_line_1_state === 'success'}
                  error={this.state.ben_address_line_1_state === 'error'}
                  labelText={<span>Address Line 1</span>}
                  id='ben_address_line_1'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: this.state.ben_address_line_1,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_address_line_1', 'length', 3)
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label='Country'
                  options={country_list_select_options}
                  value={this.state.ben_address_country}
                  onChange={this.handleCustomReactSelectChange('ben_address_country')}
                  isClearable={false}
                />
                <br />
                <GridContainer justify='flex-start'>
                  <GridItem xs={12} md={this.state.ben_address_country === 9 ? 6 : 8}>
                    <CustomInput
                      success={this.state.ben_address_suburb_state === 'success'}
                      error={this.state.ben_address_suburb_state === 'error'}
                      labelText={<span>Suburb / Region</span>}
                      id='ben_address_suburb'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_suburb,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_suburb', 'length', 3)
                      }}
                    />
                  </GridItem>
                  {this.state.ben_address_country === 9 && (
                    <GridItem xs={12} md={3}>
                      <CustomInput
                        success={this.state.ben_address_state_state === 'success'}
                        error={this.state.ben_address_state_state === 'error'}
                        labelText={<span>State</span>}
                        id='ben_address_state'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.ben_address_state,
                          onChange: event => this.newBeneficiaryChange(event, 'ben_address_state', 'length', 2)
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} md={this.state.ben_address_country === 9 ? 3 : 4}>
                    <CustomInput
                      success={this.state.ben_address_postcode_state === 'success'}
                      error={this.state.ben_address_postcode_state === 'error'}
                      labelText={<span>Postcode</span>}
                      id='ben_address_postcode'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_postcode,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_postcode', 'length', 3)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer justify='flex-start'>
              <GridItem xs={12}>
                <CustomInput
                  success={this.state.ben_email_main_state === 'success'}
                  error={this.state.ben_email_main_state === 'error'}
                  labelText={<span>Beneficiary Email [optional]</span>}
                  helpText='May be used to provide copy of payout confirmation to beneficiary.  Confirmation is required from you on each transfer.'
                  id='ben_email_main'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: this.state.ben_email_main,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_email_main', 'length', 3)
                  }}
                />
              </GridItem>
            </GridContainer>
            <hr />
            <h6>Bank Details</h6>

            <GridContainer justify='flex-start'>
              <GridItem xs={12} md={6}>
                <CustomInput
                  success={this.state.bank_legal_name_state === 'success'}
                  error={this.state.bank_legal_name_state === 'error'}
                  labelText={<span>Bank Name</span>}
                  id='bank_legal_name'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: this.state.bank_legal_name,
                    onChange: event => this.newBeneficiaryChange(event, 'bank_legal_name', 'length', 3)
                  }}
                />
                {this.state.show_iban && (
                  <CustomInput
                    success={this.state.iban === 'success'}
                    error={this.state.iban === 'error'}
                    labelText={<span>IBAN</span>}
                    id='iban'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.iban,
                      onChange: event => this.newBeneficiaryChange(event, 'iban', 'length', 3)
                    }}
                  />
                )}
              </GridItem>
              <GridItem xs={12} md={6}>
                {this.state.show_swift_code && (
                  <CustomInput
                    success={this.state.swift_code_state === 'success'}
                    error={this.state.swift_code_state === 'error'}
                    labelText={<span>SWIFT Code</span>}
                    id='swift_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.swift_code,
                      onChange: event => this.newBeneficiaryChange(event, 'swift_code', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_aba && (
                  <CustomInput
                    success={this.state.aba_routing_number_state === 'success'}
                    error={this.state.aba_routing_number_state === 'error'}
                    labelText={<span>ABA Routing Number</span>}
                    id='aba_routing_number'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.aba_routing_number,
                      onChange: event => this.newBeneficiaryChange(event, 'aba_routing_number', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_sort_code && (
                  <CustomInput
                    success={this.state.sort_code_state === 'success'}
                    error={this.state.sort_code_state === 'error'}
                    labelText={<span>Sort Code</span>}
                    id='sort_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.sort_code,
                      onChange: event => this.newBeneficiaryChange(event, 'sort_code', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_bsb && (
                  <CustomInput
                    success={this.state.bsb_code_state === 'success'}
                    error={this.state.bsb_code_state === 'error'}
                    labelText={<span>BSB</span>}
                    id='bsb_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.bsb_code,
                      onChange: event => this.newBeneficiaryChange(event, 'bsb_code', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_transit_code && (
                  <CustomInput
                    success={this.state.transit_code_state === 'success'}
                    error={this.state.transit_code_state === 'error'}
                    labelText={<span>Transit &amp; Institution No</span>}
                    id='transit_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.transit_code,
                      onChange: event => this.newBeneficiaryChange(event, 'transit_code', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_bank_code && (
                  <CustomInput
                    success={this.state.bank_code_state === 'success'}
                    error={this.state.bank_code_state === 'error'}
                    labelText={<span>Bank Code</span>}
                    id='bank_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.bank_code,
                      onChange: event => this.newBeneficiaryChange(event, 'bank_code', 'length', 3)
                    }}
                  />
                )}

                {this.state.show_account_number && (
                  <CustomInput
                    success={this.state.account_number_state === 'success'}
                    error={this.state.account_number_state === 'error'}
                    labelText={<span>Account Number </span>}
                    id='account_number'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.account_number,
                      onChange: event => this.newBeneficiaryChange(event, 'account_number', 'length', 3)
                    }}
                  />
                )}
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
            <Button onClick={() => this.addBeneficiaryModelClose()}>Cancel</Button>
            <Button color='primary' onClick={() => this.finishedCreateBene()}>
              Add Beneficiary
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  buildTableData() {
    //return
  }

  createUI() {
    const {classes} = this.props;
    //console.log(this.state.user_beneficiaries_list);

    // const beneficiary_list_select_options = this.state.user_beneficiaries_list
    //   .sort((a, b) => a.nickname.localeCompare(b.nickname))
    //   .map(item => {
    //     return { value: item.id, label: item.nickname };
    //   });

    // const purpose_of_payment_select_options = this.state.purpose_list
    //   .map(item => ({
    //     value: item.id,
    //     label: item.description
    //   }));

    return (
      <Button size='sm' color='info' key='addKey' onClick={() => this.addBeneficiaryModelOpen()}>
        Create new beneficiary
      </Button>
    );
  }

  openDoument = async data => {
    const {file_ref} = data;
    if (file_ref) {
      const file_url = await Storage.get(file_ref, {expires: 60});
      window.open(file_url);
    }
  };

  handleClose = () => {
    this.setState({dialog_delete_confirm: false});
  };

  handleDetailSubmit = async () => {
    const payload = {
      payoutBankId: this.state.currentRecord.payoutBankId,
      payoutDate: this.state.currentRecord.payoutDate
    };
    try {
      await API.put('transfers', `/update/update_transfer_payout/${this.props.transfer_id}`, {body: payload});
    } catch (error) {
      console.error('error', error);
    }
  };

  getFullDateTime = s => {
    //console.log(s);
    let showDateTime = '';
    if (s.getMonth() >= 9) {
      if (s.getDate() > 9) {
        showDateTime =
          s.getFullYear() +
          '-' +
          (s.getMonth() + 1) +
          '-' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      } else {
        showDateTime =
          s.getFullYear() +
          '-' +
          (s.getMonth() + 1) +
          '-0' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      }
    } else if (s.getMonth() < 9) {
      if (s.getDate() > 9) {
        showDateTime =
          s.getFullYear() +
          '-0' +
          (s.getMonth() + 1) +
          '-' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      } else {
        showDateTime =
          s.getFullYear() +
          '-0' +
          (s.getMonth() + 1) +
          '-0' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      }
    }
    return showDateTime;
  };

  handleNewPayout = async () => {
    console.log(this.state.newPayoutAmount)
    
    let payoutList = this.state.payouts_list;
    console.log(payoutList)

    let newPayout = {...payoutList[0]}
    newPayout.id = null
    newPayout.amount_to = this.state.newPayoutAmount
    payoutList.push(newPayout);

    await API.post('transfers', '/payouts/create', {
      body: newPayout
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      })

    let payoutTotal = 0;
    for (let i = 0; i < payoutList.length; i++) {
      payoutTotal += parseFloat(payoutList[i].amount_to);
    }
    let payoutDifference = payoutTotal - this.state.transfer.amount_to

    this.setState({
      payouts_list: payoutList,
      newPayoutAmount: 0,
      payoutTotal: payoutTotal,
      payoutDifference: payoutDifference
    })
  }

  handleDeletePayout = async (payoutID) => {
    // console.log(payoutID)
    let payoutList = this.state.payouts_list;
    // console.log(payoutList)

    if (payoutList.length < 2) {
      alert("Unable to delete payout. At least one payout is required.")
      return
    }

    payoutList = payoutList.filter(payout => payout.id !== payoutID)

    await API.get('transfers', `/payouts/delete/${payoutID}`)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      })

    let payoutTotal = 0;
    for (let i = 0; i < payoutList.length; i++) {
      payoutTotal += parseFloat(payoutList[i].amount_to);
    }
    let payoutDifference = payoutTotal - this.state.transfer.amount_to

    this.setState({
      payouts_list: payoutList,
      newPayoutAmount: 0,
      payoutTotal: payoutTotal,
      payoutDifference: payoutDifference
    })
  }

  handleGlobalIME = (payoutID) => {
    this.props.history.push("/backoffice/global-ime-code");
  }

  handleMbl = (payoutID) => {
    this.props.history.push("/backoffice/mbl-code");
  }

  render() {
    const {classes} = this.props;
    // console.log(this.state.transfer)
    // console.log(this.state.payouts_list)

    const backOffice = this.props.app_state.current_staff.backofficeStaff == 1;

    const beneficiary_list_select_options = this.state.user_beneficiaries_list //user_beneficiaries_list => this user's beneficiaries_list
      //this.state.beneficiary_list //all bene
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          label: item.nickname
        };
      });

    const entity_bank_list = this.state.entityBankAccounts.map(item => {
      return {
        value: item.id,
        label: item.nickname
      };
    });

    if (!this.state.currency_to) {
      return null;
    }
    if (!this.state.currency_from) {
      return null;
    }
    // console.log(this.state.currency_from)

    let payoutDifference = 0;
    let payoutTotal = Number.parseFloat(this.state.payoutTotal || 0)
    let transferAmountTo = Number.parseFloat(this.state.transfer.amount_to || 0)
    payoutDifference = payoutTotal - transferAmountTo
    console.log('payoutTotal', payoutTotal);
    console.log('transferAmountTo', transferAmountTo);
    console.log('payoutDifference', payoutDifference)

    return (
      <React.Fragment>

              <GridContainer>
                <GridItem md={6}>
                  <AntCard 
                    style={{height: '95%'}}
                    title="Transaction"
                    >
                      <table style={{textAlign: 'left'}}>
                        <tbody>
                        <tr>
                          <th style={{width: "200px"}}>
                            Transfer ID: 
                          </th>
                          <td>
                            {this.props.transfer_id}
                          </td>
                        </tr>
                        <tr>
                          <th>
                          Amount From:
                          </th>
                          <td>
                          {this.state.currency_from.short_name}&nbsp;
                        {Number(this.props.amount_from).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <th>
                          Rate:
                          </th>
                          <td>
                          {Number(this.props.rate).toFixed(4)}
                          </td>
                        </tr>
                        <tr>
                          <th>
                          Amount To (total):
                          </th>
                          <td>
                          {this.state.currency_to.short_name}&nbsp;
                        {Number(this.props.amount_to).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Settlement:
                          </th>
                          <td>
                            {moment(this.props.settlement_date).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                  </AntCard>
                </GridItem>
                  <GridItem md={6}>
                    <AntCard 
                      style={{height: '95%'}}
                      title="Communication"
                      >
                        <TransferDetailPayoutCommunication
                          id={this.props.client_id}
                          transfer_id={this.props.transfer_id}
                          transferId={this.props.transfer_id}
                          currentStaffID={this.props.app_state.current_staff.id}
                          current_staff_email={this.props.app_state.current_staff.email}
                          rate={this.props.rate}
                          amount_from={this.props.amount_from}
                          amount_to={this.props.amount_to}
                          currency_from={this.state.currency_from}
                          currency_to={this.state.currency_to}
                          settlement_date={this.props.settlement_date}
                          beneficiary_id={this.state.beneficiary_id}
                          payouts_list={this.state.payouts_list}
                          payoutDifference={this.state.payoutDifference}
                          // eba_account_name={this.state.entity_bank_account.eba_accout_name}
                          enabled={this.props.current_staff_super_admin}
                          language_nickname={this.state.language_nickname}
                          language_id={this.props.language_id}
                          emailsent_payout_date={this.state.transfer.emailsent_payout_date}
                          emailsent_payout_staff={this.state.transfer.emailsent_payout_staff}
                            // remitting_bank_id={this.props.remitting_bank_id}
                          // remittance_reference={remittance_reference}
                          transfer_status={this.state.transfer_status}
                        />
                    </AntCard>
                  </GridItem>
            </GridContainer>



        <form onSubmit={this.handleSubmit}>
          <GridContainer>
            <GridItem md={12}>
              <AntCard 
                title="Payout Details"
                >
                  <ReactTable
                    // style={{ width: "100%" }}
                    data={this.state.payouts_list}
                    pageSize={this.state.payouts_list.length > 10 ? 10 : this.state.payouts_list.length}
                    //data={this.buildTableData()}
                    filterable={false}
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'nickname',
                        sortable: this.state.payouts_list.length > 1,
                        Cell: c => (
                          <React.Fragment>
                            {c.value === 0 || c.value === 'null' || c.value === '' ? (
                              <React.Fragment>{<div>Beneficiary To Be Provided</div>}</React.Fragment>
                            ) : c.original.holdingAccountPayee ? (
                              <span>Holding Account Transfer to ledger #{c.original.holdingAccountPayee} </span>
                            ) : (
                              c.original.nickname
                            )}
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Address',
                        accessor: 'ben_address_line_1', //"nickname", use c.original.nickname to get that value
                        // filterMethod: (filter, rows) =>
                        //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                        // filterAll: true,
                        sortable: this.state.payouts_list.length > 1,
                        Cell: c => (
                          //if beneficiary_id === 0, do something, work around #798
                          <React.Fragment>
                            <div>
                              {c.original.ben_address_line_1}{` `}
                              {c.original.ben_address_line_2}{` `}
                              {c.original.ben_address_suburb}{` `}
                              {c.original.ben_address_state}{` `}
                              {c.original.ben_address_postcode}
                            </div>
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Bank',
                        accessor: 'bank_legal_name', //"nickname", use c.original.nickname to get that value
                        // filterMethod: (filter, rows) =>
                        //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                        // filterAll: true,
                        sortable: this.state.payouts_list.length > 1,
                        Cell: c => (
                          //if beneficiary_id === 0, do something, work around #798
                          <React.Fragment>
                            <div>
                              {c.original.bank_legal_name}{` `}
                              {c.original.bank_address_line_1}{` `}
                              {c.original.bank_address_line_2}{` `}
                              {c.original.bank_address_suburb}{` `}
                              {c.original.bank_address_state}{` `}
                              {c.original.bank_address_postcode}
                            </div>
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Account',
                        accessor: 'swift_code',
                        sortable: this.state.payouts_list.length > 1,
                        Cell: c => (
                          //if beneficiary_id === 0, do something, work around #798
                          <React.Fragment>
                            <div>
                            {c.original.swift_code && 
                            <>
                              SWIFT: {c.original.swift_code} <br />
                              </>
                            }
                            {c.original.aba_routing_number && 
                            <>
                              ABA: {c.original.aba_routing_number} <br />
                              </>
                            }
                            {c.original.bsb_code && 
                            <>
                              BSB: {c.original.bsb_code} <br />
                              </>
                            }
                            {c.original.account_number && 
                            <>
                              Account: {c.original.account_number} <br />
                              </>
                            }
                            {c.original.iban && 
                            <>
                              IBAN: {c.original.iban} <br />
                              </>
                            }
                            </div>
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Amount',
                        accessor: 'amount_to',
                        sortable: this.state.payouts_list.length > 1,
                        Cell: c => (
                          //set in a currency flag, and fix digit into minimumFractionDigits 2 and maximumFractionDigits={2}
                          <React.Fragment>
                            <div
                              className={`currency-flag currency-flag-${
                                this.state.transfer.currency_to_iso_alpha_3
                                  ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                  : ''
                              }`}
                            />{' '}
                            {this.state.transfer.currency_to_iso_alpha_3} <br />
                            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={c.value} />
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Document',
                        accessor: 'document_id',
                        sortable: this.state.payouts_list.length > 1,
                        Cell: c =>
                          c.original.file_ref ? (
                            <Button onClick={() => this.openDoument(c.original)}> View </Button>
                          ) : (
                            <span>No Doc Uploaded</span>
                          ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Purpose',
                        accessor: 'description',
                        sortable: this.state.payouts_list.length > 1,
                        maxWidth: 450
                      },
                      {
                        Header: 'Risk',
                        accessor: 'benRiskRating',
                        // sortable: this.state.payouts_list.length > 1,
                        Cell: c => (
                          <React.Fragment>
                            {c.value == 1 ? "Low" : c.value == 2 ? "Medium" : c.value == 3 ? "High" : ""}
                          </React.Fragment>
                        )
                        // maxWidth: 450
                      },
                      {
                        Header: 'Paid',
                        accessor: 'payoutDate',
                        sortable: this.state.payouts_list.length > 1,
                        Cell: c => (
                          <React.Fragment>
                            {c.value ? moment(c.value).format("DD-MM-YYYY") : ""}<br/>
                            {c.original.payoutBankNickname}<br/>
                            {c.original.payoutRef}
                          </React.Fragment>
                        )
                        // maxWidth: 450
                      },
                      {
                        Header: 'Action',
                        accessor: 'beneficiary_id', //"nickname", Will switch back to nickname while next build #798
                        // filterMethod: (filter, rows) =>
                        //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                        // filterAll: true,
                        sortable: this.state.payouts_list.length > 1,
                        Cell: c => (
                          <React.Fragment>
                              {/* <Button
                                fullWidth={true}
                                color='info'
                                disabled={this.state.transfer.status !== 8}
                                size='sm'
                                onClick={() => {
                                  const win = window.open(
                                    `/transfers/receipt/payout/${c.original.transfer_id}?payout=${c.index}`,
                                    '_blank'
                                  );
                                  win.focus();
                                }}
                              >
                                View / PDF Payout Receip
                              </Button>
                            <br /> */}
                              <Button
                                fullWidth={true}
                                color='info'
                                size='sm'
                                onClick={() => {
                                  this.setState({
                                    dialog_delete_confirm: true,
                                    currentRecord: c.original
                                  });
                                }}
                              >
                                View Details
                              </Button>
                            <br />
                              <Button
                                fullWidth={true}
                                color='info'
                                size='sm'
                                onClick={() => {
                                  this.handleDeletePayout(c.original.id)
                                }}
                              >
                                Delete Payout
                              </Button>
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={false}
                    className='-highlight'
                  />
                  <br />
                  <GridContainer>
                    <GridItem xs={12}>
                    <Input 
                      style={{width: 200}}
                      value={this.state.newPayoutAmount}
                      onChange={(e) => this.setState({
                        newPayoutAmount: e.target.value
                      })}
                    >
                    </Input>
                    &nbsp;&nbsp;
                    <Button
                      // fullWidth={true}
                      color='info'
                      size='sm'
                      onClick={() => {
                        this.handleNewPayout()
                      }}
                    >
                      Add Payout
                    </Button>
                    </GridItem>
                  </GridContainer>
                  <br />
                    <AntCard>
                        <GridContainer>
                          <GridItem xs={12}>
                            <h4>
                              <strong>Total Payout Amount</strong>
                            </h4>
                          </GridItem>
                          <GridItem xs={12}>
                            <h4>
                              <strong>
                                <FormattedNumber
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                  value={this.state.payoutTotal}
                                />
                              </strong>
                            </h4>
                            Difference: &nbsp;
                            <span style={{color: 
                              payoutDifference < 0 ? "red" : 
                              payoutDifference == 0 ? "green" : "black"  
                            }}>
                              {payoutDifference == 0 ? 
                                "Payout balanced" : 
                                <FormattedNumber
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                  value={payoutDifference}
                                />
                              }
                            </span>
                          </GridItem>
                        </GridContainer>
                    </AntCard>
              </AntCard>
            </GridItem>
            <Dialog
              open={
                this.state.dialog_delete_confirm &&
                this.state.currentRecord &&
                Object.values(this.state.currentRecord).length > 0
              }
              onClose={this.handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>Transfer Detail</DialogTitle>
              <DialogContent>
                <GridContainer>
                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Name </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value: this.state.currentRecord && this.state.currentRecord.ben_legal_name,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Address </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              this.state.currentRecord &&
                              `${this.state.currentRecord.ben_address_line_1} ${this.state.currentRecord.ben_address_suburb}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Bank </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              this.state.currentRecord &&
                              `${this.state.currentRecord.bank_legal_name} ${this.state.currentRecord.bank_address_line_1}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Swift </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              this.state.currentRecord &&
                              `${this.state.currentRecord.swift_code}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>IBAN </span>}
                          id='iban'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              this.state.currentRecord &&
                              `${this.state.currentRecord.iban} ${this.state.currentRecord.iban}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Detail 1 </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value:
                              this.state.currentRecord &&
                              `${this.state.currentRecord.detail_1}`,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12}>
                        {/* <CustomInput
                      labelText={<span>Amount </span>}
                      id="account_number"
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.currentRecord && this.state.currentRecord.ben_legal_name,
                        disabled: true
                      }}
                    /> */}
                        <React.Fragment>
                          <label>
                            <span style={{fontSize: '11px'}}>Amount</span>
                          </label>{' '}
                          <br />
                          <div
                            className={`currency-flag currency-flag-${
                              this.state.transfer && this.state.transfer.currency_to_iso_alpha_3
                                ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                : ''
                            }`}
                          />{` `}
                          {this.state.transfer && this.state.transfer.currency_to_iso_alpha_3}{`  `}
                          {this.state.currentRecord && this.state.currentRecord.amount_to && 
                          <FormattedNumber
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            value={this.state.currentRecord.amount_to}
                          />
                          }
                        </React.Fragment>
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Purpose </span>}
                          id='account_number'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value: this.state.currentRecord && `${this.state.currentRecord.description} `,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12}>
                        {/* <CustomInput
                      labelText={<span>Status </span>}
                      id="account_number"
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: parseInt(this.props.transfer_status) *,
                        disabled: true
                      }}
                    /> */}
                        <label>
                          <span style={{fontSize: '11px'}}>Status</span>
                        </label>
                        <div>
                          <CircularProgressbar
                            className={classes.progressBar}
                            value={this.props.isCompleted ? 100 : TRANSFER_STATUS_COMPLETE_VALUE[this.props.transfer_status].value}
                            text={`${this.props.isCompleted ? 100 : TRANSFER_STATUS_COMPLETE_VALUE[this.props.transfer_status].value}%`}
                            styles={buildStyles({
                              textColor: '#000',
                              textSize: '24px',
                              pathColor: '#632DE6'
                            })}
                          />
                          <span>{TRANSFER_STATUS_COMPLETE_VALUE[this.props.transfer_status].text}</span>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomReactSelect
                          //error={this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id_state === "error"}
                          label='Payout Bank'
                          options={entity_bank_list}
                          // value={this.state.currentRecord && 9}
                          value={this.state.currentRecord && this.state.currentRecord.payoutBankId}
                          onChange={this.handleCustomReactSelectChange('payoutBankId')}
                          isClearable={false}
                          //isDisabled={this.state.prefill_beneficiary}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12}>
                        <FormControl fullWidth error={this.state.formInput.dateStatus === false}>
                          <Datetime
                            timeFormat={false}
                            dateFormat={'DD/MM/YYYY'}
                            closeOnSelect={true}
                            // isValidDate={false}
                            inputProps={{
                              // value: this.state.formInput.date,
                              value: this.state.currentRecord && `${this.state.currentRecord.payoutDate} `,
                              label: 'Payout Date',
                              placeholder: 'Date',
                              error: this.state.formInput.dateStatus
                            }}
                            onChange={event => {
                              const selDate = this.getFullDateTime(new Date(event.toDate()));
                              this.setState({
                                currentRecord: {
                                  ...this.state.currentRecord,
                                  payoutDate: selDate.split(' ')[0]
                                }
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params}  />
                            )}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={<span>Payout Reference</span>}
                          id='payoutRef'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value: this.state.currentRecord && `${this.state.currentRecord.payoutRef} `,
                            disabled: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color='primary'>
                  Close
                </Button>
                <Button onClick={this.handleDetailSubmit} color='primary'>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
            {/* {
              this.state.transfer_status === 8 && (
                <React.Fragment>
                  <GridItem md={4}>
                    <Card style={{height: '95%'}}>
                      <CardHeader>
                        <h5>Communication</h5>
                      </CardHeader>
                      <CardBody>
                        <TransferDetailPayoutCommunication
                          id={this.props.client_id}
                          transfer_id={this.props.transfer_id}
                          transferID={this.props.transfer_id}
                          currentStaffID={this.props.app_state.current_staff.id}
                          current_staff_email={this.props.app_state.current_staff.email}
                          rate={this.props.rate}
                          amount_from={this.props.amount_from}
                          amount_to={this.props.amount_to}
                          currency_from={this.state.currency_from}
                          currency_to={this.state.currency_to}
                          settlement_date={this.props.settlement_date}
                          beneficiary_id={this.state.beneficiary_id}
                          payouts_list={this.state.payouts_list}
                          payoutDifference={this.state.payoutDifference}
                          // eba_account_name={this.state.entity_bank_account.eba_accout_name}
                          enabled={this.props.current_staff_super_admin}
                          language_nickname={this.state.language_nickname}
                          language_id={this.props.language_id}
                          // remitting_bank_id={this.props.remitting_bank_id}
                          // remittance_reference={remittance_reference}
                          transfer_status={this.state.transfer_status}
                        />
                      </CardBody>
                    </Card>
                  </GridItem>
                </React.Fragment>
              )
            } */}
            {/*
              hardcode: this.state.payouts_list[0].beneficiary_id at this phase
              (this.state.payouts_list[0].beneficiary_id === 0) &&
            */}
            {typeof this.state.payouts_list[0] === 'undefined'
              ? ''
              : this.props.current_staff_super_admin &&
                this.state.transfer_status === 8 &&
                this.state.payouts_list[0].beneficiary_id === 0 && (
                  <GridItem md={8}>
                    <Card>
                      <CardBody>
                        <React.Fragment>
                          <span>
                            <h5>Payout details for “No beneficiary. from below and click “SAVE” to add” </h5>
                            <CustomReactSelect
                              //error={this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id_state === "error"}
                              label='Select beneficiary'
                              options={beneficiary_list_select_options}
                              value={this.state.beneficiary_id}
                              onChange={this.handleCustomReactSelectChange('beneficiary_id')}
                              isClearable={false}
                              //isDisabled={this.state.prefill_beneficiary}
                            />
                            <p> </p>
                            <Button
                              size='sm'
                              color='info'
                              key='saveBeneficiaryKey'
                              onClick={
                                //test
                                () => {
                                  this.setState({
                                    addBeneficiaryModalOpen: false,
                                    ACTIONaddBeneficiaryModalOpen: false
                                  });

                                  API.put(
                                    'transfers', //copied from client-portal-api, there is a payments in cp project
                                    `/update/update_from_editor/${this.state.payouts_list[0].id}`,
                                    {
                                      body: {
                                        beneficiary_id: this.state.beneficiary_id //...data
                                      }
                                    }
                                  )
                                    .then(database_result => {
                                      console.log(database_result);
                                      //reload the page after getting result
                                      window.location.reload();
                                    })
                                    .catch(error => {
                                      console.log(error);
                                    });
                                }
                              }
                              disabled={!this.state.beneficiary_id}
                            >
                              Save
                            </Button>
                            <Button
                              size='sm'
                              color='info'
                              key='addBeneficiaryKey'
                              onClick={() => this.addBeneficiaryModelOpen()}
                              disabled={Boolean(this.state.beneficiary_id) || !this.state.user_beneficiaries_list} //and {!this.state.user_beneficiaries_list}
                            >
                              Create new beneficiary
                            </Button>
                          </span>
                          <span>{this.createActionUi(beneficiary_list_select_options)}</span>
                          <span>{this.createNewBeneficiaryUi()}</span>
                        </React.Fragment>
                      </CardBody>
                    </Card>
                  </GridItem>
                )}
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }
}

// TransferDetailPayout.propTypes = {classes: PropTypes.object.isRequired};
// export default withRouter(injectIntl(withStyles(styles)(TransferDetailPayout)));


TransferDetailPayout =  withRouter(injectIntl(withStyles(styles)(TransferDetailPayout)));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    // clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // loadClientDetailsRequest: () => {
    //   dispatch(loadClientDetailsRequest())
    // },
    // loadClientDetailsSuccess: () => {
    //   dispatch(loadClientDetailsSuccess())
    // },
    // loadTransferDetailsRequest: () => {
    //   dispatch(loadTransferDetailsRequest());
    // },
    // loadTransferDetailsSuccess: () => {
    //   dispatch(loadTransferDetailsSuccess());
    // }
  };
};

const TransferDetailPayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferDetailPayout);

export default TransferDetailPayoutContainer;
