import React from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {AiOutlineSearch} from 'react-icons/ai';
// import IdentificationList from "./IdentificationList";
import {
  fetchIdentificationList,
  fetchIdentificationListAll
  // setBeneficiarySelectId
} from '../../redux/actions/identification';

import Dvr from '@material-ui/icons/Dvr';

import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import matchSorter from 'match-sorter';
import {Button, Col, Divider, Flex, message, Modal, Row, Table, Typography} from 'antd'
import ListTable from '../ListTable/ListTable'
import {API} from "aws-amplify";
import {EditOutlined, MailOutlined, SearchOutlined} from "@ant-design/icons";
import FileUploader from "../Uploaders/FileUploader";

var moment = require('moment');

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - IdentificationList
 * ----------------------------------------------------------------------------
 */
class IdentificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_list: [],
      columns: []
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const {fetchIdentificationList, fetchIdentificationListAll} = this.props;
    // console.log(this.props);
    // eslint-disable-next-line react/prop-types
    if (this.props.client_id) {
      fetchIdentificationList(this.props.client_id);
    } else {
      fetchIdentificationListAll();
    }
    // const columns = this.buildTableColumns();
    // this.setState({columns: columns});
  }

  buildTableColumns() {
      return [
          {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              sorter: { compare: (a,b) => a.id - b.id },
              defaultSortOrder: 'descend',
              ...this.props.getColumnSearchProps({
                  dataIndex: 'id',
                  label: 'id',
                  render: (text, record) => {
                      return (
                          <>
                              <span>{record.id}</span>
                          </>
                      );
                  }
              })
          },
          !this.props.client_id ? {
              title: 'Team',
              dataIndex: 'team_nick_name',
              key: 'team_nick_name',
              sorter: { compare: (a,b) => a.team_nick_name - b.team_nick_name },
              ...this.props.getColumnSearchProps({
                  dataIndex: 'team_nick_name',
                  label: 'team_nick_name',
                  render: (text, record) => {
                      return (
                          <>
                              <span>{record.team_nick_name}</span>
                          </>
                      );
                  }
              })
          } : {},
          !this.props.client_id ? {
              title: 'Client',
              dataIndex: 'client_name',
              key: 'client_name',
              sorter: { compare: (a,b) => a.nickname - b.nickname },
              ...this.props.getColumnSearchProps({
                  dataIndex: 'client_name',
                  label: 'client_name',
                  render: (text, record) => {
                      return (
                          <>
                              <Typography.Link onClick={() => window.open(`/clients/edit/${record.client_id}`, '_blank')}>{record.client_name}</Typography.Link>
                          </>
                      );
                  }
              })
          } : {},
          {
              title: 'Category',
              dataIndex: 'document_category',
              key: 'document_category',
              filters: [
                  {text: 'Passport', value: 'Passport'},
                  {text: 'Drivers License', value: 'Drivers Licence'},
                  {text: 'Medicare', value: 'Medicare'},
                  {text: 'Visa', value: 'Visa'},
                  {text: 'Birth Record', value: 'Birth Record'},
                  {text: 'Marriage Record', value: 'Marriage Record'},
              ],
              onFilter: (value, record) => record.document_category != null ? record.document_category.indexOf(value) === 0 : false,
          },
          {
              title: 'Document Number',
              dataIndex: 'document_number',
              key: 'document_number',
              ...this.props.getColumnSearchProps({
                  dataIndex: 'document_number',
                  label: 'document_number',
                  render: (text, record) => {
                      return (
                          <>
                              <span>{record.document_number}</span>
                          </>
                      );
                  }
              })
          },
          {
              title: 'Expiry',
              dataIndex: 'document_expiry',
              key: 'document_expiry',
              ...this.props.getColumnSearchProps({
                  dataIndex: 'document_expiry',
                  label: 'document_expiry',
                  filterInputType: 'DATE',
                  render: (text, record) => text
              })
          },
          {
              title: 'Status',
              dataIndex: 'id_status',
              key: 'id_status',
              filters: this.props.client_id ? [
                  {text: 'Draft', value: 1},
                  {text: 'Submitted', value: 2},
                  {text: 'Checking', value: 3},
                  {text: 'Checked - Error', value: 4},
                  {text: 'Checked - Valid', value: 5},
                  {text: 'Archived', value: 6},
              ] : [
                  {text: 'Draft', value: 'Draft'},
                  {text: 'Submitted', value: 'Submitted'},
                  {text: 'Checking', value: 'Checking'},
                  {text: 'Checked - Error', value: 'Checked - Error'},
                  {text: 'Checked - Valid', value: 'Checked - Valid'},
                  {text: 'Archived', value: 'Archived'},
              ],
              onFilter: (value, record) => this.props.client_id ? record.id_status === value : (record.id_status != null ? record.id_status.indexOf(value) === 0 : false),
              render: (text, record) => {

                  if (this.props.client_id) {
                      const d = [
                          {text: 'Draft', value: 1},
                          {text: 'Submitted', value: 2},
                          {text: 'Checking', value: 3},
                          {text: 'Checked - Error', value: 4},
                          {text: 'Checked - Valid', value: 5},
                          {text: 'Archived', value: 6},
                      ];
                      if  (text != null) {
                          return d.find(i => i.value === text).text;
                      } else {
                          return '-'
                      }
                  } else {
                      return text;
                  }
              }
          },
          {
              title: 'Client Contact',
              dataIndex: 'contactFirstName',
              key: 'contactFirstName',
              render: (text, record) => {
                  if (record.contactFirstName != null && record.contactLastName != null) {
                      return `${record.contactFirstName} ${record.contactLastName}`;
                  } else {
                      return '-'
                  }

              }
          },
          {
              title: 'Action',
              dataIndex: 'actions',
              key: 'actions'
          }
      ]
  }

  sendEmail = async (id, isStaff = false) => {
      const text = `Sending message to ${isStaff ? 'Staff' : 'Client'}. Please wait..`;
      const loading = message.loading(text, 0);
      try {
          await API.post("email-18", "/identification/send-email", {
              body: {
                  isStaff: isStaff,
                  staff_email: this.props.app_state.current_staff.email,
                  identificationId: id
              }
          });
          message.success("Message sent.");
      } catch (e) {
          message.error("Unable to send email. Please try again..");
          console.log(e);
      } finally {
          loading();
      }
  }

    viewDocuments = async (item) => {
        const loading = message.loading("Loading transfer documents. Please wait..", 0);

        let documentIds = item.attachments != null ? (JSON.parse(item.attachments).ids || []) : [];

        if (documentIds.length > 0) {
            const documents = await API.post("commons", "/fetch", {
                body: {
                    context: 'documents',
                    fields: ['*'],
                    condition: {id: documentIds}
                }
            });

            const attachments = documents.map(item => item.file_ref);

            Modal.confirm({
                title: 'Transfer Documents',
                width: 600,
                content: <>
                    <Divider/>
                    <Row gutter={[16, 16]}>
                        {attachments.map(i => <>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                <FileUploader previewOnly={true}
                                              thumbType={'picture-card'}
                                              uploaderType={'PDF'}
                                              fetchFileList={() => {
                                                  const file = {
                                                      name: i,
                                                      uid: i,
                                                      status: 'done'
                                                  }
                                                  return Promise.resolve([file]);
                                              }}></FileUploader>
                            </Col>
                        </>)}
                    </Row>
                </>,
                cancelButtonProps: {hidden: true},
                maskClosable: true
            })
        }
        loading();
    }

  buildTableData() {
    var identification_list_length = this.props.identification.identification_list.length;
    if (identification_list_length > 0) {
      // eslint-disable-next-line no-unused-vars
      return this.props.identification.identification_list.map((prop, key) => {
        return {
          id: prop.id,
          nickname: prop.nickname,
          // file_id: prop.file_id,
          client_name: prop.client_nickname,
          document_category: prop.document_category,
          document_number: prop.document_number,
          document_expiry: prop.document_expiry != null ? moment(prop.document_expiry).format('DD/MM/YYYY') : null,
          id_status: prop.id_status,
          client_id: prop.client_id,
          team_nick_name: prop.team_nick_name,
          client_contact_id: prop.client_contact_id,
          contactFirstName: prop.contactFirstName,
          contactLastName: prop.contactLastName,
          actions: (
              <>
                  <Flex justify={'start'} gap={5} style={{ marginBottom: 10 }}>
                      <Button icon={<SearchOutlined/>} size={'small'} onClick={() => this.viewDocuments(prop)}>View</Button>
                      <Button size={'small'}
                              icon={<EditOutlined />}
                              onClick={() => {
                                  window.open(`/identification/edit/${prop.id}`, '_blank')
                              }} className='edit'>
                          Edit
                      </Button>
                  </Flex>
                  <Flex justify={'start'} gap={5}>
                      <Button size={'small'} onClick={() => this.sendEmail(prop.id, true)}
                              icon={<MailOutlined/>}> Staff</Button>
                      <Button size={'small'} onClick={() => this.sendEmail(prop.id)}
                              icon={<MailOutlined/>}> Client</Button>
                  </Flex>
              </>
          )
        };
      });
    }
  }

  getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  render() {
    var client_name = this.props.identification.identification_list
      .filter(u => u.client_nickname != null)
      .map((prop, key) => {
        return prop.client_nickname;
      });
    var client_name_unique = [...new Set(client_name)];
    // eslint-disable-next-line no-unused-vars
    var client_name_optionlist = client_name_unique.map((prop, key) => {
      // eslint-disable-next-line react/jsx-key
      return (
        <option key={key} value={prop}>
          {prop}
        </option>
      );
    });

    var category_name = this.props.identification.identification_list
      .filter(u => u.document_category != null)
      .map((prop, key) => {
        return prop.document_category;
      });
    var category_name_unique = [...new Set(category_name)];
    // eslint-disable-next-line no-unused-vars
    var category_name_optionlist = category_name_unique.map((prop, key) => {
      // eslint-disable-next-line react/jsx-key
      return (
        <option key={key} value={prop}>
          {prop}
        </option>
      );
    });

    var status_name = this.props.identification.identification_list
      .filter(u => u.id_status != null)
      .map((prop, key) => {
        return prop.id_status;
      });
    var status_name_unique = [...new Set(status_name)];
    // eslint-disable-next-line no-unused-vars
    var status_name_optionlist = status_name_unique.map((prop, key) => {
      // eslint-disable-next-line react/jsx-key
      return (
        <option key={key} value={prop}>
          {prop}
        </option>
      );
    });
    return (
      <Table dataSource={this.buildTableData()} columns={this.buildTableColumns()}></Table>
    );
  }
}

// export default withRouter(withStyles(styles)(IdentificationList));
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    identification: state.identification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchIdentificationList: client_id => {
      dispatch(fetchIdentificationList(client_id));
    },
    fetchIdentificationListAll: () => {
      dispatch(fetchIdentificationListAll());
    }
    // setBeneficiarySelectId: (id) => {
    //   dispatch(setBeneficiarySelectId(id))
    // }
  };
};

const IdentificationListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(IdentificationList))));

export default IdentificationListContainer;
