import {Button, Col, Divider, Flex, Form, Input, message, Modal, Row, Select, Tag} from "antd";
import React, {useState} from "react";
import {COMPANY_SCREEN_OUTCOMES} from "./RiskConstants";
import {AppTemplates} from "../../../../AppTemplates";
import {connect} from "react-redux";
import {API} from "aws-amplify";
import {useForm} from "antd/es/form/Form";

const ClientRiskCompany = ({
                               clientContacts,
                               getRiskRating,
                               setRiskRating,
                               setComplianceRating,
                               riskRating,
                               getRiskColor,
                               overallRating,
                               getOptions,
                               setRiskCountry,
                               app_state,
                               emitChanges,
                           }) => {

    const [riskForm] = useForm();
    const [componentState, setComponentState] = useState({
        isModalOpen: false,
        activeContactId: null
    });

    const getScore = (key, value) => {
        const risk = COMPANY_SCREEN_OUTCOMES.find(item => key === item.key);
        const option = risk.options.find(item => item.value === value);
        if (option != null) {
            return `${option.label} (${option.score})`
        }
        return '-'
    }

    const getContactsScore = (index, value) => {
        const data = COMPANY_SCREEN_OUTCOMES[index].options.find(i => i.value === value);
        if (data != null) {
            return data.score
        }
        return '-'
    }

    const getContactsColor = (index, value) => {
        const data = COMPANY_SCREEN_OUTCOMES[index].options.find(i => i.value === value);
        if (data != null) {
            return getRiskColor(data.riskLevel);
        }
        return getRiskColor(null);
    }
    const getContactsLabel = (index, value) => {
        const data = COMPANY_SCREEN_OUTCOMES[index].options.find(i => i.value === value);
        if (data != null) {
            return getRiskRating(data.riskLevel);
        }
        return getRiskRating(null);
    }

    const openContactRiskScoreChangeModal = (contactId) => {
        const loading = message.loading("Opening contact risk. Please wait..", 0);
        setComponentState(prev => ({...prev, isModalOpen: true}));
        API.post("commons", "/fetch", {
            body: {
                context: 'client_contacts',
                fields: ['*'],
                condition: {id: contactId}
            }
        }).then(([response]) => {
            riskForm.setFieldsValue(response);
            console.log("values", response);
            setComponentState(prev => ({...prev, activeContactId: contactId}));
        }).catch(err => {
            console.log("unable to fetch");
            message.error("Unable to fetch. Please try again..");
            setComponentState(prev => ({...prev, isModalOpen: false }));
        }).finally(() => loading());
    }

    const handleContactRiskSubmit = (values) => {
        console.log(values);
        if (componentState.activeContactId == null) {
            message.error("Contact id not defined");
        } else {
            const loading = message.loading("Updating client contact risk. Please wait..", 0);
            API.post("commons", `/update/${componentState.activeContactId}`, {
                body: {
                    context: 'client_contacts',
                    data: values
                }
            }).then(res => {
                message.success(`Contact ID ${componentState.activeContactId} risk score updated successfully`);
                setComponentState(prev => ({...prev, isModalOpen: false, activeContactId: null}));
                emitChanges();
            }).catch(err => {
                console.log("error", err);
                message.error("unable to change risk score. Please try again...");
            }).finally(() => loading());
        }
    }


    return (<>

        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_legal_structure'} label={'Legal Structure'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, c_legal_structure: (option != null ? option.score : null)}));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_legal_structure: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_legal_structure')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_legal_structure'])}>{getRiskRating(riskRating['c_legal_structure'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_legal_structure'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_complexity'} label={'Complexity'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'}
                            allowClear={true}
                            onChange={(val, option) => {
                                setRiskRating(prev => ({
                                    ...prev,
                                    c_complexity: (option != null ? option.score : null)
                                }));
                                setComplianceRating(prev => ({
                                    ...prev,
                                    c_complexity: (option != null ? option.riskLevel : 0)
                                }));
                            }} options={getOptions('c_complexity')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['c_complexity'])}>{getRiskRating(riskRating['c_complexity'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_complexity'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_jurisdiction'} label={'Jurisdiction'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select optionFilterProp="alt" placeholder={' -- SELECT --'}
                            showSearch={true}
                            allowClear={true}
                            onClear={() => {
                                setRiskRating(prev => ({...prev, c_jurisdiction: null}));
                                setComplianceRating(prev => ({...prev, c_jurisdiction: null}));
                            }}
                            onChange={(val, option) => {
                                setRiskRating(prev => ({
                                    ...prev,
                                    c_jurisdiction: (option != null ? option.score : null)
                                }));
                                setComplianceRating(prev => ({
                                    ...prev,
                                    c_jurisdiction: (option != null ? option.riskLevel : null)
                                }));
                                setRiskCountry(val);
                            }} options={AppTemplates.countries(app_state.country_list)}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_jurisdiction'])}>
                    {getRiskRating(riskRating['c_jurisdiction'])}
                </Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_jurisdiction'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_product_service'} label={'Product / Service'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, c_product_service: (option != null ? option.score : null)}));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_product_service: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_product_service')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_product_service'])}>{getRiskRating(riskRating['c_product_service'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_product_service'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_channel_sales_referral'} label={'Channel Sales Referral'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({
                            ...prev,
                            c_channel_sales_referral: (option != null ? option.score : null)
                        }));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_channel_sales_referral: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_channel_sales_referral')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_channel_sales_referral'])}>{getRiskRating(riskRating['c_channel_sales_referral'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_channel_sales_referral'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_channel_service_access'} label={'Channel Service Access'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({
                            ...prev,
                            c_channel_service_access: (option != null ? option.score : null)
                        }));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_channel_service_access: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_channel_service_access')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_channel_service_access'])}>{getRiskRating(riskRating['c_channel_service_access'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_channel_service_access'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_channel_funding_method'} label={'Funding Method'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({
                            ...prev,
                            c_channel_funding_method: (option != null ? option.score : null)
                        }));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_channel_funding_method: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_channel_funding_method')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_channel_funding_method'])}>{getRiskRating(riskRating['c_channel_funding_method'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_channel_funding_method'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_channel_product_delivery_channel'} label={'Product Delivery Channel'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({
                            ...prev,
                            c_channel_product_delivery_channel: (option != null ? option.score : null)
                        }));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_channel_product_delivery_channel: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_channel_product_delivery_channel')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_channel_product_delivery_channel'])}>{getRiskRating(riskRating['c_channel_product_delivery_channel'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_channel_product_delivery_channel'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_source_of_funds'} label={'Source of Funds'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, c_source_of_funds: (option != null ? option.score : null)}));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_source_of_funds: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_source_of_funds')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_source_of_funds'])}>{getRiskRating(riskRating['c_source_of_funds'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_source_of_funds'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_source_of_wealth'} label={'Source of Wealth'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, c_source_of_wealth: (option != null ? option.score : null)}));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_source_of_wealth: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_source_of_wealth')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_source_of_wealth'])}>{getRiskRating(riskRating['c_source_of_wealth'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_source_of_wealth'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_business_relationship'} label={'Nature of Business Relationship'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({
                            ...prev,
                            c_business_relationship: (option != null ? option.score : null)
                        }));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_business_relationship: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_business_relationship')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_business_relationship'])}>{getRiskRating(riskRating['c_business_relationship'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_business_relationship'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_expected_account_activity'} label={'Expected Account Activity'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({
                            ...prev,
                            c_expected_account_activity: (option != null ? option.score : null)
                        }));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_expected_account_activity: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_expected_account_activity')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_expected_account_activity'])}>{getRiskRating(riskRating['c_expected_account_activity'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_expected_account_activity'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_customer_operations'} label={'Customer Operations'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({
                            ...prev,
                            c_customer_operations: (option != null ? option.score : null)
                        }));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_customer_operations: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_customer_operations')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_customer_operations'])}>{getRiskRating(riskRating['c_customer_operations'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_customer_operations'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'c_estimated_net_worth'} label={'Estimated Net Worth'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({
                            ...prev,
                            c_estimated_net_worth: (option != null ? option.score : null)
                        }));
                        setComplianceRating(prev => ({
                            ...prev,
                            c_estimated_net_worth: (option != null ? option.riskLevel : 0)
                        }));
                    }} options={getOptions('c_estimated_net_worth')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag
                    color={getRiskColor(riskRating['c_estimated_net_worth'])}>{getRiskRating(riskRating['c_estimated_net_worth'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['c_estimated_net_worth'] || 0}
            </Col>
        </Row>
        <Divider/>
        {clientContacts && clientContacts.map(item => <>
            <Flex justify={'space-between'}>
                <h4 style={{textDecoration: 'underline'}}>{item.firstName} {item.lastName}</h4>
                <Button size={'small'} type={'primary'} onClick={() => openContactRiskScoreChangeModal(item.id)}>Update Contacts #{item.id}</Button>
            </Flex>
            <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                    <Form.Item label={'Political Exposed Person'}>
                        <Input disabled value={getScore('c_political_person', item.pep)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                    <Tag
                        color={getContactsColor(0, item.pep)}>{getContactsLabel(0, item.pep)}</Tag>
                </Col>
                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                    {getContactsScore(0, item.pep)}
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
                <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                    <Form.Item label={'Sections'}>
                        <Input disabled value={getScore('c_sanctions', item.sanctions)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                    <Tag
                        color={getContactsColor(1, item.sanctions)}>{getContactsLabel(1, item.sanctions)}</Tag>
                </Col>
                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                    {getContactsScore(1, item.sanctions)}
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
                <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                    <Form.Item label={'Criminal Record'}>
                        <Input disabled value={getScore('c_criminal_record', item.criminalRecord)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                    <Tag
                        color={getContactsColor(2, item.criminalRecord)}>{getContactsLabel(2, item.criminalRecord)}</Tag>
                </Col>
                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                    {getContactsScore(2, item.criminalRecord)}
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{marginBottom: '12px'}}>
                <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                    <Form.Item labelCol={{span: 12}} label={'Terrorism / Terrorism Financing'}>
                        <Input disabled value={getScore('c_terrorism', item.terrorism)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                    <Tag
                        color={getContactsColor(3, item.terrorism)}>{getContactsLabel(3, item.criminalRecord)}</Tag>
                </Col>
                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                    {getContactsScore(3, item.terrorism)}
                </Col>
            </Row><Divider/></>)}
            <Modal title={`Change Contact Risk of #ID - ${componentState.activeContactId}`}
                   open={componentState.isModalOpen}
                   onOk={() => riskForm.submit()}
                   onCancel={() => {
                setComponentState(prev => ({...prev, isModalOpen: false, activeContactId: null}))
            }}>
                <Form form={riskForm} name={'contact-risk-form'} onFinish={handleContactRiskSubmit}>
                    <Divider />
                    {COMPANY_SCREEN_OUTCOMES.map(item => (
                        <Form.Item label={item.label} name={item.formKey} rules={[{required: true, message: 'Fields cannot be empty'}]}>
                            <Select placeholder={'-- SELECT --'} options={item.options.map(i => ({
                                ...i,
                                label: i.label,
                                value: i.value
                            }))}></Select>
                        </Form.Item>
                    ))}
                </Form>
            </Modal>

    </>)
}

const mapStateToProps = store => {
    return {
        app_state: store.app_state
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientRiskCompany);