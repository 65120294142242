import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {API} from 'aws-amplify';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
import Dvr from '@material-ui/icons/Dvr';
import Button from 'components/CustomButtons/Button.jsx';
import {Button as ButtonAntD, Flex, Spin, Table} from 'antd'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import matchSorter from 'match-sorter';
import {AiOutlineSearch} from 'react-icons/ai';
import moment from 'moment';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Close from '@material-ui/icons/Close';
import ListTable from '../ListTable/ListTable'
import { HoldingAccountAPI } from './HoldingAccountConstants'
import {DownloadOutlined} from "@ant-design/icons";
import {CSVLink} from "react-csv";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - IdentificationList
 * ----------------------------------------------------------------------------
 */
class HoldingAccountLineItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      line_item_list: [],
      dialog_item_detail_open: false,
      expandedRecord: {},
        dataToDownload: []
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    if (this.props.holding_account_id) {
      this.fetchHoldingAccountLineItems(this.props.holding_account_id);
    }
  }

  fetchHoldingAccountLineItems = async (holdingAccountId) => {
      console.log(holdingAccountId);
    this.setState({line_item_list: []})
    API.get('holding_accounts', `/get/line_items/${holdingAccountId}`)
      .then(response => {
        this.setState({
          line_item_list: response.line_item_list,
          holding_account: response.holding_account[0]
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  buildTableData() {
    // console.log(this.state.line_item_list)
    const line_item_list = this.state.line_item_list.sort((a, b) => {
      return moment(a.transaction_datetime).unix() - moment(b.transaction_datetime).unix() || a.id - b.id;
    });
    // console.log(line_item_list)

    let runningBalance = 0;
    let lineItemListWithBalance = line_item_list.map((prop, key) => {
      runningBalance = runningBalance - prop.debit + prop.credit;
      return {
        id: prop.id,
        deduction: prop.credit == null || prop.credit === 0 ? 'debit' : 'credit',
        transaction_datetime: prop.transaction_datetime != null ? moment(prop.transaction_datetime).format('DD/MM/YYYY') : '-',
        memo: prop.memo,
        holding_account_id: prop.holding_account_id,
        link_transfer: prop.link_transfer,
        debit: prop.debit,
        credit:prop.credit,
        balance: runningBalance,
        originalBalance: runningBalance,
      }
    })
    lineItemListWithBalance = lineItemListWithBalance.reverse();

    if (lineItemListWithBalance.length > 0) {
      // let balance = 0;
      return lineItemListWithBalance.map((prop, key) => {
        // balance = balance - prop.debit + prop.credit;
        const link = `/holding-accounts/edit/${prop.id}/${prop.deduction}/${prop.holding_account_id}`;
        return {
          id: prop.id,
          transaction_datetime: prop.transaction_datetime,
          memo: prop.memo,
          link_transfer: prop.link_transfer,
          debit: prop.debit,
          credit: prop.credit,
          // balance: balance,
          balance: prop.balance,
          receipt: prop.id,
          actions: (
            <div className='actions-right'>
              {/* use this button to remove the data row */}
                <ButtonAntD onClick={() => this.props.history.push(link)}><i className="fa fa-edit"></i></ButtonAntD>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  const data = this.state.line_item_list;
                  // eslint-disable-next-line no-unused-vars
                  data.find((o, i) => {
                    if (o.id === prop.id) {
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      // data.splice(i, 1);

                      this.setState({
                        dialog_delete_confirm: true,
                        dialog_delete_item: prop.id,
                        // dialog_delete_item_name: `${prop.nickname}`
                      });

                      return true;
                    }
                    return false;
                  });
                  this.setState({line_item_list: data});
                }}
                color='danger'
                className='remove'
              >
                <Close />
              </Button>{' '}
            </div>
          )
        };
      });
    }
  }

  dialog_item_detail_open = async e => {
    await API.get('holding_accounts', `/get/line_item/${e.target.id}`)
      .then(response => {
        // console.log(this.props.holding_account_id);
        console.log(response);
        this.setState({
          line_item: response.line_item[0],
          dialog_item_detail_open: true,
          link_transfer: response.line_item[0].link_transfer ? (
            <React.Fragment>
              <a href={`/transfers/edit/${response.line_item[0].link_transfer}`}>Click here</a> to view transfer
            </React.Fragment>
          ) : null,
          link_payout: response.line_item[0].link_payout ? (
            <React.Fragment>
              <a href={`/transfers/receipt/transfer/${response.line_item[0].link_payout}`}>Click here</a> to view payout
              receipt
            </React.Fragment>
          ) : null
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  dialog_item_detail_close = () => {
    this.setState({
      dialog_item_detail_open: false,
      line_item: {}
    });
  };

  dialog_delete_click = item_to_delete => {
    this.setState({dialog_delete_confirm: false});
    API.put('holding_accounts', `/line_item/update/${item_to_delete}`, {body: {deleted: true}})
      .then(response => {
        console.log(response);
        this.fetchHoldingAccountLineItems(this.props.holding_account_id);
        // this.props.fetchBeneficiaryList();
        // dispatch(receiveStaffList(response));
      })
      .catch(error => {
        console.log(error);
        this.fetchHoldingAccountLineItems(this.props.holding_account_id);
        // quickfix because of the api bug, so it returns api error because client talks to old database
        // this.props.fetchBeneficiaryList();
      });
  };

  dialog_delete_close = () => {
    this.setState({
      dialog_delete_confirm: false,
      dialog_delete_item: 0,
      dialog_delete_item_name: ''
    });
  };

  getFilter = (filter, onChange) => {
    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </Fragment>
    );
  };

  buildColumns = () => {
      return [
        {
          title: 'ID',
          align: 'right',
          dataIndex: 'id',
          key: 'id',
          width: 100,
          sorter: (a, b) => a.id - b.id,
        },
        {
          title: 'Date / Time',
          align: 'right',
          dataIndex: 'transaction_datetime',
          key: 'transaction_datetime',
          width: '150px',
          ...this.props.getColumnSearchProps({
            dataIndex: 'transaction_datetime',
            label: 'Date Time',
            filterInputType: 'DATE',
            render: (text, record) => <>{text}</>
          })
        },
        {
          title: 'Transaction Details',
          align: 'left',
          dataIndex: 'memo',
          key: 'memo'
        },
        {
          title: 'Debit',
          align: 'right',
          dataIndex: 'debit',
          key: 'debit',
          render: (text, record) => {
            if (record.debit > 0) {
              const displayDebit = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: this.state.holding_account.currencies_short_name
            }).format(text)
              return <div style={{textAlign: 'right', color: 'red'}}>{displayDebit}
              </div>
            }
          }
        },
        {
          title: 'Credit',
          align: 'right',
          dataIndex: 'credit',
          render: (text, record) => {
            if (record.credit > 0) {
              const displayCredit = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: this.state.holding_account.currencies_short_name
            }).format(text)
              return <div style={{textAlign: 'right', color: 'black'}}>{displayCredit}
              </div>
            }
          }
        },
        {
          title: 'Balance',
          align: 'right',
          dataIndex: 'balance',
          key: 'balance',
          render: (text, record) => {
            const displayBalance = new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: this.state.holding_account.currencies_short_name
            }).format(text)
              return <div style={{textAlign: 'right', color: record.balance > 0 ? 'black': 'red'}}>
                {displayBalance}
              </div>
            }
        },
        // {
        //   title: () => (
        //     <div
        //       style={{
        //         textAlign: 'right',
        //         paddingRight: 8
        //       }}
        //     >
        //       Docs
        //     </div>
        //   ),
        //   dataIndex: 'receipt',
        //   key: 'receipt',
        //   render: (text, record) => {
        //     if (record.credit > 0) {
        //       return <div style={{textAlign: 'right'}}>
        //           <ButtonAntD
        //               size='small'
        //               type='primary'
        //               onClick={() => this.props.history.push(`/holding-accounts/receipt/${text}`)}
        //               target='_blank'
        //           >
        //               Receipt
        //           </ButtonAntD>
        //       </div>
        //       }
        //   }
        // },
        {
          title: 'Actions',
          align: 'right',
          width: 120,
          dataIndex: 'actions',
          render: text => (text != null ? <div style={{textAlign: 'right'}}>{text}</div> : '-')
        }
      ];
  }

  onRowExpand = (event, record) => {
      if (event === true) {
          if (this.state.expandedRecord[`row_${record.id}`] != null) {
              if (this.state.expandedRecord[`row_${record.id}`].status === 'ERROR') {
                  this.fetchAccountHoldingLineItemDetail(record);
              }
          } else {
              this.fetchAccountHoldingLineItemDetail(record);
          }
      }
  }

  fetchAccountHoldingLineItemDetail = (record) => {
      const identifier = `row_${record.id}`;
      this.setState({expandedRecord: {...this.state.expandedRecord, [identifier]: {status: 'LOADING'}}});
      HoldingAccountAPI.fetchHoldingAccountLineItem(record.id)
          .then(response => {
            console.log(response)
              const data = {...this.state.expandedRecord, [identifier]: {
                      status: 'LOADED',
                      line_item: response.line_item[0],
                      link_transfer: response.line_item[0].link_transfer ? (
                          <React.Fragment>
                              <a href={`/transfers/edit/${response.line_item[0].link_transfer}`}>Click here</a> to view transfer
                          </React.Fragment>
                      ) : null,
                      link_payout: response.line_item[0].link_payout ? (
                          <React.Fragment>
                              <a href={`/transfers/receipt/transfer/${response.line_item[0].link_payout}`}>Click here</a> to view payout
                              receipt
                          </React.Fragment>
                      ) : null,
                      link_receipt: response.line_item[0].credit ? (
                          <React.Fragment>
                            <ButtonAntD
                              size='small'
                              type='primary'
                              onClick={() => this.props.history.push(`/holding-accounts/receipt/${response.line_item[0].id}`)}
                              target='_blank'
                            >
                                Receipt
                            </ButtonAntD>
                          </React.Fragment>
                      ) : null
                  }}
              this.setState({expandedRecord: data});
          })
          .catch(error => {
              console.log(error);
              this.setState({expandedRecord: {...this.state.expandedRecord, [identifier]: {status: 'ERROR'}}});
          });
  }

  download = event => {
      const currentRecords = this.state.line_item_list.map(v => {
          const {actions, balance, ...rest} = v;
          rest.transaction_datetime = moment(rest.transaction_datetime).format('DD/MM/YYYY');
          return rest;
      });
      const columns = this.buildColumns().filter(i => !['actions', 'balance'].includes(i.dataIndex));
      console.log(columns);
      const data_to_download = [];
      for (let index = 0; index < currentRecords.length; index++) {
          let record_to_download = {};
          for (let colIndex = 0; colIndex < columns.length; colIndex++) {
              record_to_download[columns[colIndex].title] = currentRecords[index][columns[colIndex].dataIndex];
          }
          data_to_download.push(record_to_download);
      }
      this.setState({dataToDownload: data_to_download}, () => {
          // click the CSVLink component to trigger the CSV download
          this.csvLink.link.click();
      });
  };
  render() {
      const columns = this.buildColumns();
    return (
      <React.Fragment>
          <Flex justify={'end'}>
              <ButtonAntD type={'primary'} icon={<DownloadOutlined />} onClick={() => this.download()}>Download CSV</ButtonAntD>
          </Flex>
        <Table
          size={'small'}
          rowKey={row => row.id}
          dataSource={this.buildTableData()}
          columns={columns}
          expandable={{
              onExpand: (event, record) => {this.onRowExpand(event, record)},
              expandedRowRender: (record) => {
                    const data = this.state.expandedRecord[`row_${record.id}`];
                    return <>
                        {data != null ? (
                            data.status === 'LOADED'
                            ? <table style={{maxWidth: 500}}>
                                    <tr>
                                        <th style={{textAlign: 'left'}}>Transaction Date</th>
                                        <td>{moment(data.line_item.transaction_datetime).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <th style={{textAlign: 'left'}}>Memo</th>
                                        <td>{data.line_item.memo}</td>
                                    </tr>
                                    <tr>
                                        <th style={{textAlign: 'left'}}>Internal Description</th>
                                        <td>{data.line_item.memo_internal}</td>
                                    </tr>
                                    {data.link_transfer && (
                                        <tr>
                                            <th style={{textAlign: 'left'}}>Transfer</th>
                                            <td>{data.link_transfer}</td>
                                        </tr>
                                    )}
                                    {data.link_payout && (
                                        <tr>
                                            <th style={{textAlign: 'left'}}>Payout</th>
                                            <td>{data.link_payout}</td>
                                        </tr>
                                    )}
                                    {data.link_receipt && (
                                        <tr>
                                            <th style={{textAlign: 'left'}}>Receipt</th>
                                            <td>{data.link_receipt}</td>
                                        </tr>
                                    )}
                                    {data.line_item.debit > 0 && (
                                        <tr>
                                            <th style={{textAlign: 'left'}}>Debit</th>
                                            <td>
                                                {new Intl.NumberFormat('en-GB', {
                                                    style: 'currency',
                                                    currency: this.state.holding_account.currencies_short_name
                                                }).format(data.line_item.debit)}
                                            </td>
                                        </tr>
                                    )}
                                    {data.line_item.credit > 0 && (
                                        <tr>
                                            <th style={{textAlign: 'left'}}>Credit</th>
                                            <td>
                                                {new Intl.NumberFormat('en-GB', {
                                                    style: 'currency',
                                                    currency: this.state.holding_account.currencies_short_name
                                                }).format(data.line_item.credit)}
                                            </td>
                                        </tr>
                                    )}
                                </table>
                                : (
                                    data.status === "LOADING"
                                        ? <><Spin/></>
                                        : <ButtonAntD type={'link'} danger={true} onClick={() => this.fetchAccountHoldingLineItemDetail(record)}><strong>Try again...</strong></ButtonAntD>
                                )
                        ) :  <Spin />}
                    </>
              },
              rowExpandable: (row) =>  row.id !== 'Not Expandable'
          }}
          loading={false}
        />
          <CSVLink data={this.state.dataToDownload} filename="data.csv" className={'hidden'} ref={r => (this.csvLink = r)} target={'_blank'}/>
        <Button 
          onClick={()=> this.fetchHoldingAccountLineItems(this.props.holding_account_id)}
          >Refresh
        </Button>

        <Dialog
          open={this.state.dialog_delete_confirm ? this.state.dialog_delete_confirm : false}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure that you wish to <strong>delete</strong> ledger record{' '}
              {this.state.dialog_delete_item}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.dialog_delete_close} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => this.dialog_delete_click(this.state.dialog_delete_item)}
              color='danger'
              autoFocus
            >
              Delete Record
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    );
  }
}

// export default withRouter(withStyles(styles)(IdentificationList));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    identification: state.identification
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const HoldingAccountLineItemListContainer = connect(mapStateToProps, mapDispatchToProps)(HoldingAccountLineItemList);

export default withRouter(withStyles(styles)(ListTable(HoldingAccountLineItemListContainer)));
