import {Alert, Button, Card, Col, Collapse, DatePicker, Divider, Flex, Form, Input, message, Row, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import {createRef, useEffect, useState} from "react";
import axios from "axios";
import Password from "antd/es/input/Password";
import {
    CheckCircleFilled,
    CheckCircleOutlined,
    CheckSquareOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    InfoOutlined
} from "@ant-design/icons";
import React from 'react';
import {API} from "aws-amplify";
import TypographyCountry from "../CurrencySelect/TypographyCountry";
import {useLocation, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {AppUtil} from "../../AppUtil";

const BOCRemit = (props) => {

    const [authenticationForm] = useForm();
    const [transferForm] = useForm();
    const inputTextArea = createRef();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [componentState, setComponentState] = useState({
        disableAuthForm: true,
        authResponse: 'IDLE',
        countries: [],
        currencies: [],
        sessionId: null,
        isTransferLoading: false,
        payoutSaving: false
    })


    const fetchAuthenticationDetails = () => {
        setComponentState(prev => ({...prev, authResponse: 'CONNECTING' }));
        axios.post("https://service-boc.fwwportal.com/boc-remit/authenticate", { type: 'AUTH' }).then(response => {
            const sessionId = response.data.result.requestSessionResult.split('|')[2];
            setComponentState(prev => ({...prev, authResponse: 'SUCCESS', sessionId: sessionId }));
        }).catch(err => {
            setComponentState(prev => ({...prev, authResponse: 'ERROR' }));
        })
    }
    const transferMoney = (values) => {
        setComponentState(prev => ({...prev, isTransferLoading: true}));
        const loading = message.loading("Please wait while amount request is being processed. Please wait..", 0);
        console.log(values);

        const sender = {
            sRemFirstName: values.sRemFirstName,
            sRemLastName: values.sRemLastName,
            sRemAddrStreet: values.sRemAddrStreet,
            sRemAddrCity: values.sRemAddrCity,
            sRemAddrCountry: values.sRemAddrCountry,
            sRemAddrZip: values.sRemAddrZip,
            sRemContact: values.sRemContact,
            sRemBirthDate: values.sRemBirthDate,
            sRemCountryOfOrigin: values.sRemCountryOfOrigin
        };

        const receiver = {
            sBeneFirstName: values.sBeneFirstName,
            sBeneMiddleName: values.sBeneMiddleName,
            sBeneLastName: values.sBeneLastName,
            sBeneAddrStreet: values.sBeneAddrStreet,
            sBeneAddrCity: values.sBeneAddrCity,
            sBeneAddrCountry: values.sBeneAddrCountry,
            sBeneAddrZip: values.sBeneAddrZip,
            sBeneContact: values.sBeneContact,
            sBeneBirthDate: values.sBeneBirthDate,
        }

        const transaction = {
            sTranProductType: 'Credit to Account',
            sTranSource: 'FRXWLD',
            sTranValueDate: dayjs().format('MM/DD/YYYY'),
            sTranProcessDate: dayjs().format('MM/DD/YYYY'),
            sTranPaymentMode: 1,
            sTranAcctNo: values.sTranAcctNo,
            sTranAmtOrg: values.sTranAmtOrg,
            sTranFXRate: values.sTranFXRate,
            sTranAmtDes: values.sTranAmtDes,
            sTranCcyOrg: 'PHP',
            sTranCcyDes: 'PHP',
            sTranMsgToBene: values.sTranMsgToBene,
            sTranCoveringBank: '066',
            sTranSendingBank: '066',
            sTranBankCode: values.sTranBankCode,
            sTranOrderingBank: '066',
            sTranChannel: values.sTranChannel
        }

        const mainPayload = {
            sRemDet: sender,
            sBeneDet: receiver,
            sTranDet: transaction,
            paymentId: queryParams.get("paymentID")
        }

        axios.post(`https://service-boc.fwwportal.com/boc-remit/send-transaction/${componentState.sessionId}`, mainPayload)
            .then(res => {
                message.success("Transfer sent");
                setComponentState(prev => ({ ...prev, isTransferLoading: false, sessionId: null, authResponse: 'IDLE' }));
                transferForm.resetFields();
                transferForm.setFieldValue("json_response", JSON.stringify(res.data, null, 2));
        }).catch(err => {
            message.error("Transfer Failed. Please try again..");
            setComponentState(prev => ({ ...prev, isTransferLoading: false }));
        }).finally(() => loading());

    }


    const prefillPaymentDetail = async (queryParams) => {
        const loading = message.loading("Filling client details. Please wait..", 0);
        try {
            const clientId = queryParams.get('clientID');
            const [client] = await API.post("commons", "/fetch", {
                body: {
                    context: 'clients',
                    fields: ['*'],
                    condition: {id: clientId}
                }
            });

            const bId = queryParams.get('beneficiaryID');

            const [beneficiary] = await API.post("commons", "/fetch", {
                body: {
                    context: 'beneficiaries',
                    fields: ['*'],
                    condition: {id: bId}
                }
            });

            const transferId = queryParams.get('transferID');

            const [transfer] = await API.post("commons", "/fetch", {
                body: {
                    context: 'transfers',
                    fields: ['*'],
                    condition: {id: transferId}
                }
            });

            const paymentId = queryParams.get("paymentID");

            const payments = await API.get('payments', `/id/${paymentId}`, {});

            const {firstName, middleName, lastName} = AppUtil.getSplitNames(beneficiary.ben_legal_name);
            const beneficiaryPayload = {
                sBeneFirstName: firstName,
                sBeneLastName: lastName,
                sBeneMiddleName: middleName,
                sBeneAddrStreet: `${beneficiary.ben_address_line_1} ${beneficiary.ben_address_line_1}`,
                sBeneAddrCity: beneficiary.ben_address_suburb,
                sBeneAddrCountry: componentState.countries.find(item => beneficiary.ben_address_country === item.id).full_name,
                sBeneAddrZip: beneficiary.ben_address_postcode,
                sBeneContact: beneficiary.ben_telephone_mobile
            }

            const senderPayload = {
                sRemFirstName: client.first_name,
                sRemLastName: client.last_name,
                sRemAddrStreet: client.residential_street_line_1 + " " + client.residential_street_line_2,
                sRemAddrCity: client.residential_street_suburb,
                sRemAddrCountry: componentState.countries.find(item => item.id === client.residential_street_country).full_name,
                sRemAddrZip: client.residential_street_postcode,
                sRemContact: client.telephone_mobile,
                sRemBirthDate: dayjs(client.date_of_birth).format('MM/DD/YYYY'),
                sRemCountryOfOrigin: componentState.countries.find(item => item.id === client.place_of_birth_country ).iso_alpha_3.toLowerCase(),
            }

            const transactionPayload = {
                sTranAcctNo: beneficiary.account_number,
                sTranAmtOrg: transfer.amount_from,
                sTranFXRate: transfer.client_rate,
                sTranAmtDes: payments.amount_to,
                sTranCcyOrg:  componentState.currencies.find(i => i.id === transfer.currency_from_id).iso_alpha_3,
                sTranCcyDes: componentState.currencies.find(i => i.id === transfer.currency_to_id).iso_alpha_3,
                sTranMsgToBene: payments.purposeOfPayment,
                sTranCoveringBank: 'National Westminster Bank',
                sTranOrderingBank: 'National Westminster Bank'
            }
            transferForm.setFieldsValue({
                ...beneficiaryPayload,
                ...senderPayload,
                ...transactionPayload,
                transfer_id: transferId,
                payment_id: payments.id
            });


        } catch (e) {
            console.log(e);
            message.error("Unable to prefill payments. Please fix client details");
        } finally {
            loading();
        }

    }

    const initialize = async () => {
        const [countries, currencies] = await Promise.all(
            [API.post("commons", "/fetch", {
                body: {
                    context: 'countries',
                    fields: ['*']
                }
            }),
            API.post("commons", "/fetch", {
                body: {
                    context: 'currencies',
                    fields: ['*']
                }
            })]
        );
        setComponentState(prev => ({...prev, countries: countries, currencies: currencies}));
    }

    useEffect(() => {
       if (componentState.countries.length > 0) {
           // fetchAuthenticationDetails();
           if (queryParams.get('paymentID') != null) {
               prefillPaymentDetail(queryParams);
           }
       }
    }, [componentState.countries]);

    const savePayoutResponse = () => {
        setComponentState(prev => ({...prev, payoutSaving: true }));
        const loading = message.loading("Saving payout. Please wait...", 0);
        const formValues = transferForm.getFieldsValue();
        console.log(formValues);
        loading();
    }

    useEffect(() => {
        initialize();
    }, [0]);
    return (
        <>
            <Card title={'Bank of Commerce (Philippines) Money Transfer'}>
                <Form name={'authentication-form'}
                      labelCol={{ span: 3 }}
                      labelAlign={'left'}
                      form={authenticationForm}
                      onFinish={fetchAuthenticationDetails}>
                    <Flex justify={'start'}>
                        <Form.Item>
                            <Button disabled={componentState.authResponse === 'SUCCESS'}
                                    loading={componentState.authResponse === 'CONNECTING'}
                                    htmlType={'submit'} type={'primary'} icon={componentState.sessionId ? <CheckCircleOutlined /> : null}>Check Connection</Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type={'link'} onClick={() => fetchAuthenticationDetails()}>Retry</Button>
                        </Form.Item>
                    </Flex>
                </Form>
                {
                    componentState.authResponse === 'SUCCESS'
                    ? <Alert message={'Authentication Successful. Please Proceed with Transaction'} showIcon={true} icon={<CheckCircleFilled />} type={'success'}/>
                        : (componentState.authResponse === 'ERROR' ? <Alert message={'Connection failure. Please try again or contact support.'} showIcon={true} icon={<ExclamationCircleOutlined />} type={'error'}/>
                            : <Alert message={'Please check connection before proceeding with transactions.'} showIcon={true} icon={<InfoCircleOutlined />} type={'info'}/>)
                }

                <Divider />

                <Form initialValues={{ sTranChannel: "CTO" }} labelCol={{span: 5}} labelAlign={'left'} name={'transfer-form'} disabled={componentState.authResponse === 'ERROR' || componentState.authResponse === 'IDLE' } form={transferForm}
                      onFinish={transferMoney}>
                    <Collapse
                        defaultActiveKey={1}
                        items={[{
                            key: '1',
                            label: 'Sender Details / Beneficiary Details',
                            children: <>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                        <h3>Sender Details</h3>
                                        <Divider />
                                        <Form.Item name={'sRemFirstName'} label={'First Name'} rules={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sRemLastName'} label={'Last Name'} rules={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sRemAddrStreet'} label={'Street Address'} rules={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sRemAddrCity'} label={'City'} rules={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sRemAddrCountry'} label={'Country'} rules={[{required: true, message: 'Field is required'}]}>
                                            <Select allowClear={true}
                                                    showSearch={true}
                                                    optionFilterProp={'alt'}
                                                    options={componentState.countries.map(i => ({
                                                        label: <TypographyCountry
                                                            iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>,
                                                        alt: i.full_name,
                                                        value: i.full_name
                                                    }))}></Select>
                                        </Form.Item>
                                        <Form.Item name={'sRemAddrZip'} label={'ZIP Code'} rules={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sRemContact'} label={'Contact'} rules={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sRemBirthDate'} label={'Birth Date'}
                                                   normalize={(e) => e && dayjs(e).startOf('day').format('MM/DD/YYYY')}
                                                   getValueProps={(value) =>
                                                       value && {value: dayjs(value, 'DD/MM/YYYY').startOf("day")}
                                                   }>
                                            <DatePicker format={'DD/MM/YYYY'} />
                                        </Form.Item>
                                        <Form.Item name={'sRemCountryOfOrigin'} label={'Country of Origin'} rules={[{required: true, message: 'Field is required'}]}>
                                            <Select allowClear={true}
                                                    showSearch={true}
                                                    optionFilterProp={'alt'}
                                                    options={componentState.countries.map(i => ({
                                                        label: <TypographyCountry
                                                            iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>,
                                                        alt: i.full_name,
                                                        value: i.full_name
                                                    }))}></Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                        <h3>Beneficiary Details</h3>
                                        <Divider/>
                                        <Form.Item name={'sBeneFirstName'} label={'First Name'} required={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sBeneMiddleName'} label={'Middle Name'} required={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sBeneLastName'} label={'Last Name'} required={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sBeneAddrStreet'} label={'Street Address'} required={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sBeneAddrCity'} label={'City'} required={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sBeneAddrCountry'} label={'Country'} required={[{required: true, message: 'Field is required'}]}>
                                            <Select allowClear={true}
                                                    showSearch={true}
                                                    optionFilterProp={'alt'}
                                                    options={componentState.countries.map(i => ({
                                                        label: <TypographyCountry
                                                            iso_alpha_2={i.iso_alpha_2}>{i.full_name}</TypographyCountry>,
                                                        alt: i.full_name,
                                                        value: i.full_name
                                                    }))}></Select>
                                        </Form.Item>
                                        <Form.Item name={'sBeneAddrZip'} label={'ZIP Code'} required={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'sBeneContact'} label={'Contact'} required={[{required: true, message: 'Field is required'}]}>
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        }]}
                    />
                    <Divider />
                    <Collapse
                        defaultActiveKey={3}
                        items={[{
                            key: '3',
                            label: 'Banking Details',
                            children: <>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                        <Form.Item name={'sTranAcctNo'} label={'Account Number'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'sTranAmtOrg'} label={'Amount From'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'sTranFXRate'} label={'Exchange Rate'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'sTranAmtDes'} label={'Destination Amount'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'sTranMsgToBene'} label={'Purpose of Payment'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'sTranChannel'} label={'Transaction Channel'}>
                                            <Select defaultValue={'CTA'} options={[
                                                {label: 'CTO', value: 'CTO'},
                                                {label: 'CTA', value: 'CTA'},
                                                {label: 'CPU', value: 'cpu'},
                                                {label: 'pNET', value: 'pnet'},
                                            ]}></Select>
                                        </Form.Item>
                                        <Form.Item name={'sTranBankCode'} label={'Bank Code'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'sTranCcyDes'} hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'sTranCcyOrg'} hidden>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                        <Form.Item name={'json_response'} label={'Server Response'}>
                                            <Input.TextArea ref={inputTextArea} size={'large'}></Input.TextArea>
                                        </Form.Item>
                                        <Form.Item name={'transfer_id'} label={'Transfer ID'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'payment_id'} label={'Payment ID'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'sTranCoveringBank'} label={'Source Bank'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'sTranOrderingBank'} hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'bank_confirmation'} label={'Bank Confirmation'}>
                                            <Input />
                                        </Form.Item>
                                        <Button type={'primary'} loading={componentState.payoutSaving} onClick={() => savePayoutResponse()}>Save Payout</Button>
                                    </Col>
                                </Row>
                            </>
                        }]}
                    />
                    <Form.Item style={{ marginTop: 10 }}>
                        <Button type={'primary'} size={'large'} htmlType={'submit'} loading={componentState.isTransferLoading}>Transfer</Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}

export default BOCRemit;