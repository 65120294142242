// import {Card, CardHeader} from '@material-ui/core';
import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import {API} from 'aws-amplify';
import {NavLink} from 'react-router-dom';

import Card from '../../Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader';
import CardBody from '../../Card/CardBody.jsx';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import Button from '../../CustomButtons/Button';

import {Col, Divider, Input, message, Modal, Row, Space, Table, Tag} from 'antd'

import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import { AppUrlConstants } from '../../../AppAPI'
import { BeneficiaryAPI } from '../../Beneficiaries/BeneficiaryUtils'
import {CheckSquareOutlined, CloseSquareOutlined} from "@ant-design/icons";
import FileUploader from "../../Uploaders/FileUploader";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class Beneficiaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_list: [],
      currencies_list: [],
      beneficiaryList: []
    };
  }

  componentDidMount() {
    AppUrlConstants.fetchCurrencies().then(response => {
        this.setState({currencies_list: response});
    }).catch(error => {
        console.log(error);
      });
    this.fetchBeneficiaryList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
      this.fetchBeneficiaryList()
    }
  }

  fetchBeneficiaryList = () => {
    if (!this.props.client_detail_id) {
      this.setState({beneficiaryList: []});
    } else {
      const clientID = this.props.client_detail_id
      API.get('beneficiaries', `/get/by_client_id/${clientID}`)
      .then(response => {
        console.log(response);

        let riskTotal = 0
        let beneficiaryTotal = response.length
        let currencyList = []
        let currencySelectList = []
        for (let i = 0; i < response.length; i++) {
          const element = response[i];
          if (currencyList.indexOf(element.account_currency_iso_alpha_3) === -1) {
            currencyList.push(element.account_currency_iso_alpha_3)
            currencySelectList.push({text: element.account_currency_iso_alpha_3, value: element.account_currency_iso_alpha_3})
          }
          riskTotal += element.riskRating
        }
        this.setState({
          beneficiaryList: response,
          currencySelectList: currencySelectList.sort((a, b) => (a.text || '').localeCompare(b.text)),
          riskTotal: riskTotal,
          beneficiaryTotal: beneficiaryTotal,
        });
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  onSearch = (value) => {
    console.log(value)
    if (!value) {
      this.fetchBeneficiaryList()
      return
    }
    const beneficiaryList = this.state.beneficiaryList
    const filteredList = beneficiaryList.filter((item) => {
      if (item.nickname && (item.nickname || '').toLowerCase().includes(value.toLowerCase())) {
        return true
      }
    })
    this.setState({beneficiaryList: filteredList})
  }

    toggleActive = (beneficiaryId, activeId) => {
        message.info(`Toggling beneficiary to ${activeId === 0 ? 'Inactive' : 'Active'}...`).then(() => {
            BeneficiaryAPI.toggleBeneficiaryActive(beneficiaryId, activeId)
                .then(() => {
                    message.success(`Beneficiary ${activeId === 0 ? 'Inactive' : 'Active'}`);
                    this.fetchBeneficiaryList();
                })
                .catch(() => message.error("Unable to set beneficiary to active..Try again."));
        })
    }

  onViewDocumentsBtnClick = async (documents) => {
    const loading = message.loading("Loading beneficiary documents. Please wait..", 0);
    Modal.confirm({
      title: 'Beneficiary Documents',
      width: 600,
      content: <Row gutter={[16, 16]}>
        {documents.map(i => <>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <FileUploader previewOnly={true}
                          thumbType={'picture-card'}
                          uploaderType={'PDF'}
                          fetchFileList={() => {
                            const file = {
                              name: i,
                              uid: i,
                              status: 'done'
                            }
                            return Promise.resolve([file]);
                          }}></FileUploader>
          </Col>
        </>)}
      </Row>,
      cancelButtonProps: {hidden: true},
      maskClosable: true
    })
    loading();
  }


  render() {

    const columns = [
      {
        title: 'Currency',
        dataIndex: 'account_currency_iso_alpha_3',
        key: 'account_currency_iso_alpha_3',
        filters: this.state.currencySelectList,
        filterSearch: true,
        onFilter: (value, record) => record.account_currency_iso_alpha_3 && record.account_currency_iso_alpha_3.startsWith(value),
        render: (text) => {
          // const currentRecord = this.state.currencies_list.find((currency) => currency.id === text);
          const link = `https://wise.com/public-resources/assets/flags/rectangle/${text ? text.toLowerCase() : ''}.png`
          return (
            <>
            <img src={link} style={{height: 16, width: 16, verticalAlign: 'middle', marginBottom: '4px'}}/>
              &nbsp;
              {text}
              </>
            )},
          sorter: (a, b) => a.account_currency_iso_alpha_3 && (a.account_currency_iso_alpha_3 || '').localeCompare(b.account_currency_iso_alpha_3)
        ,
      },
      {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        render: (text, record) => {
          // console.log(record)
            switch (record.active) {
                case 1:
                    return <Tag color='success' style={{ width: 70, textAlign: "center", cursor: 'pointer' }} onClick={() => this.toggleActive(record.id, 0)}>Active</Tag>
                default:
                    return <Tag color='error' style={{ width: 70, textAlign: "center", cursor: 'pointer' }} onClick={() => this.toggleActive(record.id, 1)}>Inactive</Tag>
            }
        },
      },
      {
        title: 'Beneficiary',
        dataIndex: 'nickname',
        key: 'nickname',
        render: (text, record) => {
          return (
            <>
              <strong>{text}</strong><br/>
              {record.ben_address_line_1} <br/>{record.ben_address_suburb} {record.ben_address_state} {record.ben_address_postcode}
            </>
            )
        },
        sorter: (a, b) => (a.nickname || '').localeCompare(b.nickname),
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Beneficiary Bank',
        dataIndex: 'bank_legal_name',
        key: 'bank_legal_name',
        render: (text, record) => {
          return (
            <>
              <strong>{text}</strong><br/>
              {record.bank_address_line_1} <br/>{record.bank_address_suburb} {record.bank_address_state} {record.bank_address_postcode}
            </>
            )
        },
      },
      {
        title: 'Account Details',
        dataIndex: 'account_number',
        key: 'account_number',
        render: (text, record) => {
          return (
            <>
              {record.bank_address_country_full_name ? <span><strong>{record.bank_address_country_full_name}</strong><br/></span> : ''}
              <Divider style={{marginTop: 4, marginBottom: 4}}/>
              {record.swift_code ? <span><strong>SWIFT: </strong>{record.swift_code}<br/></span> : ''}
              {record.bsb_code ? <span><strong>BSB: </strong>{record.bsb_code}<br/></span> : ''}
              {record.account_number ? <span><strong>Account: </strong>{record.account_number}<br/></span> : ''}
              {record.iban ? <span><strong>IBAN: </strong>{record.iban}<br/></span> : ''}
              {record.aba_routing_number ? <span><strong>ABA: </strong>{record.aba_routing_number}<br/></span> : ''}
              {record.sort_code ? <span><strong>Sort: </strong>{record.sort_code}<br/></span> : ''}
            </>
            )
        },
      },
      {
        title: 'Docs',
        key: 'documents',
        dataIndex: 'documents',
        filters: [{text: 'Yes', value: true}, {text: 'No', value: false}],
        onFilter: (value, record) => {
          return value === record.documents.length > 0
        },
        filterSearch: true,
        render: (text, record) => {
          if (record.documents && record.documents.length > 0) {
            return <Tag style={{ cursor: 'pointer'}} onClick={() => this.onViewDocumentsBtnClick(record.documents)} icon={<CheckSquareOutlined />} color={'success'}></Tag>
          }
          return <Tag color={'orange'} icon={<CloseSquareOutlined />}></Tag>;
        },
      },
      {
        title: 'Risk',
        dataIndex: 'riskRating',
        key: 'riskRating',
        render: (text, record) => {
          // console.log(record)
          switch (record.riskRating) {
            case 1:
              return <Tag color='success' style={{width: 100, textAlign:"center"}}>Low</Tag>
              break;
            case 2:
              return <Tag color='warning' style={{width: 100, textAlign:"center"}}>Medium</Tag>
              break;
            case 3:
              return <Tag color='error' style={{width: 100, textAlign:"center"}}>High</Tag>
              break;
          }
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          return (
          <Space size="middle">
            <NavLink
              to={`/beneficiaries/edit/${record.id}`}
            >
              View
            </NavLink>
          </Space>
          )
        }
      }
    ];
  
    const riskScore = this.state.riskTotal / this.state.beneficiaryTotal;
    let riskTag;
    if (riskScore <=1) {
      riskTag = <Tag color='success' style={{width: 100, textAlign:"center"}}>Low</Tag>
    } else if (riskScore <=2) {
      riskTag = <Tag color='warning' style={{width: 100, textAlign:"center"}}>Medium</Tag>
    } else {
      riskTag = <Tag color='error' style={{width: 100, textAlign:"center"}}>High</Tag>
    }

    return (
      <Fragment>
        <div style={{padding: 0}}>
        <Input.Search 
          placeholder="Search Beneficiary Name" enterButton style={{width: 300, marginBottom: 16}}
          allowClear
          onSearch={this.onSearch}
          />
        <Table 
          columns={columns} 
          dataSource={this.state.beneficiaryList}
          />
        <p>
          <strong>
          Client overall Beneficiary Risk Rating: 
          </strong>
          &nbsp;&nbsp;&nbsp;{riskTag}
          <br/>
          [{this.state.riskTotal} total risk score] &nbsp;/&nbsp; [{this.state.beneficiaryTotal} beneficiaries] &nbsp;=&nbsp; {this.state.riskTotal / this.state.beneficiaryTotal}
        </p>
        </div>
      </Fragment>
  
  )
  }
}


export default withRouter(withStyles(styles)(Beneficiaries));