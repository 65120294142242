import React from 'react';
import {connect} from 'react-redux';
import {API} from 'aws-amplify';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import { Button, message, Steps } from 'antd';
import Card from '../../Card/Card.jsx';

import Step1Client from './Step1Client';
import Step2Transfer from './Step2TransferPayingTo';
import Step4Confirm from './Step4Confirm';


import {withRouter} from 'react-router-dom';

import {
  change_current_step,
  clearNewTransfer,
  createNewTransfer, resetAddNewTransfer,
  updateNewTransferCreation,
  updateNewTransferCreationBeneficiary
} from 'redux/actions/transfers_dashboard_multiple';
import {startNewTransferCreation, resetTransferState} from 'redux/actions/transfers_2';
import {NewTransferAPI} from './NewTransferConstants';

import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
dayjs.extend(dayjsBusinessDays);

const moment = require('moment');

class NewTransferSteps extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      processing: false
    }
  }

  componentDidMount() {
    this.props.startNewTransferCreation();
    this.props.resetState();
  }

   next = () => {
     const current = this.state.current;
     this.setState({
       current: current + 1
     })
   };

  prev = () => {
    const current = this.state.current;
    this.setState({
      current: current - 1
    })
  };

  // getNextBusinessDay = (d, n) => {
  //   d = new Date(d.getTime());
  //   const day = d.getDay();
  //   d.setDate(d.getDate() + n + (day === 6 ? 2 : Number(!day)) + Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2);
  //   return d;
  // }

  finished = async () => {

    this.setState({ processing: true });

    const recordCreatedDatetimeLocal = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const transactionDatetimeLocal = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const transactionDateLocal = dayjs().format('YYYY-MM-DD');

    let set_date = dayjs().add(2, 'day');
    console.log('set_date', set_date)

    if (dayjs().day() === 6) {
      set_date = set_date.add(2, 'day');
    } else if (dayjs().day() === 0) {
      set_date = set_date.add(1, 'day');
    }

    let set_date_dayofweek = set_date.day();
    if (set_date_dayofweek === 6) {
      set_date = set_date.add(2, 'day');
    } else if (set_date_dayofweek === 0) {
      set_date = set_date.add(1, 'day');
    }
    console.log('adjusted set_date', set_date)
    const settlement_date = set_date.format('YYYY-MM-DD');
    console.log('settlement_date', settlement_date)


    // const set_date = this.getNextBusinessDay(new Date(), 2);
    // const settlement_date = set_date.toISOString().slice(0, 10).replace('T', ' ');
    // console.log('set_date', set_date)
    // console.log('settlement_date', settlement_date)
    // console.log(this.props.transfers_2.new_transfer_data);

    // if (this.props.transfers_2.new_transfer_data.payment_method === 'worldpay') {
    //   const url = await this.getWorldPayPaymentURL();
    //   window.open(url);
    // }

    let testClient = false;
    if (this.props.transfers_2.new_transfer_data.client_id == 55) {
      testClient = true;
    }

    let sms = ''
    sms = `${testClient ? '**TEST** ' : ''}Admin Portal New Transfer Submitted by ${this.props.app_state.current_staff.first_name}: Client ${this.props.transfers_2.new_transfer_data.client.nickname} transfers from ${this.props.transfers_2.new_transfer_data.currency_from.short_name} ${this.props.transfers_2.new_transfer_data.amount_from} at ${this.props.transfers_2.new_transfer_data.client_rate} to ${this.props.transfers_2.new_transfer_data.currency_to.short_name} ${this.props.transfers_2.new_transfer_data.amount_to}`

    let smsAll = ''
    if (this.props.app_state.current_staff.id == 168 || this.props.app_state.current_staff.id == 148 || this.props.app_state.current_staff.id == 102 || this.props.app_state.current_staff.id == 180) {
      smsAll = `${testClient ? '**TEST** ' : ''}Admin Portal New Transfer Submitted by ${this.props.app_state.current_staff.first_name}: Client ${this.props.transfers_2.new_transfer_data.client.nickname} transfers from ${this.props.transfers_2.new_transfer_data.currency_from.short_name} ${this.props.transfers_2.new_transfer_data.amount_from} at ${this.props.transfers_2.new_transfer_data.client_rate} to ${this.props.transfers_2.new_transfer_data.currency_to.short_name} ${this.props.transfers_2.new_transfer_data.amount_to}`
    }

    if (this.props.transfers_2.new_transfer_data.multiple_beneficiary_id &&
        this.props.transfers_2.new_transfer_data.multiple_beneficiary_id.length > 0 &&
        !(this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].amount)) {


    }

    await API.post('email', '/interaction/main', {
      body: {
        interaction: 'New Transfer Request',
        data: {
          staffID: this.props.app_state.current_staff.id,
          staffName: this.props.app_state.current_staff.nickname,
          client_id: this.props.transfers_2.new_transfer_data.client.id,
          client_nickname: this.props.transfers_2.new_transfer_data.client.nickname,
          record_created_datetime: recordCreatedDatetimeLocal,
          transaction_datetime: transactionDatetimeLocal,
          transaction_date: transactionDateLocal,
          settlement_date: settlement_date,
          status: '5',
          from: 'New Transfer (Steps)',
          forward_id: this.props.transfers_2.new_transfer_data.forward_id || null,
          fee_amount: this.props.transfers_2.new_transfer_data.fee_amount || null,
          fee_description: this.props.transfers_2.new_transfer_data.fee_description || null,
          purpose: 'Admin Portal New Transfer Submission',
          mult_ben_id: this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id,
          mult_ben_name: this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary ? this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary.nickname : 'nil',
          mult_ben_amount: this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].amount,
          ...this.props.transfers_2.new_transfer_data,
        },
        result: 'database submission in process',
        // sms,
        // smsAll
      }
    });

    // console.log(this.props.transfers_2.new_transfer_data)
    const message = `
      A new transfer has been created.<br/>
      <strong>Admin User: </strong>${this.props.app_state.current_staff.nickname}<br/>
      <strong>Client: </strong>${this.props.transfers_2.new_transfer_data.client.nickname}<br/>
      <strong>From Currency: </strong>${this.props.transfers_2.new_transfer_data.currency_from_name}<br/>
      <strong>From Amount: </strong>${this.props.transfers_2.new_transfer_data.amount_from}<br/>
      <strong>Rate: </strong>${this.props.transfers_2.new_transfer_data.client_rate}<br/>
      <strong>To Currency: </strong>${this.props.transfers_2.new_transfer_data.currency_to_name}<br/>
      <strong>To Amount: </strong>${this.props.transfers_2.new_transfer_data.amount_to}<br/>
      <strong>Fee Added: </strong>${this.props.transfers_2.new_transfer_data.fee_visible_amount} - ${this.props.transfers_2.new_transfer_data.fee_visible_description}<br/>
      <strong>Transaction Date: </strong>${dayjs(transactionDateLocal).format('DD/MM/YYYY')}<br/>
      <strong>Settlement Date: </strong>${dayjs(settlement_date).format('DD/MM/YYYY')}<br/>
      <hr/>
      <strong>Backend: </strong><a href="https://forexsport.com/fww/fwwadmin/new_requests.php">https://forexsport.com/fww/fwwadmin/new_requests.php</a><br/>
      <strong>Admin: <strong><a href="https://crm.forexworldwide.com/transfers/list">Transfer List</a><br/>
    `
    // console.log(message)
    await API.post('email', '/interaction/backoffice', {
      body: {
        testClient: testClient,
        staffID: this.props.app_state.current_staff.id,
        subject: `${testClient ? 'TEST TRANSFER - ' : ''}FWW Admin Portal: New Transfer Request Created`,
        message: message,
        sms: testClient ? sms : false,
        smsAll: testClient ? false : smsAll
      }
    });

    if (this.props.transfers_2.sendRemitEmail) {

    }

    const transferData = this.props.transfers_2.new_transfer_data;
    if (transferData != null && transferData.multiple_beneficiary_id != null && transferData.multiple_beneficiary_id.length) {
      transferData.multiple_beneficiary_id.forEach(i => {
        if (i.document && i.document.length > 0) {
          i.document = i.document.map(j => ({file_id: j.file_id}));
        }
      })
    }

    const transferResult = {
      ...transferData,
      nickname: `${this.props.transfers_2.new_transfer_data.amount_from} ${
        this.props.transfers_2.new_transfer_data.currency_from_name
      } to ${this.props.transfers_2.new_transfer_data.amount_to} ${
        this.props.transfers_2.new_transfer_data.currency_to_name
      } Settling ${dayjs(set_date).format('DD/MM/YYYY')}`,
      // record_created_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
      // transaction_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
      // transaction_datetime: transactionDateLocal,
      transaction_date: transactionDateLocal,
      settlement_date: settlement_date,
      client_id: this.props.transfers_2.new_transfer_data.client.id,
      staff_id: this.props.transfers_2.new_transfer_data.client.responsible_staff_member,
      beneficiary_ids: this.props.transfers_2.new_transfer_data.multiple_beneficiary_id,
      status: '5',
      creatingStaffID: this.props.app_state.current_staff.id,
    }

    NewTransferAPI.saveTransfer(transferResult)
      .then(database_result => {
        console.log('database_result', database_result);
        const transferID = database_result.payload.database_result.insertId
        console.log('transferID', transferID);
        if (this.props.transfers_2.new_transfer_data.sendRemitEmail) {
          console.log('Sending remittance email');
          API.post('email-18', `/client/remittance-instructions-nmail`, {
            body: {
              transferID: transferID,
              currentStaffID: this.props.currentStaffID,
            }
          })
            .then(response => {
              console.log('Email success: ', response);
              this.setState({
                // remittance_advice_email_sent: true,
                remittance_advice_email_sending: false,
                emailsent_remittance_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                emailsent_remittance_staff: `You (staff ID ${this.props.currentStaffID})`
              });
            })
            .catch(error => {
              console.log(error);
            });

        }

        console.log("Transfer create completed")
        this.props.history.push('/transfers/list');
      })
      .catch(error => console.log(error));
      this.setState({ processing: false });

  };

  render() {

    if (!this.props.app_state || !this.props.app_state.current_staff_id) {
      return null;
    }

    const description = '';

    const steps = [
      {
        title: 'Select Client',
        description,
      },
      {
        title: 'Create Transfer',
        description,
      },
      {
        title: 'Confirm',
        description,
      },
    ]

    let subtitle;
    if (this.props.transfers_2.new_transfer_data.client && this.props.transfers_2.new_transfer_data.client.nickname) {
      subtitle = <h3>Creating a New Transfer for {this.props.transfers_2.new_transfer_data.client.nickname}</h3>
    }

    return (
      <>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
          {subtitle}
        </GridItem>
      </GridContainer>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
          <hr/>
          <Steps
            current={this.state.current}
            items={steps}
          />
          <hr/>
        </GridItem>
      </GridContainer>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
          {this.state.current === 0 && (
            <Step1Client />
          )}
          {this.state.current === 1 && (
            <Step2Transfer />
          )}
          {this.state.current === 2 && (
            <Step4Confirm />
          )}
        </GridItem>
      </GridContainer>
      <GridContainer justify='right'>
        <GridItem xs={12} sm={12}>
          <div style={{float: 'right'}}>
        {/*{this.state.current > 0 && (*/}
        {/*  <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>*/}
        {/*    Previous*/}
        {/*  </Button>*/}
        {/*)}*/}
        {this.state.current > 0 && (
          <Button danger style={{ margin: '0 8px' }} onClick={() => window.location.reload()}>
            Restart
          </Button>
        )}
        {this.state.current < steps.length - 1 && (
          <Button type="primary" onClick={() => this.next()}>
            Next
          </Button>
        )}
        {this.state.current === steps.length - 1 && (
          <Button 
            type="primary" 
            loading={this.state.processing}
            onClick={() => {
            message.info('Submitting Transfer');
            this.finished();
            message.success('Processing Complete!');
          }}>
            Done
          </Button>
        )}
        </div>
        </GridItem>
      </GridContainer>
      
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_2: state.transfers_2,
    language: state.language,
    transfers_dashboard_multiple: state.transfers_dashboard_multiple
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewTransferCreation: () => {
      dispatch(startNewTransferCreation());
    },
    createNewTransfer: data => {
      dispatch(createNewTransfer(data));
    },
    change_current_step: step => {
      dispatch(change_current_step(step));
    },
    clearNewTransfer: () => {
      dispatch(clearNewTransfer());
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    updateNewTransferCreationBeneficiary: array => {
      dispatch(updateNewTransferCreationBeneficiary(array));
    },
    resetState: () => {
      dispatch(resetTransferState());
    }
  };
};

const NewTransferStepsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTransferSteps);

export default withRouter(NewTransferStepsContainer);
