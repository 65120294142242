import React, {Fragment, useEffect, useState} from 'react';
import Amplify, {API, Auth} from 'aws-amplify';
import dayjs from 'dayjs';
import {
    Button as AntButton,
    Card as AntCard,
    Checkbox,
    Col,
    DatePicker,
    Flex,
    Form,
    Input,
    message, Popconfirm,
    Row,
    Select, Switch
} from "antd";
import {useForm} from "antd/es/form/Form";
import {AppTemplates} from "../../AppTemplates";
import config from "../../config/awsConfig";
import generator from "generate-password";
import {AppUtil} from "../../AppUtil";

const ClientContractsEdit = ({classes, clientContactData, id}) => {

    const [clientContactForm] = useForm();
    const [countriesList, setCountriesList] = useState([]);
    const [selectedClient, setSelectedClient] = useState();
    const [clientList, setClientList] = useState();
    const [componentState, setComponentState] = useState({
        hasClientLogin: false,
        isFormLoading: false
    })

    const options = [
        {label: 'Pass', value: 1, score: 0},
        {label: 'Fail - Former PEP', value: 2, score: 8},
        {label: 'Fail - Domestic PEP', value: 3, score: 26},
        {label: 'Fail - Foreign PEP', value: 4, score: 26},
        {label: 'Fail - International Organisation PEP', value: 5, score: 26}
    ];
    const otherOpts = [
        {label: 'Pass', value: 6, score: 0},
        {label: 'Fail', value: 7, score: 26},
    ];


    const fetchClients = () => {
        API.get('clients', '/get-list-not-deleted')
            .then(response => {
                setClientList(response.map(item => ({label: item.nickname, value: item.id})));
            })
            .catch(error => {
                console.log(error);
                setClientList([]);
            });
    };


    const fetchTeamsInfo = team_id => {
        if (team_id) {
            API.get('teams', `/get/id/${team_id}`, {})
                .then(response => {
                    setSelectedClient(prevData => ({...prevData, team: response.nickname}));
                });
        }
    };

    const fetchClientInfo = client_id => {
        API.get('clients', `/get/id/${clientContactData.clientId}`, {})
            .then(response => {
                console.log(response);
                setSelectedClient(response);
                fetchTeamsInfo(response.team_id);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (!clientContactData) {
            return;
        }
        clientContactForm.setFieldsValue({...clientContactData, date_of_birth: dayjs(clientContactData.date_of_birth)});
        setComponentState(prev => ({...prev, hasClientLogin: clientContactData.aws_cognito_id != null}))
    }, [clientContactData]);

    useEffect(() => {
        fetchClients();
        fetchClientInfo();
        fetchCountries();
    }, []);

    const fetchCountries = () => {
        API.get('countries', '/list_not_deleted', {})
            .then(response => {
                setCountriesList(response);
            })
            .catch(error => {
                console.log(error);
                setCountriesList([]);
            });
    };

    const saveClientData = (clientContactData) => {
        const clientId = selectedClient && selectedClient.id ? selectedClient.id : clientContactData.clientId;
        API.put('clientcontacts', `/id/${id}`, {body: {clientId, ...clientContactData}})
            .then(response => {
                message.success("Client Contact saved..");
            })
            .catch(error => {
                console.log(error);
                message.error("Client contact saving failed.");
                API.put('clientcontacts', `/id/${id}`, {body: {clientId, ...clientContactData}})
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
    };


    const createClientLogin = async () => {
        const values = clientContactForm.getFieldsValue();
        if (values.email != null) {
            const loading = message.loading("Creating client login. Please wait..", 0);

            const payload = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: null
            }

            Amplify.configure({
                Auth: {
                    mandatorySignIn: true,
                    region: config.cognito_clients.REGION,
                    userPoolId: config.cognito_clients.USER_POOL_ID,
                    identityPoolId: config.cognito_clients.IDENTITY_POOL_ID,
                    userPoolWebClientId: config.cognito_clients.APP_CLIENT_ID
                }
            });
            const password = generator.generate({
                length: 8,
                numbers: true,
                symbols: true,
                lowercase: true,
                uppercase: true,
                strict: true
            });
            try {
                const awsResponse = await Auth.signUp({username: payload.email, password: password});
                setComponentState(prev => ({...prev, isFormLoading: true}));
                const signUpResponse = await API.post("clients", `/create/client-login/${clientContactData.clientId}`, {
                    body: {
                        ...payload,
                        awsCognitoID: awsResponse.userSub,
                        password,
                        clientContactId: clientContactData.id
                    }
                });
                message.success("Client Registered success");
                message.info("Verifying account. Please wait...");
                await API.post("clients", "/verify-client-login", {body: {user_client_id: signUpResponse.payload.data.dbResult.insertId}});
                await message.success("Login Verified...");

                await API.post("commons", `/update/${clientContactData.id}`, {
                    body: {
                        context: 'client_contacts',
                        data: {aws_cognito_id: awsResponse.userSub},
                    }
                })
                message.success("Client Login created. Please wait.");
                setComponentState(prev => ({...prev, isFormLoading: false, hasClientLogin: true}));
            } catch (error) {
                console.log(error);
                if (error.code && error.code === 'UsernameExistsException') {
                    message.error("An account with the given email already exists.");
                } else if (error.code && error.code === 'InvalidPasswordException') {
                    message.error("Password should contain 1 number 1 uppercase and 1 lower case with special character", 3);
                } else if (error.code && error.code === 'InvalidParameterException') {
                    message.error("Invalid..!! Email address not valid.");
                } else {
                    message.error("Failed to register client. Please try again..");
                }
                setComponentState(prev => ({...prev, isFormLoading: false}));
            } finally {
                Amplify.configure({
                    Auth: {
                        mandatorySignIn: true,
                        region: config.cognito_staff.REGION,
                        userPoolId: config.cognito_staff.USER_POOL_ID,
                        identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
                        userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
                    }
                });
                loading();
            }
        }
    }

    const changePassword = () => {
        const loading = message.loading("Resetting password. Please wait..", 0);
        const password = generator.generate({
            length: 8,
            numbers: true,
            symbols: true,
            lowercase: true,
            uppercase: true,
            strict: true
        });

        API.post('clients', '/clients/password/change', {
            body: {
                cognitoId: clientContactData.aws_cognito_id,
                newPassword: password
            }
        }).then((response) => {
            message.success("Password has been reset");
            API.post("email-18", "/client/reset/send-email", {
                body: {
                    clientContactId: clientContactData.id,
                    password: password
                }
            }).then(res => {
                message.success("Email Sent.");
            });
        }).catch(err => {
            console.log(err);
        }).finally(() => loading());
    }

    return (
        <Fragment>
            <div style={{position: 'relative', width: '100%', background: '#efefef', padding: '10px'}}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                        <Form form={clientContactForm} onFinish={saveClientData} labelCol={{span: 6}}>
                            {/*<Row gutter={[16, 16]} style={{marginBottom: '15px'}}>*/}
                            {/*    <Col xs={24} sm={24} md={24} xl={12} xxl={12}>*/}
                            {/*        <AntCard title={'Select Client'} size={'small'}>*/}
                            {/*            <Form.Item name={'clientId'} label={'Select Client'}>*/}
                            {/*                <Select showSearch={true} optionFilterProp={'label'} defaultValue={clientContactData && clientContactData.clientId}*/}
                            {/*                        options={clientList}></Select>*/}
                            {/*            </Form.Item>*/}
                            {/*            <span style={{ display: 'block' }}>Client : {selectedClient && selectedClient.nickname}</span>*/}
                            {/*            <span style={{ display: 'block' }}>Email : {selectedClient && selectedClient.email}</span>*/}
                            {/*            <span style={{ display: 'block' }}>Team : {selectedClient && selectedClient.team}</span>*/}
                            {/*        </AntCard>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                                    <AntCard title={'Contacts'} size={'small'} style={{ marginBottom: 10 }}>
                                        <Form.Item name={'firstName'} label={'First Name'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'lastName'} label={'Last Name'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'email'} label={'Email'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'telephone'} label={'telephone'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'position'} label={'Position'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'is_ben_owner'} label={'Benficial Owner'}
                                                   valuePropName={'checked'}>
                                            <Checkbox
                                                defaultChecked={clientContactData && clientContactData.is_ben_owner}/>
                                        </Form.Item>
                                        <Form.Item name={'ownership'} label={'Ownership'} rules={[
                                            {
                                                type: "number",
                                                min: 0,
                                                max: 100,
                                                transform: (value) => (value ? Number(value) : value),
                                                message: "Number must be between 0 and 100!",
                                            },
                                        ]}>
                                            <Input />
                                        </Form.Item>
                                    </AntCard>
                                    <AntCard title={'Address'} size={'small'} style={{ marginBottom: 10 }}>
                                        <Form.Item name={'address_line_1'} label={'Address Line 1'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'address_line_2'} label={'Address Line 2'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'suburb'} label={'suburb'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'state'} label={'state'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'postcode'} label={'Post Code'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'country_id'} label={'Country'}>
                                            <Select optionFilterProp={'alt'}
                                                    options={AppTemplates.countries(countriesList)}
                                                    placeholder={' -- SELECT --'}></Select>
                                        </Form.Item>
                                    </AntCard>
                                    <AntCard title={'Notes'} size={'small'} style={{ marginBottom: 10 }}>
                                        <Form.Item name={'notes'} label={'Notes'}>
                                            <Input.TextArea/>
                                        </Form.Item>
                                    </AntCard>
                                </Col>
                                <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                                    <AntCard title={'Risk'} size={'small'} style={{ marginBottom: 10 }}>
                                        <Form.Item labelCol={{span: 8}} name={'pep'}
                                                   label={'Politically Exposed Person'}>
                                            <Select
                                                options={options.map(i => ({...i, label: `${i.label} (${i.score})`}))}/>
                                        </Form.Item>
                                        <Form.Item name={'sanctions'} label={'Sanctions'} >
                                            <Select options={otherOpts.map(i => ({
                                                ...i,
                                                label: `${i.label} (${i.score})`
                                            }))}/>
                                        </Form.Item>
                                        <Form.Item name={'criminalRecord'} label={'Criminal Record'}>
                                            <Select options={otherOpts.map(i => ({
                                                ...i,
                                                label: `${i.label} (${i.score})`
                                            }))}/>
                                        </Form.Item>
                                        <Form.Item name={'terrorism'} label={'Terrorism'}>
                                            <Select options={otherOpts.map(i => ({
                                                ...i,
                                                label: `${i.label} (${i.score})`
                                            }))}/>
                                        </Form.Item>
                                    </AntCard>
                                    <AntCard title={'Personal'} size={'small'} style={{ marginBottom: 10 }}>
                                        <Form.Item name={'date_of_birth'} label={'Date of Birth'}>
                                            <DatePicker format={'DD/MM/YYYY'}></DatePicker>
                                        </Form.Item>
                                        <Form.Item name={'place_of_birth'} label={'Place of Birth'}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item name={'country_of_birth'} label={'Country of Birth'}>
                                            <Select optionFilterProp={'alt'}
                                                    options={AppTemplates.countries(countriesList)}
                                                    placeholder={' -- SELECT --'}></Select>
                                        </Form.Item>
                                        <Form.Item name={'nationality'} label={'Nationality'}>
                                            <Select optionFilterProp={'alt'}
                                                    options={AppTemplates.countries(countriesList)}
                                                    placeholder={' -- SELECT --'}></Select>
                                        </Form.Item>
                                    </AntCard>
                                    {
                                        componentState.hasClientLogin &&
                                        <AntCard title={'User Access'} size={'small'} style={{ marginBottom: 10 }}>
                                            <Form.Item  labelCol={{ span: 8 }} label="Holding Withdrawl" name="balanceWithdrawal" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <Form.Item labelCol={{ span: 8 }} label="Submit Transfer Access" name="submitTransfer" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <Form.Item labelCol={{ span: 8 }} label="Add Benficiary Access" name="addBeneficiary" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <Form.Item labelCol={{span: 8}} label={'Change Password'}>
                                                <Popconfirm title={'Are you sure ?'} onConfirm={() => changePassword()}>
                                                    <AntButton type={'primary'}>Change Password</AntButton>
                                                </Popconfirm>
                                            </Form.Item>
                                        </AntCard>
                                    }
                                </Col>
                            </Row>
                            <Flex justify={'end'} gap={10}>
                                <AntButton type={'primary'} htmlType={'submit'}>Save Contact</AntButton>
                                <AntButton type={'primary'} onClick={() => createClientLogin()}
                                           disabled={componentState.hasClientLogin}>Create Client Login</AntButton>
                            </Flex>
                        </Form>
                    </Col>
                </Row>
            </div>
            {/*<Card>*/}
            {/*  <CardHeader color='rose' icon>*/}
            {/*    <div className={classes.flexEnd}>*/}
            {/*      <h4 className={classes.cardIconTitle}>{editMode ? 'Edit Record: ' : 'View Record: '}</h4>*/}
            {/*      <div>*/}
            {/*        {true && (*/}
            {/*            <React.Fragment>*/}
            {/*              <FormControlLabel*/}
            {/*                  control={*/}
            {/*                    <Switch*/}
            {/*                        checked={editMode}*/}
            {/*                        onChange={() => setEditMode(prevState => !prevState)}*/}
            {/*                        value='edit_mode'*/}
            {/*                        classes={{*/}
            {/*                          switchBase: classes.switchBase,*/}
            {/*                          checked: classes.switchChecked*/}
            {/*                        }}*/}
            {/*                    />*/}
            {/*                  }*/}
            {/*                  classes={{*/}
            {/*                    label: classes.label*/}
            {/*                  }}*/}
            {/*                  label={editMode ? 'Edit Mode' : 'Read Only'}*/}
            {/*              />*/}
            {/*            </React.Fragment>*/}
            {/*        )}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </CardHeader>*/}
            {/*  <CardBody>*/}
            {/*    <SelectClient*/}
            {/*        disabled={!editMode}*/}
            {/*        defaultClientId={clientContactData.clientId}*/}
            {/*        setSelectedClient={setSelectedClient}*/}
            {/*    />*/}

            {/*    <Grid container spacing={3}>*/}
            {/*      <Grid item xs={6}>*/}
            {/*        <Card className={classes.cardPadding}>*/}
            {/*          <CardHeader>*/}
            {/*            <h6>Contacts</h6>*/}
            {/*          </CardHeader>*/}
            {/*          <CardBody>*/}
            {/*            <Grid container spacing={3}>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    disabled={!editMode}*/}
            {/*                    value={firstName}*/}
            {/*                    onChange={e => setFirstName(e.target.value)}*/}
            {/*                    label='First Name'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    disabled={!editMode}*/}
            {/*                    value={lastName}*/}
            {/*                    onChange={e => setLastName(e.target.value)}*/}
            {/*                    label='Last Name'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    disabled={!editMode}*/}
            {/*                    value={email}*/}
            {/*                    onChange={e => setEmail(e.target.value)}*/}
            {/*                    label='Email'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*            </Grid>*/}
            {/*          </CardBody>*/}
            {/*        </Card>*/}
            {/*      </Grid>*/}
            {/*      <Grid item xs={6}>*/}
            {/*        <Card className={classes.cardPadding}>*/}
            {/*          <CardHeader>*/}
            {/*            <h6>Address</h6>*/}
            {/*          </CardHeader>*/}
            {/*          <CardBody>*/}
            {/*            <Grid container spacing={3}>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    disabled={!editMode}*/}
            {/*                    value={address_line_1}*/}
            {/*                    onChange={e => setAddress_line_1(e.target.value)}*/}
            {/*                    label='Line 1'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    disabled={!editMode}*/}
            {/*                    value={address_line_2}*/}
            {/*                    onChange={e => setAddress_line_2(e.target.value)}*/}
            {/*                    label='Line 2'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    disabled={!editMode}*/}
            {/*                    value={suburb}*/}
            {/*                    onChange={e => setSuburb(e.target.value)}*/}
            {/*                    label='Suburb'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={6}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    disabled={!editMode}*/}
            {/*                    value={state}*/}
            {/*                    onChange={e => setState(e.target.value)}*/}
            {/*                    label='State'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={6}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    disabled={!editMode}*/}
            {/*                    value={postcode}*/}
            {/*                    onChange={e => setPostcode(e.target.value)}*/}
            {/*                    label='Postcode'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <SelectCountry*/}
            {/*                    disabled={!editMode}*/}
            {/*                    loading={countriesListLoading}*/}
            {/*                    countriesList={countriesList}*/}
            {/*                    onChange={e => setCountry_id(e.target.value)}*/}
            {/*                    value={country_id}*/}
            {/*                    label='Country'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*            </Grid>*/}
            {/*          </CardBody>*/}
            {/*        </Card>*/}
            {/*      </Grid>*/}
            {/*      <Grid item xs={6}>*/}
            {/*        <Card className={classes.cardPadding}>*/}
            {/*          <CardHeader>*/}
            {/*            <h6>Personal</h6>*/}
            {/*          </CardHeader>*/}
            {/*          <CardBody>*/}
            {/*            <Grid container spacing={3}>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <DatePicker*/}
            {/*                    disabled={!editMode}*/}
            {/*                    format={'DD MMMM YYYY'}*/}
            {/*                    value={date_of_birth}*/}
            {/*                    onChange={date => setDate_of_birth(dayjs(date).format('YYYY-MM-DD'))}*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    disabled={!editMode}*/}
            {/*                    value={place_of_birth}*/}
            {/*                    onChange={e => setPlace_of_birth(e.target.value)}*/}
            {/*                    label='Place of Birth'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <SelectCountry*/}
            {/*                    disabled={!editMode}*/}
            {/*                    loading={countriesListLoading}*/}
            {/*                    countriesList={countriesList}*/}
            {/*                    onChange={e => setCountry_of_birth(e.target.value)}*/}
            {/*                    value={country_of_birth}*/}
            {/*                    label='Country of Birth'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*              <Grid item xs={12}>*/}
            {/*                <SelectCountry*/}
            {/*                    disabled={!editMode}*/}
            {/*                    loading={countriesListLoading}*/}
            {/*                    countriesList={countriesList}*/}
            {/*                    onChange={e => setNationality(e.target.value)}*/}
            {/*                    value={nationality}*/}
            {/*                    label='Nationality'*/}
            {/*                    size='small'*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*            </Grid>*/}
            {/*          </CardBody>*/}
            {/*        </Card>*/}
            {/*      </Grid>*/}
            {/*      <Grid item xs={6}>*/}
            {/*        <Card className={classes.cardPadding}>*/}
            {/*          <CardHeader>*/}
            {/*            <h6>Notes</h6>*/}
            {/*          </CardHeader>*/}
            {/*          <CardBody>*/}
            {/*            <TextField*/}
            {/*                multiline*/}
            {/*                minRows={12}*/}
            {/*                fullWidth*/}
            {/*                disabled={!editMode}*/}
            {/*                value={notes}*/}
            {/*                onChange={e => setNotes(e.target.value)}*/}
            {/*                size='small'*/}
            {/*                variant='outlined'*/}
            {/*            />*/}
            {/*          </CardBody>*/}
            {/*        </Card>*/}
            {/*      </Grid>*/}
            {/*    </Grid>*/}
            {/*    <Button*/}
            {/*        disabled={!editMode || loading}*/}
            {/*        onClick={saveClientData}*/}
            {/*        type='submit'*/}
            {/*        color='primary'*/}
            {/*        className={classes.updateProfileButton}*/}
            {/*    >*/}
            {/*      {loading ? 'Saving...' : 'Save'}*/}
            {/*    </Button>*/}
            {/*  </CardBody>*/}
            {/*</Card>*/}
        </Fragment>
    );
};

export default ClientContractsEdit;
