import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {AiOutlineSearch} from 'react-icons/ai';
import {API} from 'aws-amplify';
import 'react-circular-progressbar/dist/styles.css';
import {FormattedNumber} from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Button from '../../CustomButtons/Button';
import Dvr from '@material-ui/icons/Dvr';

import {Box} from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Close from '@material-ui/core/SvgIcon/SvgIcon';
import moment from 'moment';
import {Table} from "antd";
import ListTable from "../../ListTable/ListTable";
import {TransferConstants, TransfersUtil} from "../../Transfers/TransfersUtil";

const style = theme => ({
  id: {color: '#0BACF4'},
  currencyContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  currency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    '& > .currency-flag': {
      marginRight: '0.5rem',
      width: 52,
      height: 26
    }
  },
  currencyLabel: {
    textAlign: 'right',
    '& > p': {
      marginBottom: -5,
      fontSize: '0.6rem'
    }
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'left',
    '& > span': {
      // marginLeft: 12,
      fontSize: 12,
      textTransform: 'uppercase'
    }
  },
  progressBar: {width: 50},
  trail: {
    stroke: '#d6d6d6',
    strokeLinecap: 'butt',
    transform: 'rotate(0.25turn)',
    transformOrigin: 'left left'
  },
  path: {
    strokeLinecap: 'butt',
    transition: 'stroke-dashoffset 0.5s ease 0s',
    transform: 'rotate(0.25turn)',
    transformOrigin: 'left left'
  },
  circularStyles: {
    textColor: '#000',
    textSize: '20px',
    transformOrigin: 'left center',
    trail: {
      // Trail color
      stroke: '#d6d6d6',
      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
      strokeLinecap: 'butt',
      // Rotate the trail
      transform: 'rotate(0.25turn)',
      transformOrigin: 'left center'
    }
  }
});

const CurrencyElement = ({classes, currency, value}) => (
  <div className={classes.currency}>
    <div className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`} />
    <div className={classes.currencyLabel}>
      <p>{currency}</p>
      <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={value} />
    </div>
  </div>
);
class TransfersC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transfer_list: [],
      entity_list: []
    };
  }

  componentDidMount() {
    API.get('transfers', `/getByClient/id/${this.props.client_detail_id}`)
      // API.get("transfers", `/get-list-for-dashboard`)
      .then(response => {
        this.setState({transfer_list: response});
      })
      .catch(error => {
        console.log(error);
        API.get('transfers', '/get-list-not-deleted')
          // API.get("transfers", `/get-list-for-dashboard`)
          .then(response => {
            // console.log(response);
            this.setState({transfer_list: response});
          });
      });
  }

  handleClose = e => {
    window.location = '/transfers/edit/' + e;
  };

  handleCreateNewTransfer = () => {
    this.props.history.push(`/transfers/new?client_id=${this.props.client_detail_id}`);
  }

  buildTableData() {
    let date_range_transfer_list = this.state.transfer_list;
    // console.log(this.state.transfer_list); //print out to watch #892 changes - duplicate transfer display
    if (this.state.startDate && this.state.endDate) {
      date_range_transfer_list = this.props.transfers.transfer_list.filter(transfer => {
        return (
          this.state.startDate.isBefore(moment(transfer.transaction_datetime).add(1, 'day')) &&
          this.state.endDate.isAfter(moment(transfer.transaction_datetime))
        );
      });
    }

    const transfer_list_length = date_range_transfer_list.length;
    if (transfer_list_length > 0) {
      return date_range_transfer_list.map((prop, key) => {
        // console.log(prop);
        return {
          id: prop.id,
          transaction_date: prop.transaction_datetime != null ? moment(prop.transaction_datetime).format('DD/MM/YYYY') : '',
          status_id: prop.status_id,
          memo: prop.memo,
          nickname: prop.nickname,
          settlement_date: prop.transaction_datetime != null ? moment(prop.settlement_date).format('DD/MM/YYYY') : '',
          // beneficiary: prop.beneficiaries_nickname,
          ben_legal_name: prop.ben_legal_name ? prop.ben_legal_name : 'To Currency Balance',

          currency_from: prop.currency_from_iso_alpha_3,
          amount_from: prop.amount_from,

          amount_to: prop.amount_to,
          currency_to: prop.currency_to_iso_alpha_3,
          client_rate: prop.client_rate ? prop.client_rate.toFixed(4) : '-',
          status: prop.status,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  this.props.history.push(`/transfers/edit/${prop.id}`);
                }}
                color='warning'
                className='edit'
              >
                <Dvr />
              </Button>{' '}
              {this.props.app_state.current_staff_super_admin && (
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    //console.log(this);
                    const data = this.props.transfers.transfer_list;
                    data.find((o, i) => {
                      if (o.id === prop.id) {
                        this.setState({
                          dialog_delete_confirm: true,
                          dialog_delete_item: prop.id,
                          dialog_delete_item_name: `${prop.nickname}`
                        });

                        return true;
                      }
                      return false;
                    });
                    this.setState({data: data});
                  }}
                  color='danger'
                  className='remove'
                >
                  <Close />
                </Button>
              )}{' '}
            </div>
          )
        };
      });
    }
  }

  getSteps() {
    return ['Submitted', 'Acknowledged', 'Customer Money Received', 'Beneficiary Payment Made', 'Completed'];
  }

  getSteps1() {
    return ['Submitted', 'Not Proceeding'];
  }
  // 'In Process' ? '#632DE6' : c.value === 'Completed' ? '#00FF00' : '#FF0000'
  green = '#238636';
  red = '#FF0000';
  orange = 'orange';
  purple = '#632DE6';
  render() {
    const {classes} = this.props; //disable it because classes never used #1100 by Tom
    const steps = this.getSteps();
    const steps1 = this.getSteps1();

    const statusOptions = TransferConstants.TRANSFER_STATUS;

    const transfer_status = this.state.transfer_list.map((prop, key) => {
      return prop.transfer_status;
    });
    const transfer_status_unique = [...new Set(transfer_status)];
    const transfer_status_optionlist = transfer_status_unique.map((prop, key) => {
      // console.log(prop);
      return (
        <option key={key} value={prop}>
          {prop}
        </option>
      );
    });

    return (
      <div>
        <Box style={{padding: '0 16px'}}>
          <Box style={{textAlign: 'right', marginBottom: '8px'}}>
            <Button round color='info' onClick={this.handleCreateNewTransfer}>
              Create New Transfer
            </Button>
        </Box>

        <Table
          dataSource={this.buildTableData()}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              defaultSortOrder: 'descend',
              sorter: { compare: (a,b) => a.id - b.id }
            },
            {
              title: 'Transaction',
              dataIndex: 'transaction_date',
              defaultSortOrder: 'descend',
              sorter: {
                compare: (a, b) => {
                  const dateA = new Date(a.transaction_date.split('/').reverse().join('/'));
                  const dateB = new Date(b.transaction_date.split('/').reverse().join('/'));

                  return dateA - dateB;
                },
              },
              ...this.props.getColumnSearchProps({
                dataIndex: 'transaction_date',
                filterInputType: 'DATE',
                render: (text) => text
              })
            },
            {
              title: 'Settlement',
              dataIndex: 'settlement_date',
              sorter: {
                compare: (a, b) => {
                  const dateA = new Date(a.settlement_date.split('/').reverse().join('/'));
                  const dateB = new Date(b.settlement_date.split('/').reverse().join('/'));

                  return dateA - dateB;
                },
              },
              ...this.props.getColumnSearchProps({
                dataIndex: 'transaction_date',
                filterInputType: 'DATE',
                render: (text) => text
              })
            },
            {
              title: 'Beneficiary',
              dataIndex: 'ben_legal_name',
              ...this.props.getColumnSearchProps({
                  dataIndex: 'ben_legal_name',
                  render: (text, record) => {
                      return text;
                  }
              })
            },
            {
              title: 'From',
              dataIndex: 'amount_from',
              sorter: { compare: (a,b) => a.amount_from - b.amount_from },
              ...this.props.getColumnSearchProps({
                dataIndex: 'amount_from',
                render: (text, record) => (
                    <React.Fragment>
                      <CurrencyElement
                          currency={record.currency_from}
                          value={record.amount_from}
                          classes={classes}
                      />
                    </React.Fragment>
                ),
              })
            },
            {
              title: '',
              dataIndex: false,
              render: () => <span>⮕</span>
            },
            {
              title: 'To',
              dataIndex: 'amount_to',
              sorter: { compare: (a,b) => a.amount_to - b.amount_to },
              ...this.props.getColumnSearchProps({
                dataIndex: 'amount_to',
                render: (text, record) => (
                    <React.Fragment>
                      <CurrencyElement currency={record.currency_to} value={record.amount_to} classes={classes} />
                    </React.Fragment>
                ),
              })
            },
            {
              title: 'Rate',
              dataIndex: 'client_rate',
              sorter: { compare: (a,b) => a.client_rate - b.client_rate },
            },
            {
              title: 'Status',
              key: 'status',
              dataIndex: 'status',
              filters: statusOptions.map(i => ({ text: i.label, value: i.value })),
              ...this.props.getColumnSearchProps({
                label: 'Status',
                dataIndex: 'status',
                filterInputType: 'SELECT',
                onFilter: (value, record) => {
                  const d = TransferConstants.TRANSFER_STATUS.find(i => i.value === value);
                  if (record.status === d.value) {
                    return true;
                  }
                  return false;
                },
                render: text => {
                  const data = text != null ? TransferConstants.TRANSFER_STATUS.find(i => i.value === text).label : null;
                  return (
                      <div>
                        <CircularProgressbar
                            style={{...classes.trail, ...classes.path}}
                            className={classes.progressBar}
                            value={TransfersUtil.getCircularPercentage(data)}
                            text={`${TransfersUtil.getCircularPercentage(data)}%`}
                            styles={buildStyles({
                              ...classes.circularStyles,
                              pathColor: TransfersUtil.getCircularColor(data)
                            })}
                        />
                        &nbsp;
                        <span style={{fontSize: '12px', textAlign: 'center'}}>{data}</span>
                      </div>
                  );
                }
              })
            },
            {
              title: '',
              dataIndex: 'actions',
            }
          ]}
          // expandable={{
          //   expandedRowRender: (v) => {
          //     let ActivTab = 0;
          //     if (v.status_id === 2) {
          //       ActivTab = 0;
          //     }
          //     if (v.status_id === 3) {
          //       ActivTab = 1;
          //     }
          //     if (v.status_id === 4) {
          //       ActivTab = 1;
          //     }
          //     if (v.status_id === 5) {
          //       ActivTab = 1;
          //     }
          //     if (v.status_id === 6) {
          //       ActivTab = 2;
          //     }
          //     if (v.status_id === 7) {
          //       ActivTab = 3;
          //     }
          //     if (v.status_id === 8) {
          //       ActivTab = 4;
          //     }

          //     if (v.status_id === 10) {
          //       ActivTab = 1;
          //     }

          //     return (
          //         <Card>
          //           <CardBody>
          //             <GridContainer>
          //               <GridItem xs={12} sm={9}>
          //                 {/*<h6>{v.transfer_status_name}</h6>*/}
          //                 {v.status_id !== 10 && (
          //                     <Stepper activeStep={ActivTab}>
          //                       {steps.map((label, index) => {
          //                         const stepProps = {};
          //                         const labelProps = {};

          //                         return (
          //                             <Step key={label} {...stepProps}>
          //                               <StepLabel {...labelProps}>{label}</StepLabel>
          //                             </Step>
          //                         );
          //                       })}
          //                     </Stepper>
          //                 )}
          //                 {v.status_id === 10 && (
          //                     <Stepper activeStep={ActivTab} style={{width: '30%'}}>
          //                       {steps1.map((label, index) => {
          //                         const stepProps = {};
          //                         const labelProps = {};

          //                         return (
          //                             <Step key={label} {...stepProps}>
          //                               <StepLabel {...labelProps}>{label}</StepLabel>
          //                             </Step>
          //                         );
          //                       })}
          //                     </Stepper>
          //                 )}
          //               </GridItem>
          //               <GridItem xs={12} sm={3}>
          //                 {/*<h6>Actions</h6>*/}
          //                 <div style={{paddingTop: 16}}>
          //                   {
          //                     // == Remove "Copy to New Transfer: button for CRM Issue #941 by Tom
          //                     // <Button
          //                     //   style={{width: 200}} color="primary" size="sm"
          //                     //   onClick={() => {
          //                     //     API.get("transfers", `/get/id/${v.id}`)
          //                     //       .then(transfer_detail => {
          //                     //         //console.log(transfer_detail);
          //                     //         //console.log(v);
          //                     //         this.props.history.push(`/dashboard/${v.currency_from}-${v.currency_to}-${v.amount_from}-${v.original.amount_to}-${(transfer_detail.fullList.beneficiary_id === null) ? "" : transfer_detail.fullList.beneficiary_id}-${(transfer_detail.fullList.purpose_of_payment_id === null) ? "" : transfer_detail.fullList.purpose_of_payment_id}-${(transfer_detail.fullList.purpose_of_payment_detail === null) ? " " : transfer_detail.fullList.purpose_of_payment_detail}`);
          //                     //       })
          //                     //   }}
          //                     // >
          //                     //   Copy to New Transfer</Button>
          //                     // <br/>
          //                   }
          //                   <Button
          //                       style={{width: 200}}
          //                       color='primary'
          //                       size='sm'
          //                       onClick={() => {
          //                         this.props.history.push(`/transfers/edit/${v.id}`);
          //                       }}
          //                       className='edit'
          //                   >
          //                     View Transfer Details
          //                   </Button>
          //                   <br />
          //                 </div>
          //               </GridItem>
          //             </GridContainer>
          //           </CardBody>
          //         </Card>
          //     );
          //   }
          // }}
        />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

const Transfers = connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(ListTable(TransfersC))));

export default Transfers;
