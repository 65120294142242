import {Storage} from 'aws-amplify';
import axios from 'axios';
import { AppUtil } from '../../AppUtil'

export const IdentificationConstants = {
  identificationStateKeys: {
    client_list: [],
    document_category_list: [],

    // form_valid: false,
    document_category: {},
    document_category_id: '',
    document_category_id_state: '',
    document_number: '',
    document_number_state: '',
    document_detail: '',
    document_detail_state: '',
    document_expiry: '',
    document_expiry_state: '',
    document_expiry_init_for_category_change: false,
    document_issue_state: '',
    document_issue_state_state: '',
    document_issue_country: '',
    document_issue_country_state: '',
    nickname: '',
    nickname_state: '',
    newUser: null,
    client_id: '',
    client_id_state: '',
    client: {},
    query_string: false,

    country_list: [],
    country_list_prio: [],
    document_date_format: 'DD/MM/YYYY'
  }
};

export const validationMessages = {
  required:(label) => `${label} is required`,
  validEmail:(label) => `${label} is a valid email`
};

export const IdentificationFormValidationRules = {
  CLIENT: [
    { required: true, message: validationMessages.required('Client')},
  ],
  EMAIL: [
    { type: 'email', message: validationMessages.validEmail('input')},
    { required: true, message: validationMessages.required('Email')},
  ],
  DOCUMENT_CATEGORY: [
    {required: true, message: validationMessages.required('Document category')}
  ],
  DOCUMENT_NUMBER: [
    {required: true, message: validationMessages.required('Document number')}
  ],
  COUNTRY: [
    {required: true, message: validationMessages.required('Country')}
  ],
  ISSUE_STATE: [
    {required: false}
  ],
  EXPIRY_DATE: [
    {required: false}
  ],
  DOCUMENT_NICKNAME: [
    {required: true, message: validationMessages.required('Document nickname')}
  ],
  FRONT_DOCUMENT: [
    {required: true, message: validationMessages.required('Front Image')}
  ],
  BACK_DOCUMENT: [
    {required: true, message: validationMessages.required('Back Image')}
  ],
  CLIENT_CONTACT: [
    {required: false, message: validationMessages.required('Client Contact')}
  ]
}

export const IdentificationUtil = {
  fetchS3Url: async (identification_record) => {
    let file_url = {};
    if (identification_record.file_id) {
        const s3Url = await Storage.get(identification_record.file_id);
        file_url = await AppUtil.getFileInfo(s3Url);
    }
    let file_url_back = {};
    if (identification_record.file_id_back) {
      const s3Url = await Storage.get(identification_record.file_id_back, {expires: 60});
      file_url_back = await AppUtil.getFileInfo(s3Url);
    }
    const attachment_urls = [];
    if (identification_record.attachments != null && identification_record.attachments.length > 0) {
      for (const item of identification_record.attachments) {
        const s3Url = await Storage.get(item, {expires: 60});
        const response = await AppUtil.getFileInfo(s3Url);
        attachment_urls.push(response);
      }
    }
    identification_record = {
      ...identification_record, ...{
        loaded: true,
        edit_mode: false,
        is_loading: false,
        file_url,
        file_url_back,
        attachment_urls,
        file_id: identification_record.file_id,
        file_id_back: identification_record.file_id_back
      }
    }
    return identification_record;
  }
}