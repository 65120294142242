import React, {Fragment} from 'react';
import {connect} from 'react-redux';

import {
  updateClient,
  loadClientDetailsRequest,
  loadClientDetailsSuccess,
  createCognitoAccountForClient
} from '../../redux/actions/clients';

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody.jsx';

import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {API} from 'aws-amplify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Radio as AntRadio} from 'antd' ;

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';

import {Box} from '@material-ui/core';
import IdentificationListContainer from '../Identification/IdentificationList';
import ClientDocumentList from '../ClientDocuments/ClientDocumentList';
import HoldingAccountListContainer from '../HoldingAccounts/HoldingAccountList';

import queryString from 'query-string';
import CustomTextField from '../CustomInput/CustomTextField';
import Icon from '@ant-design/icons/lib/components/Icon';
import {Management} from './ClientEdit/Management';
import {UserAccountComponent} from './ClientEdit/UserAccount';
import {Teams} from './ClientEdit/Teams';
import {BussinessDetailsComponent} from './ClientEdit/BussinessDetails';
import {Contacts} from './ClientEdit/Contacts';
import {Address} from './ClientEdit/Address';
import {Personal} from './ClientEdit/Personal';
import Risk from './ClientEdit/Risk';
import moment from 'moment';
import Transfers from './ClientEdit/Transfers';
import {MultiClient} from './ClientEdit/MultiClient';
import Beneficiaries from './ClientEdit/Beneficiaries';
import {Compliance} from './ClientEdit/Compliance/Compliance';
import {Tabs, Tag} from "antd";
import Logins from "./ClientEdit/Logins";
import {RiskConstants} from "./ClientEdit/Risk/RiskConstants";
import {fetchCountryListNotDeleted} from "../../redux/actions/countries";
import Revenue from "./ClientEdit/Revenue";
import {HistoricalRevenue} from "./ClientEdit/HistoricalRevenue";
// require('react-datetime');
const generator = require('generate-password');

const staffEditStyles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'},
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class ClientEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form

      clientRecordSaving: false,

      client_types_list: [],
      staff_list: [],
      team_list: [],
      aws_cognito_id: null,
      country_list: [],
      country_list_prio: [],
      language_id_list: [],
      marital_status_list: [],
      client_status_list: [],
      referral_source_list: [],
      client_beneficiary_list: [],
      loading: false,
      loaded: false,
      is_loading: true,
      account_type: '',
      title: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      client_nickname: '', //#1068
      email: '',
      password: '',
      password_state: '',
      password_confirm: '',
      password_confirm_state: '',
      confirm_code: '',
      confirm_code_state: '',
      security_entities: [],
      security_entities_forexsport: false,
      security_entities_forexworldwide: false,
      security_entities_forexstudent: false,

      migrate_fx: '',
      migrate_fww: '',
      migrate_ready: false,

      responsible_staff_member: '',
      responsible_staff_member_state: '',
      team_id: '',
      team_id_state: '',
      team_nickname: '',
      division_nickname: '',
      entity_nickname: '',

      nationality: '',

      turnover: '',
      netWorth: '',
      sourceOfFunds: '',

      language_id: '',
      date_of_birth: '',

      default_rate_total: '',

      account_status_notes: '',
      todo_list: [],
      client_communication: '',
      status_incomplete_details: false,
      referrer_code: '',
      forgetModal: false,
      clientNotes: '',
      webpage: '',
      isBackOfficeTab: 1
    };
  }

  toggleForgetModal = bool => {
    this.setState({forgetModal: bool});
  };

  componentDidMount() {
    const query_strings = queryString.parse(this.props.location.search);
    if (query_strings) {
      switch (query_strings.tab) {
        case 'personal':
          this.setState({navpill_active: `Tab-${1}`});
          break;
        case 'address':
          this.setState({navpill_active: `Tab-${2}`});
          break;
        case 'beneficiaries':
          this.setState({navpill_active: this.state.account_type = 1 ? 3 : `Tab-${4}`});
          break;
        case 'contact':
          this.setState({navpill_active: this.state.account_type = 1 ? 4 : `Tab-${5}`});
          break;
        case 'management':
          this.setState({navpill_active: this.state.account_type = 1 ? 5 : `Tab-${6}`});
          break;
        case 'risk':
          this.setState({navpill_active: this.state.account_type = 1 ? 6 : `Tab-${7}`});
          break;
        // case 'multi-client':
        //   this.setState({navpill_active: `Tab-${7});
        //   break;
        case 'identification':
          this.setState({navpill_active: `Tab-${8}`});
          break;
        case 'transfers':
          this.setState({navpill_active: `Tab-${9}`});
          break;
        case 'balances':
          this.setState({navpill_active: this.state.account_type = 1 ? 10 : `Tab-${11}`});
          break;
        case 'teams':
          this.setState({navpill_active: `Tab-${11}`});
          break;
        default:
          this.setState({navpill_active: `Tab-${0}`});
          break;
      }
    }

    if (this.props.client_detail_id) {
      this.props.loadClientDetailsRequest();
      this.props.fetchCountryListNotDeleted();
      API.get('clients', `/get/id/${this.props.client_detail_id}`)
        .then(client_detail => {
          this.setState({
            loaded: true,
            aws_cognito_id: client_detail.aws_cognito_id,
            edit_mode: false,
            is_loading: false,
            account_business_type: client_detail.account_business_type,
            email: client_detail.email,
            portal_account_created: client_detail.portal_account_created,
            portal_email_confirmed: client_detail.portal_email_confirmed,
            portal_mobile_confirmed: client_detail.portal_mobile_confirmed,
            portal_last_login_datetime: client_detail.portal_last_login_datetime,
            account_type: client_detail.account_type,
            account_status: client_detail.account_status,
            client_communication: client_detail.account_status,
            account_status_datetime: client_detail.account_status_datetime,
            account_status_staff: client_detail.account_status_staff,
            external_reference: client_detail.external_reference,
            title: client_detail.title,
            first_name: client_detail.first_name,
            middle_name: client_detail.middle_name,
            last_name: client_detail.last_name,
            client_nickname: client_detail.nickname,
            residential_street_line_1: client_detail.residential_street_line_1,
            residential_street_line_2: client_detail.residential_street_line_2,
            residential_street_suburb: client_detail.residential_street_suburb,
            residential_street_state: client_detail.residential_street_state,
            residential_street_postcode: client_detail.residential_street_postcode,
            residential_street_country: client_detail.residential_street_country,
            postal_street_line_2: client_detail.postal_street_line_2,
            postal_street_line_1: client_detail.postal_street_line_1,
            postal_street_suburb: client_detail.postal_street_suburb,
            postal_street_state: client_detail.postal_street_state,
            postal_street_postcode: client_detail.postal_street_postcode,
            postal_street_country: client_detail.postal_street_country,
            telephone_home: client_detail.telephone_home,
            telephone_mobile: client_detail.telephone_mobile,
            telephone_work: client_detail.telephone_work,
            email_secondary: client_detail.email_secondary,

            responsible_staff_member: client_detail.responsible_staff_member,
            src: client_detail.src,
            team_id: client_detail.team_id,
            nationality: client_detail.nationality,

            turnover: client_detail.turnover,
            netWorth: client_detail.netWorth,
            sourceOfFunds: client_detail.sourceOfFunds,

            occupation: client_detail.occupation,
            employer: client_detail.employer,
            account_purpose: client_detail.account_purpose,
            referral_source: client_detail.referral_source,
            referral_detail: client_detail.referral_detail,
            referrer_code: client_detail.referrer_code,
            my_beneficiary: client_detail.my_beneficiary,

            selectedValue: 'customer_type_personal',
            selectedBusinessValue: 'customer_type_soleTrader',

            business_trading_name: client_detail.business_trading_name,
            business_abn: client_detail.business_abn,
            afsl: client_detail.afsl,
            business_company_name: client_detail.business_company_name,
            business_company_number: client_detail.business_company_number,
            business_trust_name: client_detail.business_trust_name,

            // business_company_acn: client_detail.business_company_acn,
            // business_company_abn: client_detail.business_company_abn,

            language_id: client_detail.language_id,
            marital_status: client_detail.marital_status,
            date_of_birth: client_detail.date_of_birth,
            place_of_birth_city: client_detail.place_of_birth_city,
            place_of_birth_country: client_detail.place_of_birth_country,

            default_rate: client_detail.default_rate,
            default_rate_division: client_detail.default_rate_division,
            default_rate_team: client_detail.default_rate_team,
            default_rate_staff: client_detail.default_rate_staff,
            default_rate_client: client_detail.default_rate,
            default_rate_entity: client_detail.default_rate_entity,
            default_rate_total:
              Math.round(
                (Number(client_detail.default_rate_division) +
                  Number(client_detail.default_rate_entity) +
                  Number(client_detail.default_rate_staff) +
                  Number(client_detail.default_rate) +
                  Number(client_detail.default_rate_team)) *
                  100000
              ) / 100000,
            ec_info_dashboard_charts: client_detail.ec_info_dashboard_charts,
            ec_info_dashboard: client_detail.ec_info_dashboard,
            ec_info_economicnews: client_detail.ec_info_economicnews,
            ec_info_economicholidays: client_detail.ec_info_economicholidays,
            ec_info_charts: client_detail.ec_info_charts,
            ec_info_rates: client_detail.ec_info_rates,
            transfer_auto_accept: client_detail.transfer_auto_accept,
            transfer_forwards_allowed: client_detail.transfer_forwards_allowed,
            transfer_forwards_limit: client_detail.transfer_forwards_limit,
            transfer_max_deals_accepted: client_detail.transfer_max_deals_accepted,

            account_status_notes: client_detail.account_status_notes,
            status_incomplete_details: client_detail.status_incomplete_details,
            show_legacy_holding_acc: client_detail.show_legacy_holding_acc,
            feature_ledger: client_detail.feature_ledger,
            feature_multiClient: client_detail.feature_multiClient,
            notify_checkRates: client_detail.notify_checkRates,
            rates_marketCharts: client_detail.rates_marketCharts,
            rates_marketAlerts: client_detail.rates_marketAlerts,

            migrate_fx: client_detail.migrate_fx,
            migrate_fww: client_detail.migrate_fww,
            migrate_ready: client_detail.migrate_ready,
            emailsent_activated_date: client_detail.emailsent_activated_date,
            emailsent_activated_staff: client_detail.emailsent_activated_staff_name,

            log_client_complete_rego: client_detail.log_client_complete_rego ? moment(client_detail.log_client_complete_rego).format('DD-MM-YYYY HH:mm:ss') : '',

            risk_customer_risk: client_detail.risk_customer_risk,
            risk_customer_risk_notes: client_detail.risk_customer_risk_notes,
            risk_country_resident: client_detail.risk_country_resident,
            risk_country_resident_notes: client_detail.risk_country_resident_notes,
            risk_country_transfer: client_detail.risk_country_transfer,
            risk_country_transfer_notes: client_detail.risk_country_transfer_notes,
            risk_source_wealth: client_detail.risk_source_wealth,
            risk_source_wealth_notes: client_detail.risk_source_wealth_notes,
            risk_transaction_channel: client_detail.risk_transaction_channel,
            risk_transaction_channel_notes: client_detail.risk_transaction_channel_notes,
            risk_account_purpose: client_detail.risk_account_purpose,
            risk_account_purpose_notes: client_detail.risk_account_purpose_notes,
            risk_account_activity: client_detail.risk_account_activity,
            risk_account_activity_notes: client_detail.risk_account_activity_notes,
            risk_net_worth: client_detail.risk_net_worth,
            risk_net_worth_notes: client_detail.risk_net_worth_notes,
            risk_hoc_approval: client_detail.risk_hoc_approval,
            risk_edd_req: client_detail.risk_edd_req,
            risk_review_date: client_detail.risk_review_date,

            riskRating: client_detail.riskRating,
            riskStaff: client_detail.riskStaff,
            riskNotes: client_detail.riskNotes,
            riskScore: client_detail.riskScore,
            risk_total_score: client_detail.risk_total_score,
            business_activities: client_detail.business_activities,
            clientNotes: client_detail.clientNotes,
            webpage: client_detail.webpage
          });

          if (client_detail.marketing_authorised == '1') {
            this.setState({marketing_authorised: true});
          } else {
            this.setState({marketing_authorised: false});
          }

          if (query_strings) {
            switch (query_strings.tab) {
              case 'balances':
                this.setState({navpill_active: client_detail.account_type = 1 ? 9 : 10});
                break;
            }
          }

          this.props.loadClientDetailsSuccess();
        })
        .catch(error => {
          console.log(error);
        });

      API.get('clients', '/client-types/get-list')
        .then(response => {
          this.setState({client_types_list: response});
        })
        .catch(error => {
          console.log(error);
        });
      API.get('clients', '/client-status/get')
        .then(response => {
          this.setState({client_status_list: response});
        })
        .catch(error => {
          console.log(error);
        });
      API.get('clients', '/client-referral-source/get')
        .then(response => {
          this.setState({referral_source_list: response});
        })
        .catch(error => {
          console.log(error);
        });
      API.get('staff', '/get-list-not-deleted')
        .then(response => {
          this.setState({staff_list: response});
        })
        .catch(error => {
          console.log(error);
        });
      API.get('teams', '/get-list-not-deleted')
        .then(response => {
          this.setState({team_list: response});
        })
        .catch(error => {
          console.log(error);
        });
      API.get('countries', '/list_not_deleted')
        .then(response => {
          this.setState({country_list_prio: response});
        })
        .catch(error => {
          console.log(error);
        });
      API.get('admin', '/languages/get')
        .then(response => {
          this.setState({language_id_list: response});
        })
        .catch(error => {
          console.log(error);
        });
      API.get('admin', '/marital-status/get')
        .then(response => {
          this.setState({marital_status_list: response});
        })
        .catch(error => {
          console.log(error);
        });

      API.get('clients', `/get/todo_list/${this.props.client_detail_id}`)
        .then(response => {
          this.setState({todo_list: response});
        })
        .catch(error => {
          console.log(error);
        });

      API.get('beneficiaries', `/get/by_client_id/${this.props.client_detail_id}`)
        .then(response => {
          this.setState({client_beneficiary_list: response});
        })
        .catch(error => {
          console.log(error);
        });

      API.get('clientcontacts', `/list-by-clientid/${this.props.client_detail_id}`)
        .then(clientContactResponse => {
          clientContactResponse = clientContactResponse.filter(contact => contact.sendEmail)
          this.setState({clientContacts: clientContactResponse});
        })
        .catch(error => {
          console.log(error);
        });

    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.client_detail_id !== this.props.client_detail_id) {
      this.props.loadClientDetailsRequest();
      API.get('clients', `/get/id/${this.props.client_detail_id}`)
        .then(client_detail => {
          this.setState({
            loaded: true,
            is_loading: false,
            aws_cognito_id: client_detail.aws_cognito_id,
            email: client_detail.email,
            portal_account_created: client_detail.portal_account_created,
            portal_email_confirmed: client_detail.portal_email_confirmed,
            portal_mobile_confirmed: client_detail.portal_mobile_confirmed,
            portal_last_login_datetime: client_detail.portal_last_login_datetime,
            account_type: client_detail.account_type,
            account_status: client_detail.account_status,
            client_communication: client_detail.account_status,
            account_status_datetime: client_detail.account_status_datetime,
            account_status_staff: client_detail.account_status_staff,
            external_reference: client_detail.external_reference,
            title: client_detail.title,
            first_name: client_detail.first_name,
            middle_name: client_detail.middle_name,
            last_name: client_detail.last_name,
            residential_street_line_1: client_detail.residential_street_line_1,
            residential_street_line_2: client_detail.residential_street_line_2,
            residential_street_suburb: client_detail.residential_street_suburb,
            residential_street_state: client_detail.residential_street_state,
            residential_street_postcode: client_detail.residential_street_postcode,
            residential_street_country: client_detail.residential_street_country,
            postal_street_line_2: client_detail.postal_street_line_2,
            postal_street_line_1: client_detail.postal_street_line_1,
            postal_street_suburb: client_detail.postal_street_suburb,
            postal_street_state: client_detail.postal_street_state,
            postal_street_postcode: client_detail.postal_street_postcode,
            postal_street_country: client_detail.postal_street_country,

            telephone_home: client_detail.telephone_home,
            telephone_mobile: client_detail.telephone_mobile,
            telephone_work: client_detail.telephone_work,
            email_secondary: client_detail.email_secondary,

            responsible_staff_member: client_detail.responsible_staff_member,
            src: client_detail.src,
            team_id: client_detail.team_id,
            nationality: client_detail.nationality,
            occupation: client_detail.occupation,
            employer: client_detail.employer,
            account_purpose: client_detail.account_purpose,

            turnover: client_detail.turnover,
            netWorth: client_detail.netWorth,
            sourceOfFunds: client_detail.sourceOfFunds,

            referral_source: client_detail.referral_source,
            referral_detail: client_detail.referral_detail,
            referrer_code: client_detail.referrer_code,
            my_beneficiary: client_detail.my_beneficiary,
            marketing_authorised: client_detail.marketing_authorised,

            selectedValue: 'customer_type_personal',
            selectedBusinessValue: 'customer_type_soleTrader',

            business_trading_name: client_detail.business_trading_name,
            business_abn: client_detail.business_abn,
            afsl: client_detail.afsl,
            business_company_name: client_detail.business_company_name,
            // business_company_acn: client_detail.business_company_acn,
            // business_company_abn: client_detail.business_company_abn,
            business_company_number: client_detail.business_company_number,
            business_trust_name: client_detail.business_trust_name,

            language_id: client_detail.language_id,
            marital_status: client_detail.marital_status,
            date_of_birth: client_detail.date_of_birth,
            place_of_birth_city: client_detail.place_of_birth_city,
            place_of_birth_country: client_detail.place_of_birth_country,

            default_rate: client_detail.default_rate,
            default_rate_division: client_detail.default_rate_division,
            default_rate_team: client_detail.default_rate_team,
            default_rate_staff: client_detail.default_rate_staff,
            default_rate_client: client_detail.default_rate,
            default_rate_entity: client_detail.default_rate_entity,
            default_rate_total:
              Math.round(
                (Number(client_detail.default_rate_division) +
                  Number(client_detail.default_rate_entity) +
                  Number(client_detail.default_rate_staff) +
                  Number(client_detail.default_rate) +
                  Number(client_detail.default_rate_team)) *
                  100000
              ) / 100000,
            transfer_auto_accept: client_detail.transfer_auto_accept,
            transfer_forwards_allowed: client_detail.transfer_forwards_allowed,
            transfer_forwards_limit: client_detail.transfer_forwards_limit,
            transfer_max_deals_accepted: client_detail.transfer_max_deals_accepted,
            show_legacy_holding_acc: client_detail.show_legacy_holding_acc,
            feature_ledger: client_detail.feature_ledger,
            feature_multiClient: client_detail.feature_multiClient,
            notify_checkRates: client_detail.notify_checkRates,
            rates_marketCharts: client_detail.rates_marketCharts,
            rates_marketAlerts: client_detail.rates_marketAlerts,
            migrate_fx: client_detail.migrate_fx,
            migrate_fww: client_detail.migrate_fww,
            migrate_ready: client_detail.migrate_ready,
            emailsent_activated_date: client_detail.emailsent_activated_date,
            emailsent_activated_staff: client_detail.emailsent_activated_staff_name,

            riskRating: client_detail.riskRating,
            riskStaff: client_detail.riskStaff,
            riskNotes: client_detail.riskNotes,
            clientNotes: client_detail.clientNotes,
            webpage: client_detail.webpage

          });

          this.props.loadClientDetailsSuccess();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  get_todo_list() {
    const arrayResult = [];

    for (let i = 0; i < this.state.todo_list.length; i++) {
      let Status = 'Completed';
      if (moment(this.state.todo_list[i].datetime_completed).format('DD/MM/YYYY') == 'Invalid date') {
        Status = "<Button color='warning' onClick={() => this.change_todo_status()} >Not Completed</Button>";
      }

      arrayResult.push([
        this.state.todo_list[i].client_id,
        this.state.todo_list[i].transfer_id,
        this.state.todo_list[i].description,
        moment(this.state.todo_list[i].datetime_created).format('DD/MM/YYYY'),
        moment(this.state.todo_list[i].datetime_completed).format('DD/MM/YYYY'),
        Status
      ]);
    }
    return arrayResult;
  }

  buildTableData() {
    const client_list_length = this.state.todo_list.length;
    if (client_list_length > 0) {
      return this.state.todo_list.map((prop, key) => {
        var Status = 'Completed';
        if (moment(prop.datetime_completed).format('DD/MM/YYYY') == 'Invalid date') {
          var Status = 'Not Completed';
          return {
            client_id: prop.client_id,
            transfer_id: prop.transfer_id,
            description: prop.description,
            datetime_created: moment(prop.datetime_created).format('DD/MM/YYYY'),
            datetime_completed: moment(prop.datetime_completed).format('DD/MM/YYYY'),
            actions: (
              <div className='actions-right'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    API.put('clients', `/update/todo_id/${prop.id}`, {body: {datetime_completed: new Date()}})
                      .then(response => {
                        window.location.reload();
                      })
                      .catch(error => {
                        console.log(error);
                      });
                  }}
                >
                  Not Completed
                </Button>
              </div>
            )
          };
        }
        return {
          client_id: prop.client_id,
          transfer_id: prop.transfer_id,
          description: prop.description,
          datetime_created: moment(prop.datetime_created).format('DD/MM/YYYY'),
          datetime_completed: moment(prop.datetime_completed).format('DD/MM/YYYY'),
          actions: (
            <div className='actions-right'>
              <Button variant='contained' color='secondary'>
                Completed
              </Button>
            </div>
          )
        };
      });
    }
  }

  async saveClientDetail(client_detail) {
    this.props.updateClient(client_detail, this.props.client_detail_id);
  }

  verifyCognitoPassword(value) {
    // Old pattern
    //var passwordRex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;

    const passwordRex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\S)[A-Za-z\d\S]{6,}$/;
    // This pattern requires at least 6 characters and contain at least 1 number and 1 character

    if (passwordRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyCognitoPassword(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    // this.props.updateNewClientCreation(stateName, event.target.value)
  }

  migrationHandleChange = event => {
    this.setState({
      migrationBackendId: event.target.value
    });
  };
  migrationFxMember = async event => {
    console.log(`Migrating from FX - Member ${this.state.migrationBackendId}`);
    let migrationResponse;
    try {
      migrationResponse = await API.post('clients', '/migration', {
        body: {
          database: 'fx',
          clientId: this.props.client_detail_id,
          backendId: this.state.migrate_fx || this.state.migrationBackendId
        }
      });
    } catch (error) {
      console.log(error);
    }
    console.log(migrationResponse);
  };

  migrationFwwMember = async event => {
    console.log(`Migrating from FWW - Member ${this.state.migrationBackendId}`);
    let migrationResponse;
    try {
      migrationResponse = await API.post('clients', '/migration', {
        body: {
          database: 'fww',
          clientId: this.props.client_detail_id,
          backendId: this.state.migrate_fww || this.state.migrationBackendId
        }
      });
    } catch (error) {
      console.log(error);
    }
    console.log(migrationResponse);
  };

  migrationFxBeneficiaries = async event => {
    console.log(`Migrating from FX - Beneficiaries ${this.state.migrationBackendId}`);
    let migrationResponse;
    try {
      migrationResponse = await API.post('beneficiaries', '/migration', {
        body: {
          database: 'fx',
          clientId: this.props.client_detail_id,
          backendId: this.state.migrate_fx || this.state.migrationBackendId
        }
      });
    } catch (error) {
      console.log(error);
    }
    console.log(migrationResponse);
  };

  migrationFwwBeneficiaries = async event => {
    console.log(`Migrating from FWW - Beneficiaries ${this.state.migrationBackendId}`);
    let migrationResponse;
    try {
      migrationResponse = await API.post('beneficiaries', '/migration', {
        body: {
          database: 'fww',
          clientId: this.props.client_detail_id,
          backendId: this.state.migrate_fww || this.state.migrationBackendId
        }
      });
    } catch (error) {
      console.log(error);
    }
    console.log(migrationResponse);
  };

  migrationFxTransfers = async event => {
    console.log(`Migrating from FX - Transfers ${this.state.migrationBackendId}`);
    let migrationResponse;
    try {
      migrationResponse = await API.post('transfers', '/migration', {
        body: {
          database: 'fx',
          clientId: this.props.client_detail_id,
          backendId: this.state.migrate_fx || this.state.migrationBackendId
        }
      });
    } catch (error) {
      console.log(error);
    }
    console.log(migrationResponse);
  };

  migrationFwwTransfers = async event => {
    console.log(`Migrating from FWW - Transfers ${this.state.migrationBackendId}`);
    let migrationResponse;
    try {
      migrationResponse = await API.post('transfers', '/migration', {
        body: {
          database: 'fww',
          clientId: this.props.client_detail_id,
          backendId: this.state.migrate_fww || this.state.migrationBackendId
        }
      });
    } catch (error) {
      console.log(error);
    }
    console.log(migrationResponse);
  };

  handleChange = event => {
    if (event.target.id === 'business_company_number') {
      if (event.target.value.length < 17) {
        this.setState({business_company_number_state: 'success'});
      } else {
        this.setState({business_company_number_state: 'error'});
      }
      //this.props.change(event, event.target.label, type, stateNameEqualTo);
      this.setState({[event.target.id]: event.target.value});
    } else {
      this.setState({[event.target.id]: event.target.value});
    }
  };

  handleNameChange = (event, toEdit_first_name, toEdit_last_name) => {
    if (event.target.id === 'first_name') {
      //set firstname and nickname
      this.setState({
        [event.target.id]: event.target.value,
        client_nickname: event.target.value + ', ' + toEdit_last_name
      });
    } else if (event.target.id === 'last_name') {
      this.setState({
        [event.target.id]: event.target.value,
        client_nickname: toEdit_first_name + ', ' + event.target.value
      });
    } else {
      this.setState({[event.target.id]: event.target.value});
    }
  };

  handleNicknameChange = event => {
    //console.log(event.target.value);

    // == Leave this logic for future use but turn off now #1068 == by Tom
    // let split_result = event.target.value.split(", ");

    // console.log([split_result[0]]);
    // console.log([split_result[1]]);

    //Nickname format: Yang, Tom (Lastname, First_name)
    this.setState({
      [event.target.id]: event.target.value
      //first_name: split_result[1],
      //last_name: split_result[0]
    });
  };

  // For CustomReactSelect
  handleCustomReactSelectChange = name => value => {
    if (value === null) {
      this.setState({[name]: null});
    } else {
      this.setState({[name]: value.value});
    }
  };

  handleToggle = name => event => {
    this.setState({[name]: event.target.checked});
  };

  toggleEdit = () => {
    const edit_mode = !this.state.edit_mode;
    this.setState({edit_mode: edit_mode});
  };

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    if (event.target.name === 'team_id') {
      this.getTeamDetails(event.target.value);
    }
    if (event.target.name === 'account_status') {
      this.setState({
        account_status_datetime: new Date().toISOString().slice(0, 19).replace('T', ' '),
        account_status_staff: this.props.app_state.current_staff.id
      });
    }
  };

  getTeamDetails(team_id) {
    API.get('teams', `/get/div-entity/${team_id}`)
      .then(response => {
        this.setState({
          division_nickname: response.division_nickname,
          entity_nickname: response.entity_nickname,
          team_nickname: response.team_nickname
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  validSecondaryEmail(secondaryEmail) {
    if (this.state.email_secondary === null) {
      this.setState({email_secondary: ' '});
      return this.state.email_secondary;
    }
    return this.state.email_secondary.toLowerCase();
  }

  getSelectedBusinessType = (business_type) => {
    if (business_type === 'customer_type_soleTrader') {
      return 1;
    } else if (business_type === 'customer_type_company') {
      return 2;
    }
    return 3;
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      clientRecordSaving: true
    });

    const {
      // aws_cognito_id,
      title,
      first_name,
      middle_name,
      last_name,
      //client_nickname #1068
      client_nickname,

      // email
      account_type,
      account_status,
      account_status_datetime,
      account_status_staff,
      account_status_notes,
      external_reference,
      residential_street_line_1,
      residential_street_line_2,
      residential_street_suburb,
      residential_street_state,
      residential_street_postcode,
      residential_street_country,
      postal_street_line_1,
      postal_street_line_2,
      postal_street_suburb,
      postal_street_state,
      postal_street_postcode,
      postal_street_country,
      telephone_home,
      telephone_mobile,
      telephone_work,
      email_secondary,
      responsible_staff_member,
      team_id,
      nationality,
      occupation,
      employer,
      turnover,
      netWorth,
      sourceOfFunds,
      referral_source,
      referral_detail,
      referrer_code,
      my_beneficiary,
      marketing_authorised,
      business_trading_name,
      business_abn,
      afsl,
      business_company_name,
      business_company_number,
      business_trust_name,
      // business_company_acn, //#1117 data field fix by Tom
      // business_company_abn,
      language_id,
      marital_status,
      date_of_birth,
      place_of_birth_city,
      place_of_birth_country,
      default_rate,
      ec_info_dashboard_charts,
      ec_info_dashboard,
      ec_info_economicnews,
      ec_info_economicholidays,
      ec_info_charts,
      ec_info_rates,
      transfer_auto_accept,
      transfer_forwards_allowed,
      transfer_forwards_limit,
      transfer_max_deals_accepted,
      default_rate_division,
      default_rate_team,
      default_rate_staff,
      default_rate_client,
      default_rate_entity,
      status_incomplete_details,
      show_legacy_holding_acc,
      feature_ledger,
      feature_multiClient,
      notify_checkRates,
      rates_marketCharts,
      rates_marketAlerts,
      migrate_ready,

      riskRating,
      riskStaff,
      riskNotes,
      selectedBusinessValue,
      riskScore,
      risk_total_score,
      business_activities,
      clientNotes,
      webpage

    } = this.state;
    try {
      await this.saveClientDetail({
        // aws_cognito_id: aws_cognito_id,
        account_type: account_type,
        account_business_type: this.getSelectedBusinessType(selectedBusinessValue),
        account_status: account_status,
        account_status_datetime,
        account_status_staff,
        account_status_notes: account_status_notes,
        title: title,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        nickname: client_nickname,
        external_reference,
        residential_street_line_1: residential_street_line_1,
        residential_street_line_2: residential_street_line_2,
        residential_street_suburb: residential_street_suburb,
        residential_street_state: residential_street_state,
        residential_street_postcode: residential_street_postcode,
        residential_street_country: residential_street_country,
        postal_street_line_1: postal_street_line_1,
        postal_street_line_2: postal_street_line_2,
        postal_street_suburb: postal_street_suburb,
        postal_street_state: postal_street_state,
        postal_street_postcode: postal_street_postcode,
        postal_street_country: postal_street_country,
        telephone_home: telephone_home,
        telephone_mobile: telephone_mobile,
        telephone_work: telephone_work,
        email_secondary: this.validSecondaryEmail(email_secondary),
        responsible_staff_member: responsible_staff_member,
        team_id: team_id,
        nationality: nationality,
        occupation,
        employer,
        turnover,
        netWorth,
        sourceOfFunds,
        referral_source,
        referral_detail,
        referrer_code: referrer_code,
        my_beneficiary,
        marketing_authorised,
        business_trading_name: business_trading_name,
        business_company_name: business_company_name,
        business_company_number: business_company_number,
        business_abn: business_abn,
        afsl: afsl,
        business_trust_name: business_trust_name,
        language_id,
        marital_status,
        date_of_birth,
        place_of_birth_city,
        place_of_birth_country,
        ec_info_dashboard_charts,
        ec_info_dashboard,
        ec_info_economicnews,
        ec_info_economicholidays,
        ec_info_charts,
        ec_info_rates,
        transfer_auto_accept,
        transfer_forwards_allowed,
        transfer_forwards_limit,
        transfer_max_deals_accepted,
        default_rate_division: default_rate_division,
        default_rate_team: default_rate_team,
        default_rate_staff: default_rate_staff,
        default_rate: default_rate_client,
        default_rate_entity: default_rate_entity,
        status_incomplete_details: status_incomplete_details,
        show_legacy_holding_acc,
        feature_ledger,
        feature_multiClient,
        notify_checkRates,
        rates_marketCharts,
        rates_marketAlerts,
        migrate_ready,
        riskRating,
        riskStaff,
        riskNotes,
        riskScore,
        risk_total_score,
        business_activities,
        clientNotes,
        webpage
      });
    } catch (e) {
      console.log('err', e.message)
      this.setState({
        isLoading: false,
        clientRecordSaving: false
      });
    }
    // this.props.reload_user(this.state.aws_cognito_id);
    this.setState({
      isLoading: false,
      clientRecordSaving: false
    });
  };

  forgetPassword = async () => {
    if (this.state.password_confirm !== this.state.password) {
      this.setState({
        password_helptext: 'Password and Confirm Password must match',
        password_state: 'error'
      });
      return;
    }
    if (
      this.state.password_confirm === this.state.password &&
      this.state.password_confirm !== '' &&
      this.state.password !== '' &&
      this.state.password_state === 'success'
    ) {
      try {
        this.setState({loading: true});
        await API.post('clients', '/clients/change/password', {
          body: {
            clientId: this.props.client_detail_id,
            newPassword: this.state.password
          }
        });
        this.toggleForgetModal(false);
        this.setState({loading: false});
      } catch (e) {
        this.setState({loading: false});
        console.error('error occured', e);
      }
    }
  };

  handleCreateClientPortalAccount = () => {
    const password = generator.generate({
      length: 8,
      numbers: true,
      strict: true
    });

    this.props.createCognitoAccountForClient(
      this.props.client_detail_id,
      this.state.email,
      password,
      this.state.language_id
    );
    this.setState({portal_account_created: 1});
  };

  getClientPortalCardContent() {
    const {classes} = this.props;
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };
    const portal_last_login = this.state.portal_last_login_datetime
      ? new Date(this.state.portal_last_login_datetime).toLocaleString('en-AU', options)
      : 'N/A';

    if (!this.state.portal_account_created) {
      return (
        <React.Fragment>
          <p>
            {this.state.first_name} <strong>DOES NOT HAVE</strong> a client portal registration
          </p>
          <p>
            Clicking the button below will initiate the account creation procedure. This will send an email to{' '}
            {this.state.email} advising them of account opening and requesting email confirmation.
          </p>
          <p>
            {this.state.password_state === 'error' && (
              <strong style={{color: 'red'}}>
                <em>
                  Please note passwords must contain at least 1 uppercase and 1 lowercase character, as well as 1 number
                  and 1 special character
                </em>
              </strong>
            )}
          </p>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText='Username (primary email)'
                id='username_display_email'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  disabled: true,
                  value: this.state.email || '',
                  onChange: event => {
                    this.handleChange(event);
                  }
                }}
              />
              <CustomInput
                success={this.state.password_state === 'success'}
                error={this.state.password_state === 'error'}
                labelText='Password *'
                id='password'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.password,
                  onChange: event => this.change(event, 'password', 'password'),
                  type: 'password'
                }}
              />
              <CustomInput
                success={this.state.password_confirm_state === 'success'}
                error={this.state.password_confirm_state === 'error'}
                labelText='Confirm Password *'
                id='password_confirm'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.password_confirm,
                  onChange: event => this.change(event, 'password_confirm', 'equalTo', 'password'),
                  type: 'password'
                }}
              />
            </GridItem>
          </GridContainer>
          <Button
            color='primary'
            onClick={() => this.handleCreateClientPortalAccount()}
            // type="submit"
            className={classes.updateProfileButton}
          >
            Create client portal account
          </Button>
        </React.Fragment>
      );
    }

    if (!this.state.portal_email_confirmed) {
      return (
        <React.Fragment>
          <p>
            {this.state.first_name}
            's portal account <strong>HAS NOT </strong> been confirmed.
          </p>
          <p>
            An email has been sent to {this.state.email} with a confirmation code. The user should visit
            https://portal.forexworldwide.com and verify their account.
          </p>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <ul>
          <li>{this.state.first_name} has a client portal registration</li>
          <li>
            {this.state.first_name}
            's client portal registration has been confirmed.
          </li>
          <li>
            {this.state.first_name} last CRM login: {portal_last_login}
          </li>
        </ul>
        <Button onClick={() => this.toggleForgetModal(true)}>Change user password</Button>
      </React.Fragment>
    );
  }

  getFullDate = s => {
    return moment(s).format('Do MMMM YYYY');
  };

  validatePassword(event, value, stateName) {
    // var passwordRex = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*\W.*\W)[a-zA-Z0-9\S]{9,}$/;
    // This pattern requires at least two lowercase letters, two uppercase letters, two digits, and two special characters. There must be a minimum of 9 characters total, and no white space characters are allowed.
    // very strong password

    // var passwordRex = /^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/;
    // This pattern requires at least longer than 8 characters and contain at least 1 uppercase & 1 number and 1 special character

    const passwordRex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\S)[A-Za-z\d\S]{6,}$/;
    // This pattern requires at least 6 characters and contain at least 1 number

    this.setState({[stateName]: event.target.value});
    if (passwordRex.test(event.target.value)) {
      this.setState({
        password_state: 'success',
        password_helptext: ''
      });
      return true;
    }

    if (this.state.password !== this.state.password_confirm) {
      this.setState({
        password_helptext: 'Password and Confirm Password must match',
        password_state: 'error'
      });
    } else {
      this.setState({
        password_state: 'error',
        password_helptext: 'Must be at least 6 characters and contain at least 1 number'
      });
    }
    return false;
  }

  render() {
    const {classes} = this.props;

    const referral_source_select_options = this.state.referral_source_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    if (!this.state.loaded) {
      return null;
    }
    if (!this.state.client_types_list) {
      return null;
    }
    const account_status_nickname = this.state.client_status_list[this.state.account_status]
      ? this.state.client_status_list[this.state.account_status - 1].nickname
      : '';

    const backoffice = this.props.app_state.current_staff && this.props.app_state.current_staff.backofficeStaff;
    const business = this.state.account_type === 2;

    let tab_array = [
      {
        label: 'Account',
        key: `Tab-${0}`,
        children: (
          <div style={{padding: 0}}>
            <UserAccountComponent
              classes={classes}
              handleSubmit={this.handleSubmit}
              clientRecordSaving={this.state.clientRecordSaving}
              {...this.props}
              state={this.state}
              clientID={this.props.client_detail_id}
              handleChange={this.handleChange}
              setState={this.setState.bind(this)}
              handleCustomReactSelectChange={this.handleCustomReactSelectChange}
              handleToggle={this.handleToggle}
              client_detail_id={this.props.client_detail_id}
              change={this.change.bind(this)}
              handleCreateClientPortalAccount={this.handleCreateClientPortalAccount}
              toggleForgetModal={this.toggleForgetModal.bind(this)}
              validatePassword={this.validatePassword.bind(this)}
              forgetPassword={this.forgetPassword.bind(this)}
            />
          </div>
        )
      },
      business ? 
      {
        key: `Tab-${2}`,
        label: 'Business',
        children: (
          <div style={{padding: 20}}>
            <BussinessDetailsComponent
              classes={classes}
              handleSubmit={this.handleSubmit}
              {...this.props}
              state={this.state}
              handleChange={this.handleChange}
              setState={this.setState.bind(this)}
              handleCustomReactSelectChange={this.handleCustomReactSelectChange}
              handleToggle={this.handleToggle}
              client_detail_id={this.props.client_detail_id}
              change={this.change}
              handleCreateClientPortalAccount={this.handleCreateClientPortalAccount}
              toggleForgetModal={this.toggleForgetModal}
              validatePassword={this.validatePassword}
              forgetPassword={this.forgetPassword}
            />
          </div>
        )
      } : null,
      {
        key: `Tab-${1}`,
        label: 'Contact',
        children: (
          <Fragment>
            <Personal
              backoffice={backoffice}
              classes={classes}
              handleSubmit={this.handleSubmit}
              {...this.props}
              getFullDate={this.getFullDate}
              state={this.state}
              handleChange={this.handleChange}
              setState={this.setState.bind(this)}
              handleCustomReactSelectChange={this.handleCustomReactSelectChange}
              handleToggle={this.handleToggle}
              referral_source_select_options={referral_source_select_options}
            />
          </Fragment>
        )
      },
      // {
      //   key: `Tab-${3}`,
      //   label: 'Address',
      //   children: (
      //     <div style={{padding: 0}}>
      //       <Addressq
      //         classes={classes}
      //         handleSubmit={this.handleSubmit}
      //         {...this.props}
      //         state={this.state}
      //         handleChange={this.handleChange}
      //         setState={this.setState.bind(this)}
      //         handleCustomReactSelectChange={this.handleCustomReactSelectChange}
      //         handleToggle={this.handleToggle}
      //         referral_source_select_options={referral_source_select_options}
      //       />
      //     </div>
      //   )
      // },

      business ? 
      {
        key: `Tab-${16}`,
        label: 'Ben Owners',
        children: (
          <Contacts
            isBeneficialOwner={true}
            classes={classes}
            handleSubmit={this.handleSubmit}
            {...this.props}
            state={this.state}
            handleChange={this.handleChange}
            setState={this.setState.bind(this)}
            handleCustomReactSelectChange={this.handleCustomReactSelectChange}
            handleToggle={this.handleToggle}
          />
        )
      } : null,
      {
        key: `Tab-${5}`,
        label: 'Assoc. Persons',
        children: (
            <Contacts
                classes={classes}
                handleSubmit={this.handleSubmit}
                {...this.props}
                state={this.state}
                handleChange={this.handleChange}
                setState={this.setState.bind(this)}
                handleCustomReactSelectChange={this.handleCustomReactSelectChange}
                handleToggle={this.handleToggle}
            />
        )
      },
      backoffice ? 
      {
        key: `Tab-${12}`,
        label: 'Risk',
        children: (
          <Fragment>
            <Risk
              classes={classes}
              emitTotalRiskScore={(score) => {
                this.setState({ riskScore: score });
              }}
              handleSubmit={this.handleSubmit}
              {...this.props}
              getFullDate={this.getFullDate}
              state={this.state}
              handleChange={this.handleChange}
              setState={this.setState.bind(this)}
              clientID={this.props.client_detail_id}
              handleCustomReactSelectChange={this.handleCustomReactSelectChange}
              handleToggle={this.handleToggle}
              referral_source_select_options={referral_source_select_options}
            />
          </Fragment>
        )
      } : null,

      backoffice ? 
      {
        key: `Tab-${7}`,
        label: 'BackOffice',
        children: this.props.app_state.current_staff ? (
          <Management
            classes={classes}
            handleSubmit={this.handleSubmit}
            {...this.props}
            state={this.state}
            handleChange={this.handleChange}
            setState={this.setState.bind(this)}
            handleCustomReactSelectChange={this.handleCustomReactSelectChange}
            handleToggle={this.handleToggle}
            migrationHandleChange={this.migrationHandleChange}
            migrationFxMember={this.migrationFxMember}
            migrationFwwMember={this.migrationFwwMember}
            migrationFxBeneficiaries={this.migrationFxBeneficiaries}
            migrationFwwBeneficiaries={this.migrationFwwBeneficiaries}
            migrationFxTransfers={this.migrationFxTransfers}
            migrationFwwTransfers={this.migrationFwwTransfers}
          />
        ) : (
          <span>Insufficient security level</span>
        )
      } : null,
      // {
      //   key: `Tab-${7}`,
      //   label: 'Multi Client',
      //   tabContent: this.props.app_state.current_staff ? (
      //     <MultiClient
      //       classes={classes}
      //       handleSubmit={this.handleSubmit}
      //       {...this.props}
      //       state={this.state}
      //       handleChange={this.handleChange}
      //       setState={this.setState.bind(this)}
      //       handleCustomReactSelectChange={this.handleCustomReactSelectChange}
      //       handleToggle={this.handleToggle}
      //     />
      //   ) : (
      //     <span>Insufficient security level</span>
      //   )
      // },

      backoffice ? 
      {
        key: `Tab-${11}`,
        label: 'Compliance',
        children: (
          <Compliance
            classes={classes}
            handleSubmit={this.handleSubmit}
            {...this.props}
            state={this.state}
            handleChange={this.handleChange}
            setState={this.setState.bind(this)}
            handleCustomReactSelectChange={this.handleCustomReactSelectChange}
            handleToggle={this.handleToggle}
          />
        )
      } : null,

      {
        key: `Tab-${8}`,
        label: 'ID',
        children: (
          <div style={{padding: 20}}>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <IdentificationListContainer client_id={this.props.client_detail_id} />
              </GridItem>
            </GridContainer>
            <Button
              color='primary'
              onClick={() => this.props.history.push(`/identification/new?id=${this.props.client_detail_id}`)}
              className={classes.updateProfileButton}
            >
              New Identification Record
            </Button>
          </div>
        )
      },
      // {
      //   key: `Tab-${15}`,
      //   label: 'Documents',
      //   children: (
      //       <Box sx={{padding: 20}}>
      //         <ClientDocumentList documentCategoryId={1} client_id={this.props.client_detail_id} history={this.props.history} />
      //       </Box>
      //   )
      // },
      {
        key: `Tab-${10}`,
        label: 'Documents',
        children: (
          <Box sx={{padding: 20}}>
            <ClientDocumentList client_id={this.props.client_detail_id} history={this.props.history} />
          </Box>
        )
      },
      {
        key: `Tab-${9}`,
        label: 'Balances',
        children: (
          <div style={{padding: 20}}>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <HoldingAccountListContainer client_id={this.props.client_detail_id} />
              </GridItem>
            </GridContainer>
          </div>
        )
      },
      {
        key: `Tab-${4}`,
        label: 'Beneficiaries',
        children: (
          <Beneficiaries
            classes={classes}
            handleSubmit={this.handleSubmit}
            {...this.props}
            state={this.state}
            handleChange={this.handleChange}
            setState={this.setState.bind(this)}
            handleCustomReactSelectChange={this.handleCustomReactSelectChange}
            handleToggle={this.handleToggle}
          />
        )
      },
      backoffice ? 
      {
        key: `Tab-${6}`,
        label: 'Logins',
        children: (
            <Logins state={this.state} {...this.props}/>
        )
      } : null,
      
      {
        key: `Tab-${13}`,
        label: 'Transfers',
        children: (
          <div style={{padding: 0}}>
            <Transfers
              classes={classes}
              handleSubmit={this.handleSubmit}
              {...this.props}
              state={this.state}
              handleChange={this.handleChange}
              setState={this.setState.bind(this)}
              handleCustomReactSelectChange={this.handleCustomReactSelectChange}
              handleToggle={this.handleToggle}
              client_detail_id={this.props.client_detail_id}
              change={this.change}
              handleCreateClientPortalAccount={this.handleCreateClientPortalAccount}
              toggleForgetModal={this.toggleForgetModal}
              validatePassword={this.validatePassword}
              forgetPassword={this.forgetPassword}
            />
          </div>
        )
      },

      {
        key: `Tab-${14}`,
        label: 'Teams',
        children: (
          <div style={{padding: 20}}>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <Teams
                  classes={classes}
                  client_id={this.props.client_detail_id}
                  handleSubmit={this.handleSubmit}
                  {...this.props}
                  state={this.state}
                  handleChange={this.handleChange}
                  setState={this.setState.bind(this)}
                  handleCustomReactSelectChange={this.handleCustomReactSelectChange}
                  handleToggle={this.handleToggle}
                />
              </GridItem>
            </GridContainer>
          </div>
        )
      },
      backoffice ? 
      {
        key: `Tab-${17}`,
        label: 'Revenue',
        children: (
            <Revenue client_id={this.props.client_detail_id}/>
        )
      } : null,
      backoffice ?
          {
            key: `Tab-${18}`,
            label: 'Historical Revenue',
            children: (
                <HistoricalRevenue client_id={this.props.client_detail_id}/>
            )
          } : null,
    ];

    const backOfficeKeys = [
      'Tab-0',
      'Tab-1',
      'Tab-2',
      'Tab-5',
      'Tab-6',
      'Tab-7',
      'Tab-10',
      'Tab-16',
      'Tab-17',
      'Tab-18'
    ];
    const operationsKeys = [
      'Tab-9',
      'Tab-4',
      'Tab-13',
      'Tab-14'
    ];
    const riskKeys = [
      'Tab-12',
      'Tab-10',
    ];
    const complianceKeys = [
      'Tab-8',
      'Tab-15',
      'Tab-11',
      'Tab-10',
    ]


    const radioOptions = [
        {label: 'Back Office', value: 1},
        {label: 'Operations', value: 2},
        {label: 'Risk', value: 3},
        {label: 'Compliance', value: 4}
    ];

    tab_array = this.state.isBackOfficeTab === 1
        ? tab_array.filter(i => i != null && backOfficeKeys.includes(i.key))
        : (this.state.isBackOfficeTab === 2
            ? tab_array.filter(i => i != null && operationsKeys.includes(i.key))
            : this.state.isBackOfficeTab === 3
                ? tab_array.filter(i => i != null && riskKeys.includes(i.key))
                : this.state.isBackOfficeTab === 4 ? tab_array.filter(i => i != null && complianceKeys.includes(i.key)) : [])


    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {/* <Card> */}
              <CardHeader color='rose' icon>
                <div className={classes.flexEnd}>
                  <h4 className={classes.cardIconTitle}>
                    {this.state.client_nickname}{' '}
                  </h4>
                  <h4
                      className={classes.cardIconTitle}
                      style={{color: account_status_nickname === 'Approved' ? 'green' : 'red'}}
                  >
                    Client Status: {account_status_nickname}
                  </h4>
                  <h4 style={{ display: 'flex', alignItems: 'center' }} className={classes.cardIconTitle}>
                    <span style={{ marginRight: '5px' }}>
                      Risk Score:
                    </span>
                    <Tag style={{ fontSize: '18px', padding: '5px' }} color={RiskConstants.GetRiskColor(this.state.riskScore)}>{RiskConstants.GetRiskRating(this.state.riskScore)} {this.state.riskScore && `(${this.state.riskScore})`}</Tag></h4>
                  <div>
                    <FormControlLabel
                        control={
                          <Switch
                              checked={this.state.edit_mode}
                              onChange={() => this.toggleEdit()}
                              value='edit_mode'
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked
                              }}
                          />
                        }
                        classes={{label: classes.label}}
                        label={this.state.edit_mode ? 'Edit Mode' : 'Read Only'}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <AntRadio.Group
                    defaultValue={1}
                    options={radioOptions}
                    onChange={(e) => {
                      console.log(e.target.value);
                      this.setState({isBackOfficeTab: e.target.value});
                    }}
                    optionType="button"
                    buttonStyle="solid"
                />
                <Tabs tabPosition={'top'} style={{height: 'auto'}} items={tab_array}
                      defaultActiveKey={this.state.navpill_active}/>
              </CardBody>
            {/* </Card> */}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// ClientEdit.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withRouter(withStyles(staffEditStyles)(ClientEdit));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadClientDetailsRequest: () => {
      dispatch(loadClientDetailsRequest());
    },
    loadClientDetailsSuccess: () => {
      dispatch(loadClientDetailsSuccess());
    },
    createCognitoAccountForClient: (id, username, password, language_id) => {
      dispatch(createCognitoAccountForClient(id, username, password, language_id));
    },
    updateClient: (data, id) => {
      dispatch(updateClient(data, id));
    },
    fetchCountryListNotDeleted: () => {
      dispatch(fetchCountryListNotDeleted())
    }
  };
};

const ClientEditContainer = connect(mapStateToProps, mapDispatchToProps)(ClientEdit);

export default withRouter(withStyles(staffEditStyles)(ClientEditContainer));
