import ListTable from '../../ListTable/ListTable';
import React, {useEffect, useState} from 'react';
import {Descriptions, Flex, message, Typography} from 'antd';
import {API} from 'aws-amplify';
import dayjs from 'dayjs';
import ChartistGraph from "react-chartist";

const styles = {};
export const HistoricalRevenue = props => {

    const [componentState, setComponentState] = useState({
        transfersList: [],
        chart: {
            options: {
                axisY: {
                    onlyInteger: true,
                    offset: 60
                },
                seriesBarDistance: 15,
                height: 400,
                axisX: {
                    offset: 30
                }
            },
            data: {
                labels: ["0-3 months", "3-6 months", "6-9 months", "9-12 months", "12 to 24 months"],
                series: []
            }
        },
        data: {
            threeMonths: {
                value: 0,
                threshold: 0,
                transferCount: 0,
                cumulative: 0,
            },
            sixMonths: {
                value: 0,
                threshold: 0,
                transferCount: 0,
                cumulative: 0,
            },
            nineMonths: {
                value: 0,
                threshold: 0,
                transferCount: 0,
                cumulative: 0,
            },
            twelveMonths: {
                value: 0,
                threshold: 0,
                transferCount: 0,
                cumulative: 0,
            },
            twentyfourMonths: {
                value: 0,
                threshold: 0,
                transferCount: 0,
                cumulative: 0,
            }
        }
    });

    console.log(componentState);

    const calculateProfit = transfer => {
        const amount_from = Number.parseFloat(transfer.amount_from);
        const amount_to = Number.parseFloat(transfer.amount_to);
        const client_rate = Number.parseFloat(transfer.client_rate);
        const bank_rate = Number.parseFloat(transfer.bank_rate);

        let transferProfit = 0;

        if (!transfer.bank_rate) return 0
        if (transfer.client_rate === 1) return 0

        if (transfer.currency_from_id === 1) {
            // if it is from AUD
            transferProfit = (amount_from * client_rate - amount_from * bank_rate) / bank_rate;
        } else {
            const inverseClientRate = 1 / client_rate;
            const inverseBankRate = 1 / bank_rate;
            transferProfit = (amount_to * inverseClientRate - amount_to * inverseBankRate) / inverseBankRate;
        }

        transferProfit = Number.parseFloat(transferProfit);
        transferProfit = Math.abs(transferProfit);

        // Add any "fees" if they are applicable
        if (transfer.fee_admin_amount != null) {
            transferProfit = transferProfit - Number.parseFloat(transfer.fee_admin_amount);
        }

        return transferProfit;
    };

    const init = () => {
        const loading = message.loading('Please wait...', 0);
        API.get('revenue', `/client/${props.client_id}`, {})
            .then(response => {
                const threeMonthsD = [];
                const sixMonthsD = [];
                const nineMonthsD = [];
                const twelveMonthsD = [];
                const twentyfourMonthsD = [];

                const threeMonthsC = [];
                const sixMonthsC = [];
                const nineMonthsC = [];
                const twelveMonthsC = [];
                const twentyfourMonthsC = [];

                response
                    .filter(item => item.status === 8)
                    .forEach(item => {
                        if (item.settlement_date != null) {
                            const dateToCheck = dayjs(item.settlement_date);
                            const now = dayjs();

                            const threeMonthsAgo = now.subtract(3, 'month');
                            const sixMonthsAgo = now.subtract(6, 'month');
                            const nineMonthsAgo = now.subtract(9, 'month');
                            const twelveMonthsAgo = now.subtract(12, 'month');
                            const twentyfourMonthsAgo = now.subtract(24, 'month');

                            if (dateToCheck.isAfter(threeMonthsAgo) && dateToCheck.isBefore(now)) {
                                threeMonthsD.push(item); // 0–3 months
                            } else if (dateToCheck.isAfter(sixMonthsAgo) && dateToCheck.isBefore(threeMonthsAgo)) {
                                sixMonthsD.push(item); // 3–6 months
                            } else if (dateToCheck.isAfter(nineMonthsAgo) && dateToCheck.isBefore(sixMonthsAgo)) {
                                nineMonthsD.push(item); // 6–9 months
                            } else if (dateToCheck.isAfter(twelveMonthsAgo) && dateToCheck.isBefore(nineMonthsAgo)) {
                                twelveMonthsD.push(item); // 9–12 months
                            } else if (dateToCheck.isAfter(twentyfourMonthsAgo) && dateToCheck.isBefore(twelveMonthsAgo)) {
                                twentyfourMonthsD.push(item); // 12–24 months
                            }

                            const isInPast3Months = dateToCheck.isAfter(threeMonthsAgo) && dateToCheck.isBefore(now);
                            const isInPastSixMonths = dateToCheck.isAfter(sixMonthsAgo) && dateToCheck.isBefore(now);
                            const isInPastNineMonths = dateToCheck.isAfter(nineMonthsAgo) && dateToCheck.isBefore(now);
                            const isInPastTwelveMonths = dateToCheck.isAfter(twelveMonthsAgo) && dateToCheck.isBefore(now);
                            const isInPastTwentyFourMonths = dateToCheck.isAfter(twentyfourMonthsAgo) && dateToCheck.isBefore(now);

                            if (isInPast3Months) {
                                threeMonthsC.push(item);
                            }
                            if (isInPastSixMonths) {
                                sixMonthsC.push(item);
                            }
                            if (isInPastNineMonths) {
                                nineMonthsC.push(item);
                            }
                            if (isInPastTwelveMonths) {
                                twelveMonthsC.push(item);
                            }
                            if (isInPastTwentyFourMonths) {
                                twentyfourMonthsC.push(item);
                            }
                        }
                    });

                setComponentState(prev => ({
                    ...prev,
                    data: {
                        threeMonths: {
                            ...prev.data.threeMonths,
                            value: threeMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                            cumulative: threeMonthsC.reduce((acc, item) => acc + calculateProfit(item), 0),
                            transferCount: threeMonthsD.length,
                        },
                        sixMonths: {
                            ...prev.data.sixMonths,
                            value: sixMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                            cumulative: sixMonthsC.reduce((acc, item) => acc + calculateProfit(item), 0),
                            transferCount: sixMonthsD.length,
                        },
                        nineMonths: {
                            ...prev.data.nineMonths,
                            value: nineMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                            cumulative: nineMonthsC.reduce((acc, item) => acc + calculateProfit(item), 0),
                            transferCount: nineMonthsD.length,
                        },
                        twelveMonths: {
                            ...prev.data.twelveMonths,
                            value: twelveMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                            cumulative: twelveMonthsC.reduce((acc, item) => acc + calculateProfit(item), 0),
                            transferCount: twelveMonthsD.length,
                        },
                        twentyfourMonths: {
                            ...prev.data.twentyfourMonths,
                            value: twentyfourMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                            cumulative: twentyfourMonthsC.reduce((acc, item) => acc + calculateProfit(item), 0),
                            transferCount: twentyfourMonthsD.length,
                        },
                    },
                    transfersList: response,
                    chart: {
                        ...prev.chart,
                        data: {
                            ...prev.chart.data,
                            series: [[
                                threeMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                                sixMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                                nineMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                                twelveMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                                twentyfourMonthsD.reduce((acc, item) => acc + calculateProfit(item), 0),
                            ]]
                        }
                    }
                }));

                message.success('Transfers fetched');
            })
            .catch(err => {
                console.error(err);
                message.error('Unable to fetch. Please try again.');
            })
            .finally(() => loading());
    };


    useEffect(() => {
        init();
    }, [0]);

    return (
        <>
            <Descriptions
                column={6}
                title={'Aged Profit Calculations'}
                layout={'vertical'}
                bordered
                items={
                    [
                        {
                            key: 1,
                            label: '0 - 3 Months',
                            children: <>
                                <h5>{Intl.NumberFormat('en-Au', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'AUD'
                                }).format(componentState.data.threeMonths.value)}</h5>
                                <Flex justify={'start'} vertical>
                                    <Typography.Text>Payments: {componentState.data.threeMonths.transferCount}</Typography.Text>
                                    <Typography.Text>per Payment: {Intl.NumberFormat('en-Au', {
                                        useGrouping: true,
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'AUD'
                                    }).format((componentState.data.threeMonths.value / componentState.data.threeMonths.transferCount) || 0)}</Typography.Text>
                                    <br/>
                                    <Typography.Text>Cumulative: {Intl.NumberFormat('en-Au', {
                                        useGrouping: true,
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'AUD'
                                    }).format((componentState.data.threeMonths.cumulative) || 0)}</Typography.Text>
                                </Flex>
                            </>
                        },
                        {
                            key: 2,
                            label: '3 - 6 Months',
                            children: <>
                                <h5>{Intl.NumberFormat('en-Au', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'AUD'
                                }).format(componentState.data.sixMonths.value)}</h5>
                                <Flex justify={'start'} vertical>
                                    <Typography.Text>Payments: {componentState.data.sixMonths.transferCount}</Typography.Text>
                                    <Typography.Text>per Payment: {Intl.NumberFormat('en-Au', {
                                        useGrouping: true,
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'AUD'
                                    }).format((componentState.data.sixMonths.value / componentState.data.sixMonths.transferCount) || 0)}</Typography.Text>
                                    <br/>
                                    <Typography.Text>Cumulative: {Intl.NumberFormat('en-Au', {
                                        useGrouping: true,
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'AUD'
                                    }).format((componentState.data.sixMonths.cumulative) || 0)}</Typography.Text>
                                </Flex>
                            </>
                        },
                        {
                            key: 3,
                            label: '6 - 9 Months',
                            children: <>
                                <h5>{Intl.NumberFormat('en-Au', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'AUD'
                                }).format(componentState.data.nineMonths.value)}</h5>
                                <Flex justify={'start'} vertical>
                                    <Typography.Text>Payments: {componentState.data.nineMonths.transferCount}</Typography.Text>
                                    <Typography.Text>per Payment: {Intl.NumberFormat('en-Au', {
                                        useGrouping: true,
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'AUD'
                                    }).format((componentState.data.nineMonths.value / componentState.data.nineMonths.transferCount) || 0)}</Typography.Text>
                                    <br/>
                                    <Typography.Text>Cumulative: {Intl.NumberFormat('en-Au', {
                                        useGrouping: true,
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'AUD'
                                    }).format((componentState.data.nineMonths.cumulative) || 0)}</Typography.Text>
                                </Flex>
                            </>
                        },
                        {
                            key: 4,
                            label: '9 - 12 Months',
                            children: <>
                                <>
                                    <h5>{Intl.NumberFormat('en-Au', {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'AUD'
                                    }).format(componentState.data.twelveMonths.value)}</h5>
                                    <Flex justify={'start'} vertical>
                                        <Typography.Text>Payments: {componentState.data.twelveMonths.transferCount}</Typography.Text>
                                        <Typography.Text>per Payment: {Intl.NumberFormat('en-Au', {
                                            useGrouping: true,
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'AUD'
                                        }).format((componentState.data.twelveMonths.value / componentState.data.twelveMonths.transferCount) || 0)}</Typography.Text>
                                        <br/>
                                        <Typography.Text>Cumulative: {Intl.NumberFormat('en-Au', {
                                            useGrouping: true,
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'AUD'
                                        }).format((componentState.data.twelveMonths.cumulative) || 0)}</Typography.Text>
                                    </Flex>
                                </>
                            </>
                        },
                        {
                            key: 5,
                            label: '12 - 24 Months',
                            children: <>
                                <h5>{Intl.NumberFormat('en-Au', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'AUD'
                                }).format(componentState.data.twentyfourMonths.value)}</h5>
                                <Flex justify={'start'} vertical>
                                    <Typography.Text>Payments: {componentState.data.twentyfourMonths.transferCount}</Typography.Text>
                                    <Typography.Text>per Payment: {Intl.NumberFormat('en-Au', {
                                        useGrouping: true,
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'AUD'
                                    }).format((componentState.data.twentyfourMonths.value / componentState.data.twentyfourMonths.transferCount) || 0)}</Typography.Text>
                                    <br/>
                                    <Typography.Text>Cumulative: {Intl.NumberFormat('en-Au', {
                                        useGrouping: true,
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'AUD'
                                    }).format((componentState.data.twentyfourMonths.cumulative) || 0)}</Typography.Text>
                                </Flex>
                            </>
                        }
                    ]
                }
            ></Descriptions>
            <ChartistGraph style={{ marginTop: 10 }} data={componentState.chart.data} options={componentState.chart.options} type="Bar" />
        </>
    );
};

export default ListTable(HistoricalRevenue);
