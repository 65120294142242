import React from 'react';

import {createBrowserHistory} from 'history';
import {Router, Switch} from 'react-router-dom';

import indexRoutes from 'routes/index.jsx';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DayJSUtils from '@date-io/dayjs';

import Amplify, {API, Auth} from 'aws-amplify';
import config from './config/awsConfig';
import configAdmin from './config/awsConfigAdmin';
import configSystem from './config/awsConfigSystem';
import configStaff from './config/awsConfigStaff';
import configBeneficiaries from './config/awsConfigBeneficaries';
import configClients from './config/awsConfigClients';
import configClientsSimple from './config/awsConfigClientSimple';
import configForwards from './config/awsConfigForwards';
import AppliedRoute from './components/Routes/AppliedRoute';
import configDivisions from './config/awsConfigDivisions';
import configTeams from './config/awsConfigTeams';
import configEntities from './config/awsConfigEntities';
import configTransfers from './config/awsConfigTransfers';
import configIdentification from './config/awsConfigIdentification';
import configRemittance from './config/awsConfigRemittance';
import awsConfigCountries from './config/awsConfigCountries';
import awsConfigCurrencies from './config/awsConfigCurrencies';
import awsConfigForwardDrawDown from './config/awsConfigForwardDrawDown';
//core for react-intl
import {IntlProvider, addLocaleData} from 'react-intl';
import 'antd/dist/reset.css';
//import locale data and message data


import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'


import messages_zh from './translations/zh.json';
import messages_en from './translations/en.json';
import messages_de from './translations/de.json';
import messages_es from './translations/es.json';
import messages_fr from './translations/fr.json';
import messages_it from './translations/it.json';
import messages_pt from './translations/pt.json';

//import locale data and message data
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import de from 'react-intl/locale-data/de';
import zh from 'react-intl/locale-data/zh';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import pt from 'react-intl/locale-data/pt';

addLocaleData([...en, ...es, ...de, ...zh, ...fr, ...it, ...pt]);


dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)


const messages = {
  zh: messages_zh,
  en: messages_en,
  de: messages_de,
  es: messages_es,
  fr: messages_fr,
  it: messages_it,
  pt: messages_pt
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito_staff.REGION,
    userPoolId: config.cognito_staff.USER_POOL_ID,
    identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: 'crm',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: 'admin',
        endpoint: configAdmin.apiGateway.URL,
        region: configAdmin.apiGateway.REGION
      },
      {
        name: 'email',
        endpoint: 'https://crm-api.forexworldwide.com/email',
        region: config.apiGateway.REGION
      },
      {
        name: 'email-18',
        endpoint: 'https://0nym76z8di.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: config.apiGateway.REGION
      },
      {
        name: 'holding_accounts',
        endpoint: 'https://crm-api.forexworldwide.com/holding_accounts',
        region: config.apiGateway.REGION
      },
      {
        name: 'sns',
        endpoint: 'https://crm-api.forexworldwide.com/sns',
        // endpoint: "https://or1xkucvi7.execute-api.us-east-1.amazonaws.com/prod",
        region: config.apiGateway.REGION
      },
      {
        name: 'news-staff',
        endpoint: 'https://crm-api.forexworldwide.com/news-staff',
        region: config.apiGateway.REGION
      },
      {
        name: 'news-clients',
        endpoint: 'https://crm-api.forexworldwide.com/news-clients',
        region: config.apiGateway.REGION
      },
      {
        name: 'forwards',
        endpoint: configForwards.apiGateway.URL,
        region: configForwards.apiGateway.REGION
      },
      {
        name: 'identification',
        // endpoint: configIdentification.apiGateway.URL,
        endpoint: 'https://nppmavlhkc.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: configIdentification.apiGateway.REGION
      },
      {
        name: 'forwardsDrawDown',
        endpoint: awsConfigForwardDrawDown.apiGateway.URL,
        region: awsConfigForwardDrawDown.apiGateway.REGION
      },
      {
        name: 'system',
        endpoint: configSystem.apiGateway.URL,
        region: configSystem.apiGateway.REGION
      },
      {
        name: 'staff',
        endpoint: configStaff.apiGateway.URL,
        region: configStaff.apiGateway.REGION
      },
      {
        name: 'clients',
        endpoint: configClients.apiGateway.URL,
        // endpoint: 'http://localhost:3000/dev',
        region: configClients.apiGateway.REGION
      },
      {
        name: 'clients-simple',
        endpoint: configClientsSimple.apiGateway.URL,
        region: configClientsSimple.apiGateway.REGION
      },
      {
        name: 'beneficiaries',
        endpoint: configBeneficiaries.apiGateway.URL,
        region: configBeneficiaries.apiGateway.REGION
      },
      {
        name: 'entities',
        endpoint: configEntities.apiGateway.URL,
        region: configEntities.apiGateway.REGION
      },
      {
        name: 'teams',
        endpoint: configTeams.apiGateway.URL,
        region: configTeams.apiGateway.REGION
      },
      {
        name: 'divisions',
        endpoint: configDivisions.apiGateway.URL,
        region: configDivisions.apiGateway.REGION
      },
      {
        name: 'transfers',
        endpoint: configTransfers.apiGateway.URL,
        region: configTransfers.apiGateway.REGION
      },
      {
        name: 'remittance',
        endpoint: configRemittance.apiGateway.URL,
        region: configRemittance.apiGateway.REGION
      },
      {
        name: 'countries',
        endpoint: awsConfigCountries.apiGateway.URL,
        region: awsConfigCountries.apiGateway.REGION
      },
      {
        name: 'currencies',
        endpoint: awsConfigCurrencies.apiGateway.URL,
        region: awsConfigCurrencies.apiGateway.REGION
      },
      {
        name: 'payments',
        endpoint: 'https://crm-api.forexworldwide.com/payments',
        region: config.apiGateway.REGION
      },
      {
        name: 'reports',
        endpoint: 'https://crm-api.forexworldwide.com/reports',
        region: config.apiGateway.REGION
      },
      {
        name: 'transfers_pending',
        endpoint: 'https://crm-api.forexworldwide.com/transfers-pending',
        region: config.apiGateway.REGION
      },
      {
        name: 'worldpay',
        endpoint: 'https://crm-api.forexworldwide.com/worldpay',
        region: config.apiGateway.REGION
      },
      {
        name: 'groups',
        endpoint: 'https://crm-api.forexworldwide.com/groups',
        region: config.apiGateway.REGION
      },
      {
        name: 'batch-payments',
        endpoint: 'https://crm-api.forexworldwide.com/batch-payments',
        region: config.apiGateway.REGION
      },
      {
        name: 'clientcontacts',
        endpoint: 'https://6bxsdqizma.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: config.apiGateway.REGION
      },
      {
        name: 'client-documents',
        endpoint: 'https://crm-api.forexworldwide.com/client-documents',
        region: config.apiGateway.REGION
      },
      {
        name: 'transfer-documents',
        endpoint: 'https://crm-api.forexworldwide.com/transfer-documents',
        region: config.apiGateway.REGION
      },
      {
        name: 'beneficiary-documents',
        endpoint: 'https://crm-api.forexworldwide.com/beneficiary-documents',
        region: config.apiGateway.REGION
      },
      {
        name: 'banking',
        endpoint: 'https://crm-api.forexworldwide.com/banking',
        region: config.apiGateway.REGION
      },
      {
        name: 'client-compliance',
        endpoint: 'https://crm-api.forexworldwide.com/client-compliance',
        region: config.apiGateway.REGION
    },
      {
        name: 'rate-alerts',
        endpoint: 'https://npahspfi6c.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: config.apiGateway.REGION
    },
      {
        name: 'bank-accounts',
        endpoint: ' https://waty9fgzx8.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: config.apiGateway.REGION
      },
      {
        name: 'client-risks',
        endpoint: 'https://ceme1q9i7f.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: config.apiGateway.REGION
      },
      {
        name: 'commons',
        endpoint: 'https://biv1yclv6k.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: config.apiGateway.REGION
      },
      {
        name: 'reconciliation',
        endpoint: 'https://tafu984484.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: config.apiGateway.REGION
      },
      {
        name: 'reports',
        endpoint: 'https://ckvhxoyjd6.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: config.apiGateway.REGION
      },
      {
        name: 'revenue',
        endpoint: 'https://yj2zpy43h4.execute-api.ap-southeast-2.amazonaws.com/dev',
        region: config.apiGateway.REGION
      },

    ]
  }
});

const hist = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: false,
      current_user: null
    };
  }

  async componentDidMount() {
    try {
      // await Auth.currentSession();
      await Auth.currentAuthenticatedUser();
      this.userHasAuthenticated(true);
    } catch (e) {
      console.log(e);
      if (e !== 'No current user') {
        console.log(e);
        // alert(e);
      }
    }
    this.setState({isAuthenticating: false});
  }

  userHasAuthenticated = authenticated => {
    if (authenticated) {
      // console.log(authenticated);
      Auth.currentAuthenticatedUser({
        bypassCache: false
      })
        .then(user => {
          // console.log(user);
          this.populateData(user.username);
          this.props.onLoginSuccess(user.username);
        })
        .catch(err => {
          console.err(err);
        });
    }

    this.setState({
      isAuthenticated: authenticated
    });
    // this.setState({ isAuthenticated: authenticated });
  };

  async populateData(aws_cognito_id) {
    try {
      const user = await this.getUserProfile(aws_cognito_id);
      // console.log(user);
      this.setState({
        current_user: user
      });
    } catch (e) {
      console.error(e);
      const user = await this.getUserProfile(aws_cognito_id);
      // console.log(user);
      this.setState({
        current_user: user
      });
    }
  }

  async getUserProfile(aws_cognito_id) {
    return API.get('staff', `/get/aws_cognito_id/${aws_cognito_id}`);
  }

  // repopulateData = async (aws_cognito_id) => {
  //   try {
  //     const user = await API.get("staff", `/get/aws_cognito_id/${aws_cognito_id}`);
  //     this.setState({
  //       current_user: user
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  set_current_user = user => {
    this.setState({current_user: user});
  };

  handleLogout = async () => {
    // event.preventDefault();
    // this.props.history.push("/");
    try {
      await Auth.signOut();
      this.userHasAuthenticated(false);
      this.setState({
        current_user: null
      });
      // this.props.history.push("/");
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    //console.log(this.props.language.language_current_ui);
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      set_current_user: this.set_current_user,
      handleLogout: this.handleLogout,
      current_user: this.state.current_user
      // reload_user: this.repopulateData
    };
    return (
      !this.state.isAuthenticating && (
        <IntlProvider
          locale={this.props.language.language_current_ui}
          messages={messages[this.props.language.language_current_ui]}
        >
          <MuiPickersUtilsProvider utils={DayJSUtils}>
            <Router history={hist}>
              <Switch>
                {indexRoutes.map((prop, key) => {
                  return <AppliedRoute path={prop.path} component={prop.component} key={key} props={childProps} />;
                })}
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
        </IntlProvider>
      )
    );
  }
}

export default App;
