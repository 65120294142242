import {API} from 'aws-amplify';

export const TransferAPI = {
    fetchTransfer: (staffId, startDate, endDate, status) =>
        API.get('transfers', `/list/staff-date/${staffId}/${startDate}/${endDate}/${status}`, {}),
    fetchTransferById: transferId => API.get('transfers', `/get/id/${transferId}`, {}),
    removeTransfer: transferId => API.put('transfers', `/update/id/${transferId}`, {body: {deleted: true}}),
    updateTransferById: (transferId, requestBody) =>
        API.put('transfers', `/update/id/${transferId}`, {body: requestBody}),
    fetchPendingTransferDetailById: transferId => API.get('transfers_pending', `/get-full/id/${transferId}`, {})
};

export const TransferConstants = {
    STATUS_OPTIONS: {
        IN_PROCESS: 'In Process',
        COMPLETED: 'Completed',
        ACKNOWLEDGED: 'Acknowledged',
        FUNDS_RECEIVED: 'Funds Received',
        PROCESSING: 'Processing',
        NOT_PROCEEDING: 'Not Proceeding',
        AWAITING_FUNDS: 'Awaiting Funds'
    },
    TRANSFER_STATUS: [
        {label: 'Draft', value: 1 },
        {label: 'In Process', value: 2 },
        {label: 'Acknowledged', value: 3 },
        {label: 'Processing', value: 4 },
        {label: 'Awaiting Funds', value: 5 },
        {label: 'Funds Received', value: 6 },
        {label: 'Payment Made', value: 7 },
        {label: 'Completed', value: 8 },
        {label: 'On Hold', value: 9 },
        {label: 'Not Proceeding', value: 10 },
    ]
};

export const TransfersUtil = {
    getCircularColor: value => {
        switch (value) {
            case TransferConstants.STATUS_OPTIONS.IN_PROCESS:
                return 'success';
            case TransferConstants.STATUS_OPTIONS.COMPLETED:
                return 'success';
            case TransferConstants.STATUS_OPTIONS.ACKNOWLEDGED:
                return 'success';
            case TransferConstants.STATUS_OPTIONS.FUNDS_RECEIVED:
                return 'green';
            case TransferConstants.STATUS_OPTIONS.AWAITING_FUNDS:
                return 'orange';
            case TransferConstants.STATUS_OPTIONS.PROCESSING:
                return 'success';
            case TransferConstants.STATUS_OPTIONS.NOT_PROCEEDING:
                return 'red';
            default:
                return 'orange';
        }
    },
    getCircularPercentage: value => {
        let percentage;
        switch (value) {
            case 'Acknowledged':
                percentage = 20;
                break;
            case 'In Process':
                percentage = 30;
                break;
            case 'Processing':
                percentage = 40;
                break;
            case 'Funds Received':
                percentage = 70;
                break;
            case 'Completed':
                percentage = 100;
                break;
            case 'Not Proceeding':
                percentage = 100;
                break;
            default:
                percentage = 50;
                break;
        }
        return percentage;
    },
    getTagColor: stsKey => {
        let status = 'success';
        switch (stsKey) {
            case 'Draft':
                status = 'orange';
                break;
            case 'Submitted':
                status = 'orange';
                break;
            case 'Acknowledged':
                status = 'orange';
                break;
            case 'In Process':
                status = 'orange';
                break;
            case 'Awaiting Funds':
                status = 'orange';
                break;
            case 'Funds Received':
                status = 'green';
                break;
            case 'On Hold':
                status = 'red';
                break;
            case 'Payment Made':
                status = 'red';
                break;
            case 'Not Proceeding':
                status = 'blue';
                break;
            case 'Completed':
                status = 'blue';
                break;
            default:
                status = 'success';
                break;
        }
        return status;
    }
};
